<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        <span>Media Library</span>
      </div>
      <div>
        <cs-button
          :disabled="disable"
          variant="primary"
          fill="solid"
          @click="showCreateLibrary"
          >Create Library</cs-button
        >
      </div>
    </div>
    <div v-if="libraries && libraries.length">
      <div class="ecs-media-library__search">
        <cs-search-bar
          v-model="val"
          class="ecs-media-library__search-bar"
          placeholder="Search Libraries"
        />
      </div>
      <div class="ecs-dashboard__table-head">
        <b-table
          :items="libraries"
          :fields="fields"
          borderless
          hover
          :filter="filteredResult"
          striped
          table-variant="light"
          tbody-tr-class="ecs-dashboard__table-row"
          @row-clicked="myRowClickHandler"
        >
          <template #table-busy>
            <div class="ecs-dashboard__table-busy">
              <cs-spinner class="ecs-spinner" />
            </div>
          </template>
          <!-- Library Name -->
          <template v-slot:cell(libraryName)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text slot="visible" :long-text="data.item.name" />
              <div slot="popover">{{ data.item.name }}</div>
            </app-hover>
          </template>
          <!-- Total Assets -->
          <template v-slot:cell(totalAssets)="data">
            <span>
              {{ data.item.assetsCount }}
            </span>
          </template>
          <!-- Total Size -->
          <template v-slot:cell(totalSize)="data">
            <span v-if="data.item.assetsSize"
              >{{ data.item.assetsSize }} GB</span
            >
            <span v-else>0 GB</span>
          </template>
          <!-- Time -->
          <template v-slot:cell(created)="data">
            <span>{{
              data.item.created_at | moment('D MMM YYYY, h:mm a')
            }}</span>
          </template>
          <!-- Features -->
          <template v-slot:cell(features)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <div slot="visible" class="ecs-media-library__features">
                <cs-tag
                  v-for="feature in filterFeatures(
                    data.item.video_features,
                    data.item.image_features,
                    data.item.audio_features
                  )"
                  :key="feature"
                  class="ecs-media-library__feature"
                  >{{ feature }}</cs-tag
                >
              </div>
              <div slot="popover">
                <cs-tag
                  v-for="feature in filterFeatures(
                    data.item.video_features,
                    data.item.image_features,
                    data.item.audio_features
                  )"
                  :key="feature"
                  class="ecs-media-library__feature"
                  >{{ feature }}</cs-tag
                >
              </div>
            </app-hover>
          </template>
          <template v-slot:cell(action)="data">
            <i
              v-if="!disable"
              :id="`showPopover${data.index}`"
              class="cs-icons-options ecs-media-library__action-icon"
              tabindex="-1"
            >
            </i>
            <b-popover
              ref="actionPopover"
              :target="`showPopover${data.index}`"
              triggers="click blur"
              custom-class="bg-dark"
              placement="leftbottom"
            >
              <div class="ecs-dashboard__popover">
                <div @click="openUpdateMediaLibraryModal(data.item)">
                  Edit Library
                </div>
                <div @click="openDeleteMediaLibraryModal(data.item)">
                  Remove Library
                </div>
              </div>
            </b-popover>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Empty State -->
    <div v-if="libraries && !libraries.length">
      <app-empty-state
        :icon="MediaLibraryImage"
        button-text="Create Library"
        :disable="disable"
        description="You have not created any library yet"
        @add-action="showCreateLibrary"
      />
    </div>
    <app-create-media-library-modal
      :show-add="show"
      @close="closeCreateLibrary"
      @close-after-create="closeAfterLibraryCreated"
    />

    <app-edit-media-library-modal
      :show-update-media-library="showUpdateMediaLibrary"
      :media-library="mediaLibrary"
      @close-update-media-library="closeUpdateMediaLibraryModal"
    />

    <!-- Update User Modal -->
    <app-delete-media-library-modal
      :show-delete-media-library="showDeleteMediaLibrary"
      :media-library="mediaLibrary"
      @close-delete-media-library="closeDeleteMediaLibraryModal"
    />
  </div>
</template>

<script>
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import AppEditMediaLibraryModal from '@/components/medialibrary/EditMediaLibraryModal.vue';
import AppDeleteMediaLibraryModal from '@/components/medialibrary/DeleteMediaLibraryModal.vue';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppHover from '@/components/general/Hover.vue';
import ListMediaLibraries from '@/gql/medialibrary/ListMediaLibraries.gql';
import AppCreateMediaLibraryModal from '@/components/medialibrary/CreateMediaLibraryModal.vue';
const MediaLibraryImage = require('@/assets/images/media-library.svg');

export default {
  components: {
    AppTruncatedText,
    AppEmptyState,
    AppHover,
    AppCreateMediaLibraryModal,
    AppEditMediaLibraryModal,
    AppDeleteMediaLibraryModal,
  },
  apollo: {
    libraries: {
      query: ListMediaLibraries,
      variables() {
        return {
          projectId: this.$route.params.id,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      val: '',
      MediaLibraryImage,
      mediaLibrary: {},
      show: false,
      showUpdateMediaLibrary: false,
      showDeleteMediaLibrary: false,
      fields: [
        {
          key: 'libraryName',
          label: 'Library Name',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'totalAssets',
          label: 'Total Assets',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'totalSize',
          label: 'Total Size',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created',
          label: 'Created',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'features',
          label: 'Features',
          thClass: 'ecs-dashboard__table-head',
        },
        { key: 'action', label: '', thClass: 'ecs-dashboard__table-head' },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    filterFeatures(videoFeatures, imageFeatures, audioFeatures) {
      const image = imageFeatures?.length ? imageFeatures : [];
      const video = videoFeatures?.length ? videoFeatures : [];
      const audio = audioFeatures?.length ? audioFeatures : [];
      return [...image, ...video, ...audio];
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'MediaLibraryAssetList',
        params: { mediaid: record.id },
      });
    },
    showCreateLibrary() {
      this.show = true;
    },
    closeCreateLibrary() {
      this.show = false;
    },
    async closeAfterLibraryCreated() {
      this.show = false;
      await this.$apollo.queries.libraries.refetch();
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    openUpdateMediaLibraryModal(d) {
      this.mediaLibrary = d;
      this.showUpdateMediaLibrary = true;
      this.closeActionPopover();
    },
    closeUpdateMediaLibraryModal(val) {
      if (val) {
        this.$apollo.queries.libraries.refetch();
      }
      this.closeActionPopover();
      this.showUpdateMediaLibrary = false;
    },
    openDeleteMediaLibraryModal(d) {
      this.mediaLibrary = d;
      this.showDeleteMediaLibrary = true;
      this.closeActionPopover();
    },
    closeDeleteMediaLibraryModal(val) {
      if (val) {
        this.$apollo.queries.libraries.refetch();
      }
      this.closeActionPopover();
      this.showDeleteMediaLibrary = false;
    },
  },
};
</script>

<style scoped>
.ecs-media-library__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-media-library__search-bar {
  width: 100%;
}
.ecs-media-library__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.ecs-media-library__feature {
  margin: 5px;
}
.ecs-media-library__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 550px;
  vertical-align: middle;
}
* >>> td {
  vertical-align: middle;
}
.ecs-media-library__action-icon {
  font-size: 20px;
  color: var(--cs-gray-06);
}
.ecs-media-library__file-icon {
  font-size: 20px;
  margin-right: 20px;
  color: var(--cs-gray-06);
}
</style>
