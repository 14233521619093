<template>
  <div>
    <div class="d-flex flex-row justify-content-between ecs-dashboard-row">
      <div class="d-flex flex-row gap-2">
        <span class="mt-2">Choose Period:</span>
        <cs-select v-model="dateRange" :options="dateRangeOptions"></cs-select>
        <cs-date-input
          v-model="filterData.startDate"
          :disabled="dateRange === 'last_7_days'"
          label=""
        ></cs-date-input>
        <cs-date-input
          v-model="filterData.endDate"
          :disabled="dateRange === 'last_7_days'"
          label=""
        ></cs-date-input>
      </div>
      <div
        class="mt-3 cs-textstyle-paragraph cursor-pointer ecs-cursor"
        @click="viewFullHistory"
      >
        View Full History <i class="cs-icons-next-angle"></i>
      </div>
    </div>
    <div v-if="!$apollo.queries.stats.loading" class="ecs-row">
      <div class="light-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[0] }}
        </div>
        <div class="ecs-stat-number">
          {{ stats ? stats.totalJobs : '' }}
        </div>
      </div>
      <div class="second-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[1] }}
        </div>
        <div v-if="view === 'caption' && stats" class="ecs-stat-number">
          {{ stats.successfulJobs.toFixed(2) }}%
        </div>
        <div v-else class="ecs-stat-number">
          {{
            successfulConversions % 1 !== 0
              ? successfulConversions.toFixed(2)
              : successfulConversions
          }}%
        </div>
      </div>
      <div class="light-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[2] }}
        </div>
        <div class="ecs-stat-number">
          {{ stats ? `${stats.timeConsumed}s` : '' }}
        </div>
      </div>
      <div class="forth-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[3] }}
        </div>
        <div v-if="stats" class="ecs-stat-number">
          {{ stats.pendingJobs }}
        </div>
      </div>
      <div class="light-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[4] }}
        </div>
        <div class="ecs-stat-number">
          {{ stats ? `$${stats.totalSpending}` : '' }}
        </div>
      </div>
      <div v-if="view === 'file'" class="light-box d-flex flex-column ecs-box">
        <div class="cs-textstyle-paragraph">
          {{ currentView.array[5] }}
        </div>
        <div class="ecs-stat-number">{{ stats ? totalConvertedSize : '' }}</div>
      </div>
    </div>
    <div class="chart">
      <line-chart
        v-if="!$apollo.queries.chart.loading"
        :chart-data="chartData"
        :options="options"
      ></line-chart>
    </div>
  </div>
</template>

<script>
import GetFileConversionStats from '@/gql/fileconversion/GetFileConversionStats.gql';
import GetFileConversionChart from '@/gql/fileconversion/GetFileconversionChart.gql';
import GetTranscodingStats from '@/gql/transcoding/GetTranscodingStats.gql';
import GetTranscodingChart from '@/gql/transcoding/GetTranscodingChart.gql';
import GetCaptionStats from '@/gql/caption/GetCaptionStats.gql';
import GetCaptionChart from '@/gql/caption/GetCaptionChart.gql';
import LineChart from '@/components/charts/LineChart.vue';
import {
  dateRangeOptions,
  getDynamicHeadings,
  groupByFormats,
  getDashboardDataset,
  getdashboardChartData,
  formatBytes,
} from '@/services/utils';

export default {
  components: { LineChart },
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  apollo: {
    stats: {
      query() {
        return this.currentView.stats;
      },
      variables() {
        return {
          projectId: this.$route.params.id,
          endDate: this.filterData.endDate,
          fromDate: this.filterData.startDate,
        };
      },
      skip() {
        return (
          !this.filterData.startDate ||
          !this.filterData.endDate ||
          !this.dateRange
        );
      },
    },
    chart: {
      query() {
        return this.currentView.chart;
      },
      variables() {
        return {
          projectId: this.$route.params.id,
          endDate: this.filterData.endDate,
          fromDate: this.filterData.startDate,
        };
      },
      result({ data }) {
        const { chart } = data;
        const { startDate, endDate } = this.filterData;
        const groupBy = 'day';
        const dynamicHeadings = getDynamicHeadings(startDate, endDate, groupBy);

        const dashboardChartData = getdashboardChartData(
          chart,
          dynamicHeadings,
          groupBy
        );
        this.chartData = {
          labels: [
            ...dynamicHeadings.map((date) =>
              this.$moment(date).format(groupByFormats[groupBy])
            ),
          ],
          datasets: [getDashboardDataset(dashboardChartData)],
        };
      },
      skip() {
        return (
          !this.filterData.startDate ||
          !this.filterData.endDate ||
          !this.dateRange
        );
      },
    },
  },
  data() {
    return {
      date: new Date(),
      formValid: false,
      projectId: this.$route.params.id,
      dateRange: 'last_7_days',
      dateRangeOptions,
      chartData: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          padding: 28,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#E6EAEA',
                zeroLineColor: '#E6EAEA',
              },
              ticks: {
                fontColor: '#87939B',
                fontSize: 12,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: '#E6EAEA',
                zeroLineColor: '#E6EAEA',
              },
              scaleLabel: {
                display: true,
                labelString: 'Average execution time',
                fontSize: 14,
                fontWeight: 700,
                fontColor: '#B5C0C7',
                fontFamily: 'Roboto',
              },
              ticks: {
                fontColor: '#87939B',
                fontSize: 12,
                beginAtZero: true,
                callback(value) {
                  return `${value}s`;
                },
              },
            },
          ],
        },
      },
      filterData: {
        startDate: '',
        endDate: '',
      },
      fileArray: [
        'Total Conversions',
        'Successful Conversions',
        'Minutes Consumed',
        'Pending/Queued Jobs',
        'Total Spending ',
        'File Size Converted',
      ],
      transcodingArray: [
        'Total Number Of Jobs ',
        'Completed Successfully',
        'Minutes Consumed',
        'Pending/Queued Jobs',
        'Total Spending ',
      ],
      fields: [],
      currentView: {},
      listProjectCharges: [],
    };
  },
  computed: {
    totalConvertedSize() {
      const totalsize = this.stats.convertedFileSize
        ? this.stats.convertedFileSize
        : 0;
      return formatBytes(totalsize);
    },
    successfulConversions() {
      if (this.stats) {
        if (this.stats.totalJobs === 0) return 0;
        return (this.stats.successfulJobs / this.stats.totalJobs) * 100;
      }
      return 0;
    },
  },
  watch: {
    dateRange: {
      handler(val) {
        if (val === 'last_7_days') {
          this.defaultFromTo();
        }
      },
    },
    view: {
      immediate: true,
      handler(val) {
        const viewSettings = {
          file: {
            array: this.fileArray,
            name: 'FileConversionJobList',
            stats: GetFileConversionStats,
            chart: GetFileConversionChart,
          },
          transcoding: {
            array: this.transcodingArray,
            name: 'JobsList',
            stats: GetTranscodingStats,
            chart: GetTranscodingChart,
          },
          caption: {
            array: this.transcodingArray,
            name: 'CaptionJobList ',
            stats: GetCaptionStats,
            chart: GetCaptionChart,
          },
        };
        this.currentView = viewSettings[val];
      },
      deep: true,
    },
    filterData: {
      handler(val) {
        if (val) {
          this.$apollo.queries.stats.refetch();
          this.$apollo.queries.chart.refetch();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultFromTo();
  },
  methods: {
    defaultFromTo() {
      const d = new Date();
      this.filterData = {
        ...this.filterData,
        startDate: this.$moment(d).subtract(6, 'days').format('YYYY-MM-DD'),
        endDate: this.$moment(d).format('YYYY-MM-DD'),
      };
      this.$apollo.queries.stats.refetch();
      this.$apollo.queries.chart.refetch();
    },
    viewFullHistory() {
      this.$router.push({ name: this.currentView.name });
    },
  },
};
</script>

<style scoped>
.ecs-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 25px;
  gap: 20px;
}
.light-box {
  background: var(--cs-info-lightest);
  border: 1px solid var(--cs-info-base);
}
.second-box {
  background: var(--cs-primary-lightest);
  border: 1px solid var(--cs-primary-base);
}
.forth-box {
  background: var(--cs-warning-lightest);
  border: 1px solid var(--cs-warning-base);
}
.ecs-box {
  flex: 1;
  border-radius: 5px;
  padding: 18px;
}
.chart {
  background: var(--cs-gray-01);
  border: 1px solid var(--cs-gray-02);
}
.ecs-dashboard-row {
  margin-top: 31px;
}
.ecs-stat-number {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: var(--cs-gray-05);
  font-family: var(--cs-font-primary);
  margin-top: 10px;
}
</style>
