<template>
  <div class="ecs-card-list">
    <div class="ecs-card-list__top">
      <div>Active Cards</div>
    </div>
    <div
      v-for="paymentMethod in paymentMethodList"
      :key="paymentMethod.id"
      class="ecs-card-list__items"
      :class="{
        'ecs-card-list__items-active': paymentMethod.isDefault,
      }"
    >
      <div class="ecs-card-list__items--placement">
        <img
          :src="brandImg(paymentMethod.card.brand)"
          class="ecs-card-list__items-card-img"
        />
        <div class="placement">
          <div>
            {{ paymentMethod.card.brand }} ending in
            {{ paymentMethod.card.last4 }}
          </div>
          <div class="ecs-card-list__items--placement__expires">
            Expires {{ paymentMethod.card.exp_month }}/{{
              paymentMethod.card.exp_year
            }}
          </div>
        </div>
      </div>
      <div class="ecs-card-list__items--placement">
        <cs-tag
          v-if="paymentMethod.isDefault"
          class="ecs-card-list__items--placement_tag"
          >Default</cs-tag
        >
        <div>
          <i :id="paymentMethod.id" class="cs-icons-options" tabindex="-1"></i
          ><b-popover
            ref="actionPopover"
            :target="paymentMethod.id"
            triggers="click blur"
            custom-class="bg-dark"
            placement="leftbottom"
          >
            <div class="ecs-dashboard__popover">
              <div
                v-if="!paymentMethod.isDefault"
                @click.stop="makeDefaultCard(paymentMethod)"
              >
                Make Default Card
              </div>
              <div @click.stop="showUpdateCard(paymentMethod)">Update Card</div>
              <div
                v-if="!paymentMethod.isDefault"
                @click.stop="removeCard(paymentMethod)"
              >
                Remove Card
              </div>
            </div>
          </b-popover>
        </div>
      </div>
    </div>
    <app-update-card-modal
      v-if="selectedPaymentMethod"
      :payment-method="selectedPaymentMethod"
      :show-edit-modal="!!selectedPaymentMethod"
      @close="hideUpdateCard"
      @fetch-cards="$emit('fetch-cards')"
    />
  </div>
</template>

<script>
import AppUpdateCardModal from '@/components/paymentMethods/UpdateCardModal.vue';
// GQL
import UpdateDefaultCard from '@/gql/paymentMethods/UpdateDefaultCard.gql';
import RemovePaymentMethod from '@/gql/paymentMethods/RemovePaymentMethod.gql';

export default {
  components: {
    AppUpdateCardModal,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
      selectedPaymentMethod: null,
    };
  },
  computed: {
    paymentMethodList() {
      let defaultMethod = {};
      const methodList = [];
      // eslint-disable-next-line array-callback-return
      this.customer.payment_methods.map((data) => {
        if (this.customer.invoice_settings.default_payment_method === data.id)
          defaultMethod = {
            ...data,
            isDefault: true,
          };
        else methodList.push(data);
      });
      return [defaultMethod, ...methodList];
    },
  },
  methods: {
    brandImg(brand) {
      switch (brand) {
        case 'visa':
          return 'https://usa.visa.com/dam/VCOM/blogs/visa-blue-gradient-800x450.jpg';
        case 'mastercard':
          return 'https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg';
        case 'amex':
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/601px-American_Express_logo_%282018%29.svg.png?20191022102801';
        case 'jcb':
          return 'http://www.jcbeurope.eu/about/emblem_slogan/images/index/logo_img01.jpg';
        case 'unionpay':
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/120px-UnionPay_logo.svg.png?20180128134319';
        case 'discover':
          return 'https://seeklogo.com/images/D/Discover_Card-logo-4BC5D7C02C-seeklogo.com.png';
        case 'diners':
          return 'https://i.pinimg.com/736x/dd/e9/b3/dde9b3ac8f3ae9dde573e3607c36f1a1.jpg';
        default:
          return 'https://img.freepik.com/free-vector/illustration-credit-card-icon_53876-5915.jpg';
      }
    },
    showUpdateCard(paymentMethod) {
      this.$root.$emit('bv::hide::popover');
      this.selectedPaymentMethod = paymentMethod;
      this.showEditModal = true;
    },
    hideUpdateCard() {
      this.selectedPaymentMethod = null;
      this.showEditModal = false;
    },
    async removeCard(paymentMethod) {
      await this.$apollo.mutate({
        mutation: RemovePaymentMethod,
        variables: {
          projectId: this.$route.params.id,
          paymentMethodsId: paymentMethod.id,
        },
      });
      this.$emit('fetch-cards');
    },
    async makeDefaultCard(paymentMethod) {
      await this.$apollo.mutate({
        mutation: UpdateDefaultCard,
        variables: {
          projectId: this.$route.params.id,
          paymentMethodsId: paymentMethod.id,
        },
      });
      this.$emit('fetch-cards');
    },
  },
};
</script>

<style scoped>
.ecs-card-list {
  background: var(--cs-gray-01);
  border: 1px solid var(--cs-gray-02);
  margin-top: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.ecs-card-list__top {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.ecs-card-list__top div {
  font-size: 30px;
  font-weight: 500;
}
.ecs-card-list__items {
  padding: 16px 32px 16px 32px;
  height: 58px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--cs-gray-02);
}
.ecs-card-list__items:last-child {
  display: flex;
  justify-content: space-between;
}
.ecs-card-list__items-active {
  border: 1px solid var(--cs-primary-base);
  background: #e5f9f0;
}
.ecs-card-list__items--placement {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  text-align: center;
  align-items: center;
  gap: 30px;
}
.ecs-card-list__items--placement img {
  width: 60px;
  height: 35px;
}
.ecs-card-list__items--placement i {
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}
.ecs-card-list__items--placement__expires {
  justify-self: flex-end;
}
.ecs-card-list__items--placement_tag {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.placement {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 400px;
}
.ecs-card-list__items-card-img {
  object-fit: cover;
}
</style>
