<template>
  <div>
    <cs-modal
      :show="showUpdateMediaLibrary"
      class="ecs-edit-library-modal"
      @close="onClose"
    >
      <div slot="header">Edit Library</div>
      <div slot="body">
        <div class="ecs-edit-library-modal__inputs">
          <cs-input
            v-model="libraryName"
            placeholder="ex: Project Library"
            label="Name"
            required
          />
          <cs-textarea
            v-model="description"
            label="Description"
            placeholder="ex: My library for 'Default' project"
          >
          </cs-textarea>
          <div v-if="mediaLibrary" class="ecs-edit-library-modal__features">
            <div>
              <p class="cs-textstyle-paragraph-bold">Video Features</p>
              <div
                v-if="
                  mediaLibrary.video_features &&
                  mediaLibrary.video_features.length
                "
              >
                <p
                  v-for="feature in mediaLibrary.video_features"
                  :key="feature"
                >
                  {{ makeFeature(feature) }}
                </p>
              </div>
              <div v-else>No features</div>
            </div>
            <div>
              <p class="cs-textstyle-paragraph-bold">Image Features</p>
              <div
                v-if="
                  mediaLibrary.image_features &&
                  mediaLibrary.image_features.length
                "
              >
                <p
                  v-for="feature in mediaLibrary.image_features"
                  :key="feature"
                >
                  {{ makeFeature(feature) }}
                </p>
              </div>
              <div v-else>No features</div>
            </div>
            <div>
              <p class="cs-textstyle-paragraph-bold">Audio Features</p>
              <div
                v-if="
                  mediaLibrary.audio_features &&
                  mediaLibrary.audio_features.length
                "
              >
                <p
                  v-for="feature in mediaLibrary.audio_features"
                  :key="feature"
                >
                  {{ makeFeature(feature) }}
                </p>
              </div>
              <div v-else>No features</div>
            </div>
          </div>
        </div>
      </div>
      <cs-button
        slot="main"
        :disabled="!libraryName"
        variant="primary"
        @click="updateMediaLibrary"
        >Save Changes</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import UpdateMediaLibrary from '@/gql/medialibrary/UpdateMediaLibrary.gql';

export default {
  props: {
    showUpdateMediaLibrary: {
      type: Boolean,
      required: true,
    },
    mediaLibrary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      libraryName: '',
      description: '',
    };
  },
  watch: {
    showUpdateMediaLibrary: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.libraryName = this.mediaLibrary.name;
          this.description = this.mediaLibrary.description;
        }
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close-update-media-library');
    },
    async updateMediaLibrary() {
      await this.$apollo.mutate({
        mutation: UpdateMediaLibrary,
        variables: {
          name: this.libraryName,
          updateMediaLibraryId: this.mediaLibrary.id,
          description: this.description,
        },
      });
      this.$emit('close-update-media-library', true);
    },
    makeFeature(feature) {
      return feature
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
};
</script>

<style scoped>
.ecs-edit-library-modal {
  ---cs-modal-width: 900px !important;
}
.ecs-edit-library-modal__inputs {
  margin: 20px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ecs-edit-library-modal__features {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.ecs-button {
  float: right;
}
</style>
