<template>
  <div class="ecs-accordian" role="tablist">
    <b-card v-for="item in items" :key="item" no-body>
      <b-card-header
        header-tag="header"
        role="tab"
        @click="onClickAccordianItem(item)"
      >
        <div v-b-toggle="item" class="ecs-accordian-header">
          <div class="cs-textstyle-paragraph-small-bold">
            {{ item | formatTag }}
          </div>
          <div>
            <span class="when-open"><i class="cs-icons-expand-arrow"></i></span>
            <span class="when-closed"><i class="cs-icons-next-angle"></i></span>
          </div>
        </div>
      </b-card-header>
      <b-collapse :id="item" visible accordion="my-accordion" role="tabpanel">
        <b-card-body v-if="item === 'content-moderation'">
          <div
            v-if="isFeatureSelected(item)"
            class="cs-textstyle-paragraph-small"
          >
            {{ featureNotSelectedText }}
          </div>
          <b-card-text v-if="job.moderation_status"
            >Overall Score &nbsp;
            <cs-tag
              v-if="job.moderation_status === 'SAFE'"
              class="ecs-tag__COMPLETED"
              >Safe &nbsp; <i class="cs-icons-check"></i
            ></cs-tag>
            <cs-tag
              v-if="job.moderation_status === 'UNSAFE'"
              class="ecs-tag__FAILED"
              >Unsafe &nbsp; <i class="cs-icons-close"></i>
            </cs-tag>
          </b-card-text>
          <div
            v-if="contentModerationItems.length"
            class="ecs-content-moderation-table-container"
          >
            <b-table :items="contentModerationItems" borderless>
              <template v-slot:cell(class)="data">
                <div class="label">
                  <span
                    class="box"
                    :style="{ background: randomColor() }"
                  ></span>
                  <span>{{ data.item.class }}</span>
                </div>
              </template>
            </b-table>
          </div>
        </b-card-body>
        <b-card-body v-if="item === 'ocr'"
          ><div
            v-if="isFeatureSelected(item)"
            class="cs-textstyle-paragraph-small"
          >
            {{ featureNotSelectedText }}
          </div>
        </b-card-body>
        <b-card-body v-if="item === 'object-detection'">
          <div
            v-if="isFeatureSelected(item)"
            class="cs-textstyle-paragraph-small"
          >
            {{ featureNotSelectedText }}
          </div>
          <div v-else>
            <b-table
              v-if="objectDetectionItems"
              :fields="objectDetectionFields"
              :items="objectDetectionItems"
              borderless
            >
              <template v-slot:cell(class)="data">
                <div class="label">
                  <span
                    class="box"
                    :style="{ background: data.item.color }"
                  ></span>
                  <span>{{ data.item.class }}</span>
                </div>
              </template>
            </b-table>
          </div>
        </b-card-body>
        <b-card-body v-if="item === 'tagging'">
          <div
            v-if="isFeatureSelected(item)"
            class="cs-textstyle-paragraph-small"
          >
            {{ featureNotSelectedText }}
          </div>
          <div
            v-if="tagsList.length"
            class="cs-textstyle-paragraph-small tagging"
          >
            <cs-tag v-for="tag in tagsList" :key="tag">{{ tag }}</cs-tag>
          </div>
        </b-card-body>
        <b-card-body v-if="item === 'metadata'">
          <div
            v-if="isFeatureSelected(item)"
            class="cs-textstyle-paragraph-small"
          >
            {{ featureNotSelectedText }}
          </div>
          <div v-if="job.metadata">
            <div v-for="(value, name) in job.metadata" :key="name">
              <div
                v-if="name !== '__typename'"
                class="cs-textstyle-paragraph-small ecs-metadata-item"
              >
                <div>{{ name.split('_').join(' ') }}</div>
                <div>{{ value }}</div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { randomColor } from '@/services/utils';
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    job: {
      type: Object,
      required: true,
    },
    objects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      randomColor,
      objectDetectionFields: ['class', 'confidence_score'],
      featureNotSelectedText: "This feature wasn't selected",
    };
  },
  computed: {
    objectDetectionItems() {
      if (this.objects) {
        const objects = this.objects.map((obj) => ({
          class: obj.class,
          confidence_score: `${(obj.score * 100).toFixed()} %`,
          color: obj.color,
        }));
        return objects;
      }
      return [];
    },
    contentModerationItems() {
      if (this.job) {
        const items = this.job.moderation_nudity.concat(
          this.job.moderation_violence
        );
        const objects = items.map((obj) => ({
          class: obj.class_name,
          confidence_score: `${(obj.probability * 100).toFixed()} %`,
        }));
        return objects;
      }
      return [];
    },
    tagsList() {
      if (this.job) {
        return this.job.tags.map((tag) => tag.class_name);
      }
      return [];
    },
  },
  methods: {
    onClickAccordianItem(item) {
      this.$emit('open-accordian-item', item);
    },
    isFeatureSelected(feature) {
      return this.job.features.indexOf(feature) === -1;
    },
  },
};
</script>

<style>
.ecs-accordian {
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.ecs-accordian .card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.ecs-accordian .card:last-child {
  border-bottom: 0;
}
.ecs-accordian .card-header {
  padding: 0.8rem 1rem;
}
.ecs-accordian .card-body {
  background-color: var(--cs-gray-01);
}
.ecs-accordian .card-header {
  border: none;
}
.ecs-accordian-header {
  display: flex;
  justify-content: space-between;
}
.ecs-accordian-header > div:first-child {
  text-transform: uppercase;
}
.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}
.label {
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}
.box {
  height: 9px;
  width: 9px;
}
.tagging {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.ecs-content-moderation-table-container {
  background: var(--cs-gray-00);
  padding: 16px;
}
.ecs-metadata-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  text-transform: capitalize;
}
.ecs-metadata-item > div:first-child {
  color: var(--cs-gray-05);
}
.ecs-metadata-item > div:last-child {
  color: var(--cs-gray-06);
}
</style>
