<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header d-flex flex-row justify-content-between">
      <div class="ecs-dashboard__header">
        <div class="ecs-dashboard__header-title">
          File Conversion / <span>Dashboard</span>
        </div>
      </div>
      <cs-button
        class="d-flex align-self-end justify-self-end"
        :disabled="disable"
        @click="showFile"
        >New Conversion</cs-button
      >
    </div>
    <app-dashboard-box-row view="file" />
    <app-file-conversion-modal
      :show-add="showFileConversionModal"
      @close-file-conversion-modal="closeFile"
      @update-jobs-history="closeAfterJobCreated"
    />
  </div>
</template>

<script>
import $bus from '@/services/bus';
import { getAccessLevel } from '@/services/utils';
import AppDashboardBoxRow from '@/components/general/DashboardBoxRow.vue';
import AppFileConversionModal from '@/components/fileconversion/AddFileConversionJobModal.vue';

export default {
  components: { AppDashboardBoxRow, AppFileConversionModal },
  data() {
    return {
      showFileConversionModal: false,
      disable: null,
    };
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showFile() {
      this.showFileConversionModal = true;
    },
    closeFile() {
      this.showFileConversionModal = false;
    },
    async closeAfterJobCreated() {
      this.showFileConversionModal = false;
    },
  },
};
</script>

<style scoped></style>
