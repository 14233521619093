<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Billing / <span>Invoices</span>
      </div>
    </div>
    <!-- Invoice Info -->
    <div class="ecs-invoice-list__info">
      <div class="ecs-invoice-list__info__top">
        <div class="cs-textstyle-section-heading">Invoice Info</div>
        <div>
          <cs-button
            fill="outline"
            :disabled="disable"
            @click="openEditProjectModal"
            >Edit Info</cs-button
          >
        </div>
      </div>
      <div class="ecs-invoice-list__info__content">
        <!-- Company Name -->
        <div class="ecs-invoice-list__info-content">
          <div class="cs-textstyle-paragraph-small-bold">
            Company Name <i class="cs-icons-info"></i>
          </div>
          <div class="cs-textstyle-paragraph-small">
            {{ project.company_name }}
          </div>
        </div>
        <!-- Company Address -->
        <div class="ecs-invoice-list__info-content">
          <div class="cs-textstyle-paragraph-small-bold">
            Company Address <i class="cs-icons-info"></i>
          </div>
          <div class="cs-textstyle-paragraph-small">
            {{ project.company_address }}
          </div>
        </div>
        <!-- National Tax ID -->
        <div class="ecs-invoice-list__info-content">
          <div class="cs-textstyle-paragraph-small-bold">
            National Tax ID <i class="cs-icons-info"></i>
          </div>
          <div class="cs-textstyle-paragraph-small">
            {{ project.company_national_tax_id }}
          </div>
        </div>
      </div>
      <div
        v-if="$apollo.queries.project_invoices.loading"
        class="ecs-spinner__wrapper"
      >
        <cs-spinner class="ecs-spinner" />
      </div>
    </div>

    <!-- Upcoming Payment -->
    <app-upcoming-payment v-if="upcoming" :upcoming-payment="upcoming" />
    <!-- Table -->
    <div
      v-if="project_invoices && project_invoices.length"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="project_invoices"
        :fields="fields"
        borderless
        hover
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.project_invoices.loading"
        @row-clicked="goToInvoiceDetails"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- ID -->
        <template v-slot:cell(id)="data">
          <span> #{{ data.item.id }}</span>
        </template>
        <!-- Period -->
        <template v-slot:cell(month)="data">
          <span> {{ data.item.month | moment('MMMM YYYY') }}</span>
        </template>
        <!-- Status -->
        <template v-slot:cell(status)="data">
          <span>
            <cs-tag
              :class="[
                data.item.status === 'PAID'
                  ? 'ecs-tag__success'
                  : data.item.status === 'UNPAID'
                  ? 'ecs-tag__danger'
                  : 'ecs-tag__processing',
              ]"
              >{{ data.item.status }}</cs-tag
            ></span
          >
        </template>
        <!-- Amount -->
        <template v-slot:cell(total_usd)="data">
          <span>{{ data.item.total_usd | currency }}</span>
        </template>
        <!-- Action -->
        <template v-slot:cell(action)>
          <span><i class="cs-icons-doc ecs-icon"></i></span>
        </template>
      </b-table>
    </div>
    <!-- edit project modal  -->
    <app-edit-project-modal
      :show-project-modal="showEditProjectModal"
      :project="project"
      invoice-only
      @close-edit-project-modal="closeEditProjectModal"
    />
  </div>
</template>

<script>
import ListProjectInvoices from '@/gql/billing/ListProjectInvoices.gql';
import GetProject from '@/gql/project/GetProject.gql';
import GetUpcomingInvoice from '@/gql/billing/GetUpcomingInvoice.gql';
import AppEditProjectModal from '@/components/project/EditProjectModal.vue';
import AppUpcomingPayment from '@/components/billing/UpcomingPayment.vue';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';

export default {
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
  components: {
    AppEditProjectModal,
    AppUpcomingPayment,
  },
  data() {
    return {
      disable: null,
      showEditProjectModal: false,
      projectId: this.$route.params.id,
      payment: {
        status: 'Upcoming',
        chargeTillDate: '$12 till June 5',
        paymentDate: 'June 31',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'month',
          label: 'Period',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'total_usd',
          label: 'Amount',
          thClass: 'ecs-dashboard__table-head',
        },
        { key: 'action', label: '', thClass: 'ecs-dashboard__table-head' },
      ],
      project: {
        company_name: '',
        company_address: '',
        company_national_tax_id: '',
      },
    };
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  apollo: {
    project_invoices: {
      query: ListProjectInvoices,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
    },
    project: {
      query: GetProject,
      variables() {
        return {
          id: this.projectId,
        };
      },
    },
    upcoming: {
      query: GetUpcomingInvoice,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
    },
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'ADMIN') {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    goToInvoiceDetails(record) {
      this.$router.push({
        name: 'InvoiceDetail',
        params: { month: record.month },
      });
    },
    openEditProjectModal() {
      this.showEditProjectModal = true;
    },
    closeEditProjectModal() {
      this.showEditProjectModal = false;
    },
  },
};
</script>

<style scoped>
.ecs-invoice-list__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
  padding: 20px;
}
.ecs-invoice-list__info__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-invoice-list__info__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
}
.ecs-invoice-list__info__top div {
  font-weight: 500;
}
.ecs-invoice-list__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 300px;
}
.ecs-invoice-list__info-content i {
  color: var(--cs-gray-03);
  font-size: 15px;
}
.ecs-tag__success {
  --cs-tag-color: var(--cs-primary-lightest) !important;
  color: var(--cs-primary-base) !important;
}
.ecs-tag__danger {
  --cs-tag-color: var(--cs-danger-lightest) !important;
  color: var(--cs-danger-base) !important;
}
.ecs-tag__processing {
  --cs-tag-color: var(--cs-warning-lightest) !important;
  color: var(--cs-warning-base) !important;
}
.ecs-icon {
  font-size: 20px;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
</style>
