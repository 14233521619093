<template>
  <div>
    <cs-modal :show="showDeleteUser" class="ecs-users" @close="onClose">
      <div slot="header">Remove {{ user.name || user.email }}?</div>
      <div slot="body" class="ecs-users__body">
        <div class="ecs-users__body-header">
          Are you sure you want to remove {{ user.name || user.email }} from
          this project?
        </div>
        <div class="ecs-users__body-subheader">
          To confirm, please type {{ user.email }} below. This change can't be
          reversed.
        </div>

        <div class="ecs-users__prompt">
          <cs-input
            v-model="email"
            :placeholder="'Type here: ' + user.email"
            required
            class="ecs-users__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="danger"
        :disabled="!valid"
        @click="deleteUser"
        >Confirm Remove</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import DeleteProjectMember from '@/gql/user/DeleteProjectMember.gql';

export default {
  props: {
    showDeleteUser: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    valid() {
      return this.email === this.user.email;
    },
  },
  methods: {
    reset() {
      this.email = '';
    },
    onClose() {
      this.reset();
      this.$emit('close-delete-user');
    },
    async deleteUser() {
      if (this.email === this.user.email) {
        await this.$apollo.mutate({
          mutation: DeleteProjectMember,
          variables: {
            projectId: this.projectId,
            userId: this.user.id,
          },
        });
        this.reset();
        this.$emit('close-delete-user', true);
      } else {
        this.reset();
      }
    },
  },
};
</script>

<style scoped>
.ecs-users__body {
  width: 100%;
}
.ecs-users__body-header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-users__body-subheader {
  color: var(--cs-gray-04);
  font-size: 14px;
}
.ecs-users__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-users__wide-input {
  width: 100%;
}
</style>
