<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Connect your destination cloud provider where you want to save your files
      once transcoded.
    </div>
    <div class="cs-textstyle-detail-heading my-2">Choose Destination</div>
    <div class="ecs-transcoding-job__list">
      <div v-for="(item, index) in items" :key="index">
        <div
          :class="active === index ? 'ecs-transcoding-job__active-items' : ''"
          class="ecs-transcoding-job__non-active-items"
          @click="toggleSource(index, item)"
        >
          <div>
            <span v-if="!item.img">{{ item.text }}</span
            ><img
              v-else
              :src="item.img"
              :class="item.text === 'AWS' ? 'ecs-transcoding-job__aws-img' : ''"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ecs-transcoding-job__input-wrapper my-2">
      <div class="ecs-transcoding-job__input-wrapper--secret-keys">
        <div class="ecs-transcoding-job__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="destinationBucketName"
            :label="`${items[active].text} Bucket Name`"
            class="ecs-transcoding-job__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="destinationBucketPath"
            :label="`${items[active].text} Bucket Path`"
            class="ecs-transcoding-job__input-wrapper-inputs"
            required
          />
        </div>
        <div class="ecs-transcoding-job__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="destinationAccessKeyId"
            :label="`${items[active].text} Access Key ID`"
            class="ecs-transcoding-job__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="destinationSecretAccessKey"
            :label="`${items[active].text} Secret Access Key`"
            class="ecs-transcoding-job__input-wrapper-inputs"
            required
          />
        </div>
      </div>
    </div>
    <cs-button
      class="ecs-button"
      :disabled="isDisabled"
      @click="createTranscodingJob"
    >
      Transcode
    </cs-button>
  </div>
</template>

<script>
import CreateJob from '@/gql/transcoding/CreateJob.gql';

const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: 0,
      items: [
        {
          text: 'AWS',
          destinationProvider: 'AWS',
          img: awsImgUrl,
        },
        {
          text: 'Google Cloud',
          destinationProvider: 'GCP',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          destinationProvider: 'DIGITALOCEAN',
          img: doImgUrl,
        },
        {
          text: 'Azure',
          destinationProvider: 'AZURE',
          img: azureImgUrl,
        },
      ],
      destinationBucketName: null,
      destinationBucketPath: null,
      destinationAccessKeyId: null,
      destinationSecretAccessKey: null,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.destinationBucketName ||
        !this.destinationBucketPath ||
        !this.destinationAccessKeyId ||
        !this.destinationSecretAccessKey
      );
    },
  },
  methods: {
    toggleSource(i) {
      this.active = i;
    },
    async createTranscodingJob() {
      await this.$apollo.mutate({
        mutation: CreateJob,
        variables: {
          outputFormats: this.data.format,
          destinationProvider: this.items[this.active].destinationProvider,
          sourceProvider: this.data.sourceProvider,
          projectId: this.$route.params.id,
          sourceBucketPath: this.data.sourceBucketPath,
          sourceBucketName: this.data.sourceBucketName,
          sourceAccessKey: this.data.sourceAccessKeyId,
          sourceUrl: this.data.sourceUrl,
          sourceSecretKey: this.data.sourceSecretAccessKey,
          destinationAccessKey: this.destinationAccessKeyId,
          destinationBucketName: this.destinationBucketName,
          destinationBucketPath: this.destinationBucketPath,
          destinationSecretKey: this.destinationSecretAccessKey,
          webhookUrl: this.data.webHookURL,
        },
      });
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 40px;
}
.ecs-button {
  float: right;
}
.ecs-transcoding-job__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-transcoding-job__active-items i {
  color: var(--cs-gray-06);
}
.ecs-transcoding-job__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 100px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-transcoding-job__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-transcoding-job__non-active-items img {
  width: 80px;
}
.ecs-transcoding-job__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.ecs-transcoding-job__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-transcoding-job__input-wrapper {
  background: var(--cs-gray-01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}
.ecs-transcoding-job__input-wrapper-inputs {
  width: 100%;
}
.ecs-transcoding-job__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-transcoding-job__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-transcoding-job__settings-input {
  width: 80%;
}
.ecs-transcoding-job__aws-img {
  padding-left: 5px;
  width: 40px !important;
}
</style>
