<template>
  <div>
    <cs-modal :show="showDelete" @close="onClose">
      <div slot="header">{{ modalTitle }}</div>
      <div slot="body" class="ecs-delete">
        <div class="ecs-delete__header cs-textstyle-paragraph-bold">
          {{ title }}
        </div>
        <div class="ecs-delete__sub-header cs-textstyle-paragraph-small">
          {{ description }}
        </div>

        <div class="ecs-delete__prompt">
          <cs-input
            v-model="name"
            :label="label"
            placeholder="Enter Here"
            required
            class="ecs-delete__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="danger"
        :disabled="!valid"
        @click="deleteSite"
      >
        Confirm Delete
      </cs-button>
    </cs-modal>
  </div>
</template>

<script>
export default {
  props: {
    showDelete: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'Are you sure you want to delete this item',
    },
    description: {
      type: String,
      default:
        "Please enter the item name you wish to delete. This change can't be reversed",
    },
    label: {
      type: String,
      default: 'Item name',
    },
    item: {
      type: Object,
      required: true,
    },
    modalTitle: {
      type: String,
      default: 'Delete Item',
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    valid() {
      return !!this.name && this.name === this.item.name;
    },
  },
  methods: {
    onClose() {
      this.$emit('close-delete-site-modal');
    },
    async deleteSite() {
      if (this.valid) {
        this.$emit('confirm-delete');
      }
    },
  },
};
</script>

<style scoped>
.ecs-delete {
  width: 100%;
}
.ecs-delete__header {
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-delete__sub-header {
  color: var(--cs-gray-04);
}
.ecs-delete__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-delete__wide-input {
  width: 100%;
}
</style>
