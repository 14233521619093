<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Image Insights / <span>Job ID {{ jobId }}</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button fill="outline" @click="onDownloadJSON"
          >Download JSON</cs-button
        >
      </div>
    </div>
    <!-- Info -->
    <div v-if="job" class="ecs-image-insights-detail__info">
      <div class="ecs-image-insights-detail__info__content">
        <!-- Job Id -->
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Job Id</div>

          <div class="cs-textstyle-paragraph-small">
            {{ jobId }} <i class="cs-icons-copy" @click="onCopyJobId"></i>
          </div>
        </div>
        <!-- Job Features -->
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Job Features</div>
          <div
            class="cs-textstyle-paragraph-small ecs-image-insights-detail__features"
          >
            <cs-tag v-for="feature in job.features" :key="feature">{{
              feature | formatTag
            }}</cs-tag>
          </div>
        </div>
        <!-- Job Status -->
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Job Status</div>
          <div class="cs-textstyle-paragraph-small">
            <cs-tag :class="['ecs-tag__' + job.status]">{{
              job.status
            }}</cs-tag>
          </div>
        </div>
        <!-- Cost -->
        <div v-if="job.cost" class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Cost</div>
          <!-- TODO Get Cost from Backend -->
          <div class="cs-textstyle-paragraph-small">
            {{ job.cost | currency }}
          </div>
        </div>
        <!-- Started -->
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Started</div>
          <div class="cs-textstyle-paragraph-small">
            {{ job.created_at | moment('DD MMM YYYY, hh:mm A') }}
            (UTC)
          </div>
        </div>
      </div>
      <app-file-info
        v-if="job.source_url"
        :heading-text="'Source File'"
        :file="job.source_url"
        :size="job.file_size"
      ></app-file-info>
      <app-file-info
        v-if="job.destination_url"
        :heading-text="'Destination File'"
        :file="job.destination_url"
        :size="job.file_size"
      ></app-file-info>
    </div>
    <!-- Body -->
    <app-image-insight-job v-if="job" :file-data="job"> </app-image-insight-job>
  </div>
</template>

<script>
import GetImageInsightJob from '@/gql/imageinsight/GetImageInsightJob.gql';
import AppFileInfo from '@/components/imageinsight/FileInfo.vue';
import AppImageInsightJob from '@/components/medialibrary/ImageInsightJob.vue';
import { getLastNoOfElements, download } from '@/services/utils';

export default {
  components: {
    AppFileInfo,
    AppImageInsightJob,
  },
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
  apollo: {
    job: {
      query: GetImageInsightJob,
      variables() {
        return {
          getImageInsightJobId: this.$route.params.jobid,
        };
      },
    },
  },
  data() {
    return {
      projectId: this.$route.params.id,
    };
  },
  computed: {
    jobId() {
      if (this.job) {
        return getLastNoOfElements(this.job.id, 12);
      }
      return '';
    },
  },
  methods: {
    onCopyJobId() {
      navigator.clipboard.writeText(this.job.id);
    },
    onDownloadJSON() {
      download(
        JSON.stringify(this.job),
        `image-insight-${this.job.id}.json`,
        'text/json'
      );
    },
  },
};
</script>

<style scoped>
.ecs-image-insights-detail__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 45px;
  margin-top: 40px;
  padding: 20px;
}
.ecs-image-insights-detail__info__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-image-insights-detail__info__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
}
.ecs-image-insights-detail__info__top div {
  font-weight: 500;
}
.ecs-image-insights-detail__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
}
.ecs-image-insights-detail__features {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.ecs-image-insights-detail__info-content i.cs-icons-copy {
  color: var(--cs-primary-base);
  font-size: medium;
  cursor: pointer;
}
</style>
