import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import Home from '@/views/Home.vue';
// Account
import Account from '@/views/account/Account.vue';
// Project
import ProjectDashboard from '@/views/project/Dashboard.vue';
// Transcoding
import Transcoding from '@/views/transcoding/Transcoding.vue';
import TranscodingDashboard from '@/views/transcoding/Dashboard.vue';
import JobsList from '@/views/transcoding/JobsList.vue';
import JobDetail from '@/views/transcoding/JobDetail.vue';
// Key Management
import ProjectKeysList from '@/views/keymanagement/ProjectKeysList.vue';
// User Management
import UserList from '@/views/usermanagement/UsersList.vue';
// Static Site
import StaticSiteList from '@/views/staticsite/StaticSiteList.vue';
import StaticSite from '@/views/staticsite/StaticSite.vue';
import StaticSiteDetail from '@/views/staticsite/StaticSiteDetail.vue';
import StaticSiteBuildDetail from '@/views/staticsite/StaticSiteBuildDetail.vue';
// Billing
import PaymentMethods from '@/views/billing/PaymentMethods.vue';
import InvoiceList from '@/views/billing/InvoiceList.vue';
import CostExplorer from '@/views/billing/CostExplorer.vue';
import InvoiceDetail from '@/views/billing/InvoiceDetail.vue';
import Billing from '@/views/billing/Billing.vue';
// Image Insights
import ImageInsight from '@/views/imageinsight/ImageInsight.vue';
import ImageInsightHistory from '@/views/imageinsight/ImageInsightHistory.vue';
import ImageInsightsDetail from '@/views/imageinsight/ImageInsightsDetail.vue';
// Video Insights
import VideoInsight from '@/views/videoinsight/VideoInsight.vue';
import VideoInsightHistory from '@/views/videoinsight/VideoInsightHistory.vue';
// Caption
import Caption from '@/views/caption/Caption.vue';
import CaptionDashboard from '@/views/caption/Dashboard.vue';
import CaptionJobList from '@/views/caption/JobList.vue';
import CaptionsJobDetail from '@/views/caption/CaptionsJobDetail.vue';
// File Conversion
import FileConversion from '@/views/fileconversion/FileConversion.vue';
import FileConversionDashboard from '@/views/fileconversion/FileConversionDashboard.vue';
import FileConversionDetail from '@/views/fileconversion/FileConversionDetail.vue';
import FileConversionJobList from '@/views/fileconversion/JobList.vue';
// Media Library
import MediaLibraryAssetList from '@/views/medialibrary/MediaLibraryAssetList.vue';
import MediaLibraryJobs from '@/views/medialibrary/MediaLibrary.vue';
import MediaLibrary from '@/views/medialibrary/Media.vue';
import MediaLibraryAssetDetail from '@/views/medialibrary/MediaLibraryAssetDetail.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'account',
        name: 'Account',
        component: Account,
      },
      {
        path: 'project/:id',
        name: 'ProjectDashboard',
        component: ProjectDashboard,
      },
      {
        path: 'project/:id/iam/users',
        name: 'UserList',
        component: UserList,
      },
      {
        path: 'project/:id/iam/keys',
        name: 'ProjectKeysList',
        component: ProjectKeysList,
      },
      {
        path: 'project/:id/transcoding',
        name: 'Transcoding',
        component: Transcoding,
        children: [
          {
            path: 'dashboard',
            name: 'TranscodingDashboard',
            component: TranscodingDashboard,
          },
          {
            path: 'list',
            name: 'JobsList',
            component: JobsList,
          },
          {
            path: ':jobId/detail',
            name: 'JobDetail',
            component: JobDetail,
          },
        ],
      },

      {
        path: 'project/:id/staticsite',
        component: StaticSite,
        name: 'StaticSite',
        children: [
          {
            path: 'list',
            name: 'StaticSiteList',
            component: StaticSiteList,
          },
          {
            path: ':siteid/detail',
            name: 'StaticSiteDetail',
            component: StaticSiteDetail,
          },
          {
            path: ':siteid/detail/:buildid/detail',
            name: 'StaticSiteBuildDetail',
            component: StaticSiteBuildDetail,
          },
        ],
      },
      {
        path: 'project/:id/billing',
        name: 'Billing',
        component: Billing,
        children: [
          {
            path: 'paymentmethods',
            name: 'PaymentMethods',
            component: PaymentMethods,
          },
          {
            path: 'invoicelist',
            name: 'InvoiceList',
            component: InvoiceList,
          },
          {
            path: 'costexplorer',
            name: 'CostExplorer',
            component: CostExplorer,
          },
          {
            path: 'invoicelist/:month/detail',
            name: 'InvoiceDetail',
            component: InvoiceDetail,
          },
        ],
      },
      {
        path: 'project/:id/imageinsight',
        name: 'ImageInsight',
        component: ImageInsight,
        children: [
          {
            path: 'history',
            name: 'ImageInsightHistory',
            component: ImageInsightHistory,
          },
          {
            path: 'history/:jobid/detail',
            name: 'ImageInsightsDetail',
            component: ImageInsightsDetail,
          },
        ],
      },
      {
        path: 'project/:id/medialibrary',
        name: 'MediaLibrary',
        component: MediaLibrary,
        children: [
          {
            path: 'dashboard',
            name: 'MediaLibraryDashboard',
            component: MediaLibraryJobs,
          },
          {
            path: 'dashboard/:mediaid',
            name: 'MediaLibraryAssetList',
            component: MediaLibraryAssetList,
          },
          {
            path: 'dashboard/:mediaid/assets/:assetid',
            name: 'MediaLibraryAssetDetail',
            component: MediaLibraryAssetDetail,
          },
        ],
      },
      {
        path: 'project/:id/videoinsight',
        name: 'VideoInsight',
        component: VideoInsight,
        children: [
          {
            path: 'history',
            name: 'VideoInsightHistory',
            component: VideoInsightHistory,
          },
        ],
      },
      {
        path: 'project/:id/caption',
        name: 'Caption',
        component: Caption,
        children: [
          {
            path: 'dashboard',
            name: 'CaptionDashboard',
            component: CaptionDashboard,
          },
          {
            path: 'list',
            name: 'CaptionJobList',
            component: CaptionJobList,
          },
          {
            path: ':jobId/detail',
            name: 'CaptionsJobDetail',
            component: CaptionsJobDetail,
          },
        ],
      },
      {
        path: 'project/:id/fileconversion',
        name: 'FileConversion',
        component: FileConversion,
        children: [
          {
            path: 'dashboard',
            name: 'FileConversionDashboard',
            component: FileConversionDashboard,
          },
          {
            path: ':jobId/detail',
            name: 'FileConversionDetail',
            component: FileConversionDetail,
          },
          {
            path: 'list',
            name: 'FileConversionJobList',
            component: FileConversionJobList,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // next();

  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    // Logged in but trying to access an onboarding screen
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    // Logged out but trying to access an app screen
    next('/login');
  } else {
    next();
  }
});

export default router;
