<template>
  <div class="ecs-key">
    <div class="ecs-key__alert">
      <cs-alert
        variant="warning"
        title="Please save this key"
        content="You will not be able to view it again."
        show-icon
        @click="close"
      />
    </div>
    <div class="ecs-key__id mb-3">
      <div class="ecs-key__id-tile cs-textstyle-paragraph-small-bold pb-1">
        Key Id
      </div>
      <div class="ecs-key__id-body">
        <div class="ecs-key__id-main cs-textstyle-paragraph-bold">
          {{ keyProperty.id }}
        </div>
        <div class="ecs-key__id-icon cs-textstyle-paragraph-bold">
          <i class="cs-icons-copy" @click="copyText(keyProperty.id)"></i>
        </div>
      </div>
    </div>
    <div class="ecs-key__id">
      <div class="ecs-key__id-tile cs-textstyle-paragraph-small-bold pb-1">
        Key Secret
      </div>
      <div class="ecs-key__id-body">
        <div class="ecs-key__id-main cs-textstyle-paragraph-bold">
          {{ keyProperty.secret }}
        </div>
        <div class="ecs-key__id-icon cs-textstyle-paragraph-bold">
          <i class="cs-icons-copy" @click="copyText(keyProperty.secret)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keyProperty: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close-created-key-detail');
    },
    async copyText(data) {
      await navigator.clipboard.writeText(data);
      this.$toast.open({
        message: 'Code copied',
        type: 'success',
        duration: 2000,
        dismissible: true,
        position: 'top',
      });
    },
  },
};
</script>

<style scoped>
.ecs-key {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.ecs-key__id-main {
  word-wrap: break-word;
  width: 80%;
}
.ecs-key__id-body {
  display: flex;
  justify-content: space-between;
}
.ecs-key__id-icon {
  cursor: pointer;
  color: var(--cs-primary-base);
  font-size: 20px;
}
</style>
