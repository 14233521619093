<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Select the output format of your source file, and enter webhook url for
      notification.
    </div>
    <div class="cs-textstyle-detail-heading my-2">Transcode Settings</div>
    <div class="transcoding-settings">
      <cs-select
        v-model="selectedFormat"
        :options="options"
        label="Output Format"
        class="ecs-transcoding-job__output-format"
      ></cs-select>
      <cs-input
        v-model="webHookURL"
        placeholder="Enter URL"
        label="Web Hook URL (Optional)"
      >
      </cs-input>
    </div>
    <cs-button class="ecs-button" :disabled="isDisabled" @click="nextStep">
      Continue
    </cs-button>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          label: 'MP4',
          value: 'MP4',
        },
        {
          label: 'MP3',
          value: 'MP3',
        },
        {
          label: 'THUMBNAILS',
          value: 'THUMBNAILS',
        },
        {
          label: 'HLS',
          value: 'HLS',
        },
      ],
      selectedFormat: null,
      webHookURL: null,
    };
  },
  computed: {
    isDisabled() {
      return !this.selectedFormat;
    },
  },
  methods: {
    nextStep() {
      this.$emit('next-step', {
        format: [this.selectedFormat],
        webHookURL: this.webHookURL,
      });
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 40px;
}
.ecs-button {
  float: right;
}
.transcoding-settings {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}
.ecs-transcoding-job__output-format {
  width: 210px;
}
</style>
