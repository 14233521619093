<template>
  <div class="cdn-spent-graph">
    <div>
      <span class="mt-2 cdn-spent-graph__header-select-label"
        >Choose Period:</span
      >
      <cs-select v-model="dateRange" :options="dateRangeOptions"></cs-select>
    </div>
    <div class="cdn-spent-graph__date-inputs">
      <cs-date-input
        v-model="filterData.startDate"
        :disabled="dateRange === 'last_30_days'"
        label=""
      ></cs-date-input>
      <cs-date-input
        v-model="filterData.endDate"
        :disabled="dateRange === 'last_30_days'"
        label=""
      ></cs-date-input>
    </div>
    <div class="chart">
      <div class="d-flex justify-content-between px-4 py-2">
        <div v-if="cdn_statistics" class="cs-textstyle-paragraph-bold">
          Total Bandwidth:
          {{ formatBytes(cdn_statistics.total_bandwidth_used) }}
        </div>
      </div>
      <line-chart
        v-if="!$apollo.queries.cdn_statistics.loading"
        :chart-data="chartData"
        :options="options"
      ></line-chart>
    </div>
  </div>
</template>

<script>
import ListCdnStatistics from '@/gql/staticsite/ListCdnStatistics.gql';
import LineChart from '@/components/charts/LineChart.vue';
import {
  getDynamicHeadings,
  formatBytes,
  groupByFormats,
  getDashboardDataset,
  getdashboardChartData,
} from '@/services/utils';

export default {
  components: { LineChart },
  props: {
    cdnId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    cdn_statistics: {
      query() {
        return this.chartGql;
      },
      variables() {
        return {
          listCdnStatisticsId: this.cdnId,
          endDate: this.filterData.endDate,
          fromDate: this.filterData.startDate,
        };
      },
      result({ data }) {
        const { cdn_statistics } = data;
        const { startDate, endDate } = this.filterData;
        const groupBy = 'day';
        const dynamicHeadings = getDynamicHeadings(startDate, endDate, groupBy);

        const dashboardChartData = getdashboardChartData(
          cdn_statistics.bandwidth_used_chart,
          dynamicHeadings,
          groupBy
        );
        this.chartData = {
          labels: [
            ...dynamicHeadings.map((date) =>
              this.$moment(date).format(groupByFormats[groupBy])
            ),
          ],
          datasets: [getDashboardDataset(dashboardChartData)],
        };
      },
      skip() {
        return (
          !this.filterData.startDate ||
          !this.filterData.endDate ||
          !this.dateRange
        );
      },
    },
  },
  data() {
    return {
      date: new Date(),
      formValid: false,
      formatBytes,
      chartGql: ListCdnStatistics,
      projectId: this.$route.params.id,
      dateRange: 'last_30_days',
      dateRangeOptions: [
        { label: 'Last 30 Days', value: 'last_30_days' },
        { label: 'Custom', value: 'custom' },
      ],
      chartData: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          padding: 28,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#E6EAEA',
                zeroLineColor: '#E6EAEA',
              },
              ticks: {
                fontColor: '#87939B',
                fontSize: 12,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: '#E6EAEA',
                zeroLineColor: '#E6EAEA',
              },
              scaleLabel: {
                display: true,
                fontSize: 14,
                fontWeight: 700,
                fontColor: '#B5C0C7',
                fontFamily: 'Roboto',
              },
              ticks: {
                fontColor: '#87939B',
                fontSize: 12,
                beginAtZero: true,
                callback(value) {
                  return `${value}`;
                },
              },
            },
          ],
        },
      },
      filterData: {
        startDate: '',
        endDate: '',
      },
    };
  },
  watch: {
    dateRange: {
      handler(val) {
        if (val === 'last_30_days') {
          this.defaultFromTo();
        }
      },
    },
    filterData: {
      handler(val) {
        if (val) {
          this.$apollo.queries.cdn_statistics.refetch();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultFromTo();
  },
  methods: {
    defaultFromTo() {
      const d = new Date();
      this.filterData = {
        ...this.filterData,
        startDate: this.$moment(d).subtract(29, 'days').format('YYYY-MM-DD'),
        endDate: this.$moment(d).format('YYYY-MM-DD'),
      };
      this.$apollo.queries.cdn_statistics.refetch();
    },
  },
};
</script>

<style scoped>
.chart {
  background: var(--cs-gray-01);
  border: 1px solid var(--cs-gray-02);
  margin-top: 10px;
}
.cdn-spent-graph__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cdn-spent-graph__header-title {
  font-size: 22px;
  font-weight: 600;
}
.cdn-spent-graph__header-select-label {
  margin-right: 10px;
}
.cdn-spent-graph__date-inputs {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
