<template>
  <div class="ecs-image-insights-detail__pan-zoom">
    <div class="ecs-image-insights-detail__pan-zoom-image">
      <panZoom
        v-show="imgVisible"
        ref="panZoom"
        :options="{ minZoom: 1, maxZoom: 3 }"
        @transform="onTransform"
      >
        <img :src="src" />
        <div
          v-for="(object, index) in objects"
          v-show="showObjects"
          :key="index"
          :style="{
            left: object.bbox[0] * currentScale + transformX + 'px',
            top: object.bbox[1] * currentScale + transformY + 'px',
            width: object.bbox[2] * currentScale + 'px',
            height: object.bbox[3] * currentScale + 'px',
            border: `2px solid ${object.color}`,
            position: 'absolute',
          }"
          class="ecs-image-insights-detail__pan-zoom-image__object"
        >
          <cs-tag>{{ object.class }} </cs-tag>
        </div>
      </panZoom>
      <div v-show="!imgVisible" class="blur-container">
        <img :src="src" class="blur" />
      </div>
      <div v-show="!imgVisible" class="action">
        <i class="cs-icons-eye-hide-filled"></i>
        <cs-button @click="onViewImage">View Image</cs-button>
      </div>
    </div>

    <div class="ecs-image-insights-detail__pan-zoom-buttons">
      <cs-button
        size="small"
        fill="clear"
        variant="secondary"
        :disabled="currentScale === 1"
        @click="onZoomOut"
        ><i class="cs-icons-minus"></i
      ></cs-button>
      <cs-button
        size="small"
        fill="clear"
        variant="secondary"
        @click="onZoomReset"
        ><i class="cs-icons-search"></i
      ></cs-button>
      <cs-button
        size="small"
        fill="clear"
        variant="secondary"
        :disabled="currentScale === 3"
        @click="onZoomIn"
        ><i class="cs-icons-plus"></i
      ></cs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    objects: {
      type: Array,
      required: true,
      default: () => [],
    },
    showObjects: {
      type: Boolean,
      required: true,
      default: false,
    },
    showImg: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      currentScale: 1,
      transformX: 0,
      transformY: 0,
      imgVisible: this.showImg,
    };
  },
  mounted() {
    this.onZoomReset();
  },
  methods: {
    onTransform() {
      const transform = this.getTransform();
      this.currentScale = transform.scale;
      this.transformX = transform.x;
      this.transformY = transform.y;
    },
    onZoomOut() {
      this.$refs.panZoom.$panZoomInstance.smoothZoomAbs(
        this.transformX,
        this.transformY,
        this.currentScale - 0.5
      );
    },
    onZoomReset() {
      this.currentScale = 1;
      this.$refs.panZoom.$panZoomInstance.moveTo(0, 0);
      this.$refs.panZoom.$panZoomInstance.zoomAbs(0, 0, 1);
    },
    onZoomIn() {
      this.$refs.panZoom.$panZoomInstance.smoothZoomAbs(
        this.transformX,
        this.transformY,
        this.currentScale + 0.5
      );
    },
    getTransform() {
      return this.$refs.panZoom.$panZoomInstance.getTransform();
    },
    onViewImage() {
      this.imgVisible = true;
    },
  },
};
</script>

<style scoped>
.ecs-image-insights-detail__pan-zoom {
  display: flex;
  flex-direction: column;
  gap: 8.5px;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  padding: 20px;
}
.ecs-image-insights-detail__pan-zoom-image {
  border: 1px solid var(--cs-gray-02);
  object-fit: contain;
  overflow: hidden;
  position: relative;
  min-height: 100px;
  max-height: 400px;
}
.ecs-image-insights-detail__pan-zoom-buttons {
  display: flex;
  justify-content: center;
  background-color: var(--cs-gray-02);
  padding: 10px 0px;
}
.blur-container {
  min-height: 100px;
  position: relative;
}
.blur {
  filter: blur(1.5rem);
}
.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action i {
  font-size: 2rem;
  color: var(--cs-gray-00);
}
.ecs-image-insights-detail__pan-zoom-image__object__content > span {
  background: var(--cs-gray-06);
  color: var(--cs-gray-00);
}
</style>
