<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-caption" @close="onClose">
      <div slot="header">{{ title }}</div>
      <div slot="body" class="ecs-caption__body">
        <app-add-progress-bar :step="step" :total-step="2" />
        <app-caption-first
          v-if="step === 1"
          @next-step="onGoToStep($event, 2)"
        ></app-caption-first>
        <app-caption-final
          v-if="step === 2"
          :data="modalData"
          @close-modal="updateList"
        ></app-caption-final>
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';
import AppCaptionFirst from '@/components/caption/CaptionSourceModal.vue';
import AppCaptionFinal from '@/components/caption/CaptionDestinationModal.vue';

export default {
  components: {
    AppAddProgressBar,
    AppCaptionFirst,
    AppCaptionFinal,
  },
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      modalData: {},
    };
  },
  computed: {
    title() {
      if (this.step === 1) {
        return 'Create Caption Job';
      }
      return 'Choose Destination';
    },
  },
  methods: {
    onClose() {
      this.step = 1;
      this.$emit('close-new-job-modal');
    },
    onGoToStep(e, step) {
      this.modalData = { ...this.modalData, ...e };
      this.step = step;
    },
    updateList() {
      this.$emit('update-caption-jobs-history');
      this.onClose();
    },
  },
};
</script>

<style scoped></style>
