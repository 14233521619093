<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Billing / <span>Payment Methods</span>
      </div>
      <cs-button
        v-if="customer && customer.payment_methods.length"
        @click="showAddCardModal"
      >
        Add Card
      </cs-button>
    </div>

    <div v-if="$apollo.queries.customer.loading" class="ecs-spinner__wrapper">
      <cs-spinner class="ecs-spinner" />
    </div>
    <div v-if="customer">
      <app-card-list
        v-if="customer.payment_methods.length"
        :customer="customer"
        @fetch-cards="refetchCard"
      />
      <app-empty-state
        v-if="!customer.payment_methods.length"
        description="You don't have payment method yet"
        button-text="Add Card"
        :icon="cardIcon"
        @add-action="showAddCardModal"
      />
    </div>
    <div v-if="adminAccess">
      <app-empty-state
        description="Admin access required!"
        :show-button="false"
      />
    </div>

    <app-add-card-modal
      :show-add-card="showAddCard"
      @close="hideAddCardModal"
      @fetch-cards="refetchCard"
    />
  </div>
</template>

<script>
import AppCardList from '@/components/paymentMethods/CardList.vue';
import AppAddCardModal from '@/components/paymentMethods/AddCardModal.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
// GQL
import FetchCustomer from '@/gql/paymentMethods/FetchCustomer.gql';

const cardIcon = require('@/assets/images/card.svg');

export default {
  apollo: {
    customer: {
      query: FetchCustomer,
      variables() {
        return {
          projectId: this.$route.params.id,
        };
      },
      error(e) {
        this.accessDenied = JSON.stringify(e.message);
      },
      fetchPolicy: 'network-only',
    },
  },
  components: {
    AppCardList,
    AppEmptyState,
    AppAddCardModal,
  },
  data() {
    return {
      showAddCard: false,
      cardIcon,
      accessDenied: null,
    };
  },
  computed: {
    adminAccess() {
      const access =
        this.accessDenied && this.accessDenied.toLowerCase().includes('access');

      return access;
    },
  },
  methods: {
    showAddCardModal() {
      this.showAddCard = true;
    },
    hideAddCardModal() {
      this.showAddCard = false;
    },
    async refetchCard() {
      await this.$apollo.queries.customer.refetch();
    },
  },
};
</script>

<style scoped>
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
</style>
