<template>
  <div>
    <cs-modal :show="showDeleteMediaLibrary" @close="onClose">
      <div slot="header">Remove Library</div>
      <div slot="body" class="ecs-delete-library-modal__body">
        <div
          class="ecs-delete-library-modal__body-header cs-textstyle-paragraph-bold"
        >
          Are you sure you want to remove {{ mediaLibrary.name }}?
        </div>
        <div
          class="ecs-delete-library-modal__body-subheader cs-textstyle-paragraph-small"
        >
          To confirm, type the name of the library you wish to delete. This
          change can’t be reversed.
        </div>
        <div class="ecs-delete-library-modal__prompt">
          <cs-input
            v-model="libraryName"
            label="Library Name"
            :placeholder="'Type here: ' + mediaLibrary.name"
            required
            class="ecs-delete-library-modal__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        :disabled="!valid"
        variant="danger"
        @click="deleteMediaLibrary"
        >Remove Library</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import DeleteMediaLibrary from '@/gql/medialibrary/DeleteMediaLibrary.gql';

export default {
  props: {
    showDeleteMediaLibrary: {
      type: Boolean,
      required: true,
    },
    mediaLibrary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      libraryName: '',
    };
  },
  computed: {
    valid() {
      return this.libraryName === this.mediaLibrary.name;
    },
  },
  methods: {
    reset() {
      this.libraryName = '';
    },
    onClose() {
      this.reset();
      this.$emit('close-delete-media-library');
    },
    async deleteMediaLibrary() {
      if (this.libraryName === this.mediaLibrary.name) {
        await this.$apollo.mutate({
          mutation: DeleteMediaLibrary,
          variables: {
            deleteMediaLibraryId: this.mediaLibrary.id,
          },
        });
        this.reset();
        this.$emit('close-delete-media-library', true);
      } else {
        this.reset();
      }
    },
  },
};
</script>

<style scoped>
.ecs-delete-library-modal__body {
  width: 100%;
}
.ecs-delete-library-modal__body-header {
  margin-top: 10px;
  margin-bottom: 5px;
  color: var(--cs-gray-07);
}
.ecs-delete-library-modal__body-subheader {
  color: var(--cs-gray-0);
  font-size: 14px;
}
.ecs-delete-library-modal__prompt {
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-delete-library-modal__wide-input {
  width: 100%;
}
.ecs-delete-library-modal__prompt >>> .cs-input__label-text {
  font-weight: 600;
}
</style>
