<template>
  <div>
    <cs-modal :show="showDeleteProjectModal" @close="onClose">
      <div slot="header">Delete Project</div>
      <div slot="body" class="ecs-project">
        <div class="ecs-project__header">
          Are you sure you want to delete {{ project.name }}?
        </div>
        <div class="ecs-project__sub-header">
          Please enter the name of the project you wish to delete. The change
          can't be reversed
        </div>

        <div class="ecs-project__prompt">
          <cs-input
            v-model="name"
            label="Name of the project"
            placeholder="Enter Here"
            required
            class="ecs-project__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="danger"
        :disabled="!valid"
        @click="deleteProject"
        >Confirm Delete</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import DeleteProject from '@/gql/project/DeleteProject.gql';
import $bus from '@/services/bus';

export default {
  props: {
    showDeleteProjectModal: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  computed: {
    valid() {
      return this.name === this.project.name;
    },
  },
  methods: {
    onClose() {
      this.name = '';
      this.$emit('close-delete-project-modal');
    },
    async deleteProject() {
      try {
        await this.$apollo.mutate({
          mutation: DeleteProject,
          variables: {
            projectId: this.project.id,
          },
        });
        this.onClose();
        $bus.$emit('refresh-projects', this.project);
      } catch (error) {
        this.$toast.open({
          message:
            'Please note - Project with any assets (e.g. an attached static site, cdn, etc) can’t be deleted',
          type: 'error',
          position: 'top',
          duration: 4000,
          dismissable: true,
          onDismiss: this.onClose,
          pauseOnHover: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.ecs-project__header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-project__sub-header {
  color: var(--cs-gray-04);
  margin: 5px;
  font-size: 14px;
}
.ecs-project__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-project__wide-input {
  width: 100%;
}
</style>
