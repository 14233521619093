<template>
  <cs-card class="ecs-card">
    <div slot="body" class="ecs-payment">
      <div class="cs-textstyle-section-heading ecs-payment__title">
        <div>Upcoming Payment</div>
        <cs-button variant="secondary" disabled>Pay Now</cs-button>
      </div>
      <div class="ecs-payment__content">
        <div class="cs-textstyle-paragraph-small-bold">Next Payment On:</div>
        <div class="cs-textstyle-paragraph-small">
          {{ dueDate }}
        </div>
      </div>
      <div class="ecs-payment__content">
        <div class="cs-textstyle-paragraph-small-bold">Charge Till Date:</div>
        <div class="cs-textstyle-paragraph-small">
          ${{ upcomingPayment.total_usd }} until {{ todaysDate }}
        </div>
      </div>
      <div class="ecs-payment__content">
        <div class="cs-textstyle-paragraph-small-bold">Status:</div>
        <div class="cs-textstyle-paragraph-small">
          <cs-tag class="ecs-tag__status">{{ upcomingPayment.status }}</cs-tag>
        </div>
      </div>
    </div>
  </cs-card>
</template>

<script>
import { formatDate } from '@/services/utils';

export default {
  props: {
    upcomingPayment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dueDate() {
      return formatDate(this.upcomingPayment.due_date);
    },
    todaysDate() {
      return formatDate(new Date());
    },
  },
};
</script>

<style scoped>
.ecs-card {
  margin-bottom: 50px !important;
  background-color: var(--cs-gray-01) !important;
}
.ecs-payment {
  display: flex;
  flex-direction: column;
}
.ecs-payment__title {
  font-weight: 500;
  color: var(--cs-gray-07);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.ecs-payment__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  color: var(--cs-gray-07);
}
.ecs-tag__status {
  --cs-tag-color: var(--cs-primary-base) !important;
}
</style>
