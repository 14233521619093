<template>
  <b-row class="ecs-login">
    <b-col cols="4">
      <div class="ecs-login__left">
        <div class="ecs-login__left--top">
          <div class="ecs-login__left--top__text">
            <span>Efficient</span>
            <span class="ecs-login__left--top__text--small"> Cloud </span>
          </div>
          <div class="ecs-login__left--top__heading">
            <div>Affordable services</div>
            <div>on every cloud</div>
          </div>
        </div>
        <img :src="vector" class="ecs-login__vector" />
      </div>
    </b-col>
    <b-col cols="8" class="ecs-login__right">
      <div class="ecs-login__right--body">
        <cs-card class="ecs-login__right--card">
          <div slot="header" class="ecs-login__right--title">
            <div>Sign In</div>
            <div class="cs-textstyle-paragraph ecs-login__info">
              Start building in minutes.
            </div>
          </div>
          <div slot="body" class="ecs-login__right--buttons">
            <cs-login-button
              v-for="provider in providers"
              :key="provider"
              :network="provider"
              class="ecs-login__button"
              @click="login(provider)"
            >
            </cs-login-button>
          </div>
        </cs-card>
        <div class="ecs-login__right--footer">
          <div class="ecs-login__info">
            By signing in you agree to the
            <a target="_blank" href="/legal/terms.html">Terms of Service</a>
            <span> and </span>
            <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
          </div>
        </div>
      </div>
      <div class="ecs-login__footer">
        <div class="mx-2">
          <a href="mailto:hello@efficientcloud.com">Contact Us</a>
        </div>
        <div class="mx-2">
          <a target="_blank" :href="docsUrl">Help</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
const vector = require('@/assets/images/login-vector.svg');
export default {
  data() {
    return {
      vector,
      providers: ['google', 'microsoft', 'linkedin', 'twitter'],
    };
  },
  computed: {
    docsUrl() {
      return process.env.VUE_APP_DOCS;
    },
  },
  mounted() {
    if (this.$route.hash) {
      const token = this.$route.hash.split('#access_token=')[1];
      if (token) {
        localStorage.access_token = token;
        this.$router.push('/');
        window.location.reload();
      }
    }
  },
  methods: {
    async login(provider) {
      const providerId = provider.toUpperCase();
      window.location = `${process.env.VUE_APP_REST_ENDPOINT}/login?provider=${providerId}&callback=${process.env.VUE_APP_AUTH_CALLBACK}`;
    },
  },
};
</script>

<style scoped>
.ecs-login {
  display: flex;
  flex-direction: row;
  background-color: var(--cs-gray-02);
  height: 100vh;
}
.ecs-login__left {
  display: flex;
  flex-direction: column;
  background: #0e0848;
  height: 100vh;
  justify-content: space-between;
}
.ecs-login__vector {
  width: 100%;
  display: flex;
  object-fit: cover;
  justify-self: flex-end;
}
.ecs-login__left--top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30%;
}
.ecs-login__left--top__text {
  color: var(--cs-primary-base);
  font-size: 30px;
  font-weight: 600;
}
.ecs-login__left--top__text--small {
  font-weight: 100;
}
.ecs-login__left--top__heading {
  text-align: center;
  color: var(--cs-gray-00);
  font-size: 30px;
  font-weight: 400;
  margin-top: 20px;
}
.ecs-login__right--card {
  display: flex;
  width: 450px;
  height: 450px;
  padding: 10px;
}
.ecs-login__info {
  color: var(--cs-gray-05);
  font-size: 16px;
  margin-top: 5px;
}
.ecs-login__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--cs-accent-dark);
  height: 150px;
}
.ecs-login__right--title {
  padding: 10px 20px;
  text-align: center;
}
.ecs-login__right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}
.ecs-login__right--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.ecs-login__right--buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 20px;
}
.ecs-login__button {
  --cs-button-padding: 13px !important;
}
.ecs-login__right--footer {
  padding: 10px 20px;
  width: 350px;
  text-align: center;
}
.ecs-login__right--footer a {
  color: var(--cs-primary-base);
}
.ecs-login__footer {
  border-top: 1px solid var(--cs-gray-03);
  display: flex;
  padding: 30px;
  justify-self: flex-end;
  justify-content: center;
  flex-direction: row;
  color: var(--cs-gray-04);
  gap: 30px;
}
.row > * {
  padding: 0px;
}
</style>
