<template>
  <cs-modal :show="showEditModal" @close="onClose">
    <div slot="header">
      Edit Card ending with {{ paymentMethod.card.last4 }}
    </div>
    <div slot="body" class="ecs-payment__modal">
      <div class="card-info"></div>
      <div class="ecs-group-input">
        <cs-input v-model="expMonth" label="Expiry Month" />
        <cs-input v-model="expYear" label="Expiry Year" />
      </div>
    </div>
    <cs-button slot="main" :disabled="isDisabled" @click="updateCard">
      Update Card
    </cs-button>
  </cs-modal>
</template>

<script>
import UpdateCard from '@/gql/paymentMethods/UpdateCard.gql';

export default {
  props: {
    showEditModal: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expMonth: this.paymentMethod.card.exp_month,
      expYear: this.paymentMethod.card.exp_year,
      isSaving: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isSaving || !this.expMonth || !this.expYear;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setDefaultValue() {
      this.expMonth = '';
      this.expYear = '';
      this.isSaving = false;
    },
    async updateCard() {
      this.isSaving = true;
      await this.$apollo.mutate({
        mutation: UpdateCard,
        variables: {
          projectId: this.$route.params.id,
          paymentMethodsId: this.paymentMethod.id,
          expMonth: Number(this.expMonth),
          expYear: Number(this.expYear),
        },
      });
      // this.setDefaultValue()
      this.$emit('close');
      this.$emit('fetch-cards');
    },
  },
};
</script>

<style scoped>
.ecs-payment__modal--inputs {
  width: 100%;
}
.ecs-group-input {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  align-items: center;
}
.ecs-payment__modal {
  margin-top: 16px;
}
</style>
