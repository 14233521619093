import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import apolloClient from '@/services/apollo';
import VueApollo from 'vue-apollo';
import VueToast from 'vue-toast-notification';
import panZoom from 'vue-panzoom';
import VueCountryCode from 'vue-country-code';
import router from './router';

import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'cs-components';
import 'cs-components/dist/csuite.css';
import '@/assets/css/design-token.css';
import '@/assets/css/style.css';
import '@/assets/icons/css/styles.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
Vue.use(VueMoment);
Vue.use(VueToast);
Vue.use(panZoom);
Vue.use(VueCountryCode);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.directive('router-link', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      vnode.context.$router.push(binding.value);
    });
  },
});

Vue.filter('formatTag', (value) => value.split('-').join(' '));

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
