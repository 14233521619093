<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div v-if="asset" class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Media Library / {{ asset.mediaLibrary.name }} /
        <span>{{ asset.name }} </span>
      </div>

      <div class="ecs-dashboard__header-actions">
        <cs-button
          variant="primary"
          fill="outline"
          :disabled="disable"
          @click="showEditAssetModal"
          >Edit</cs-button
        >
        <cs-button
          variant="primary"
          fill="solid"
          :disabled="disable"
          @click="showDeleteAssetModal"
          >Remove</cs-button
        >
      </div>
    </div>
    <!-- Info -->
    <div v-if="asset" class="ecs-media-library-detail__info">
      <div class="ecs-media-library-detail__info__content">
        <!-- File Name -->
        <div class="ecs-media-library-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">File Name</div>

          <div class="cs-textstyle-paragraph-small">
            {{ asset.name }}
          </div>
        </div>
        <!-- Features -->
        <div class="ecs-media-library-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Features</div>
          <div
            class="cs-textstyle-paragraph-small ecs-media-library-detail__features"
          >
            <cs-tag v-for="feature in features" :key="feature">{{
              feature | formatTag
            }}</cs-tag>
          </div>
        </div>
        <!-- Status -->
        <div class="ecs-media-library-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Status</div>
          <div
            v-if="asset.mediaLibrary.status"
            class="cs-textstyle-paragraph-small"
          >
            <cs-tag :class="['ecs-tag__' + asset.mediaLibrary.status]">{{
              asset.status
            }}</cs-tag>
          </div>
        </div>
        <!-- Total Size -->
        <div
          v-if="asset.original_size"
          class="ecs-media-library-detail__info-content"
        >
          <div class="cs-textstyle-paragraph-small-bold">Total Size</div>
          <div class="cs-textstyle-paragraph-small">
            {{ formatBytes(asset.original_size) }}
          </div>
        </div>
        <!-- Tags -->
        <div class="ecs-media-library-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Tags</div>
          <div
            v-if="asset.tags"
            class="cs-textstyle-paragraph-small ecs-media-library-detail__tags"
          >
            <div
              v-for="tag in asset.tags"
              :key="tag.key"
              class="ecs-media-library-detail__tags--tag"
            >
              <cs-tag>{{ tag.key | formatTag }}</cs-tag>
              <cs-tag
                :style="{
                  '--cs-tag-color': '#87939B',
                  '--cs-tag-texcolor': '#ffffff',
                }"
                >{{ tag.value | formatTag }}</cs-tag
              >
            </div>
          </div>
        </div>
        <!-- Date Upload -->
        <div class="ecs-media-library-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Date Upload</div>
          <div class="cs-textstyle-paragraph-small">
            {{ asset.created_at | moment('DD MMM YYYY, hh:mm A') }}
            (UTC)
          </div>
        </div>
      </div>
      <app-file-info
        v-if="asset.source_url"
        :heading-text="'Input File'"
        :file-label="'Source'"
        :file="asset.source_url"
        :size="asset.original_size"
      ></app-file-info>
    </div>

    <app-image-insight-job
      v-if="asset && fileType === 'image'"
      :file-data="fileData"
      :source-url="source_url"
    >
    </app-image-insight-job>
    <app-edit-media-library-asset-modal
      v-if="asset"
      :show-update-media-library-asset="showUpdateMediaLibraryAsset"
      :asset="asset"
      @close-update-media-library-asset="closeUpdateMediaLibraryAssetModal"
    />

    <app-delete-asset
      v-if="asset"
      :item="asset"
      :show-delete="showDelete"
      @close-delete-asset-modal="closeDeleteAssetModal"
      @deleted-asset="refreshData"
    />
  </div>
</template>

<script>
import { getAccessLevel, formatBytes } from '@/services/utils';
import $bus from '@/services/bus';
import GetMediaLibraryAsset from '@/gql/medialibrary/GetMediaLibraryAsset.gql';
import GetMediaLibraryAssetUrl from '@/gql/medialibrary/GetMediaLibraryAssetUrl.gql';
import AppFileInfo from '@/components/imageinsight/FileInfo.vue';
import AppImageInsightJob from '@/components/medialibrary/ImageInsightJob.vue';
import AppEditMediaLibraryAssetModal from '@/components/medialibrary/EditMediaLibraryAssetModal.vue';
import AppDeleteAsset from '@/components/medialibrary/DeleteMediaLibraryAsset.vue';

export default {
  components: {
    AppFileInfo,
    AppImageInsightJob,
    AppEditMediaLibraryAssetModal,
    AppDeleteAsset,
  },
  apollo: {
    asset: {
      query: GetMediaLibraryAsset,
      variables() {
        return {
          assetId: this.$route.params.assetid,
        };
      },
    },
    source_url: {
      query: GetMediaLibraryAssetUrl,
      variables() {
        return {
          assetId: this.$route.params.assetid,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      formatBytes,
      showUpdateMediaLibraryAsset: false,
      showDelete: false,
    };
  },
  computed: {
    fileType() {
      if (this.asset && this.asset.content_type) {
        return this.asset.content_type.split('/')[0];
      }
      return 'image';
    },
    features() {
      return this.asset.mediaLibrary[`${this.fileType}_features`];
    },
    fileData() {
      if (this.fileType) {
        const type = this.getType(this.fileType);
        return this.asset[type.job];
      }
      return null;
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    getType(contentType) {
      const typeData = [
        { type: 'image', job: 'imageinsight_job' },
        { type: 'audio', job: 'transcoding_job' },
      ];
      return typeData.find((a) => a.type.includes(contentType));
    },
    showEditAssetModal() {
      this.showUpdateMediaLibraryAsset = true;
    },
    closeUpdateMediaLibraryAssetModal(val) {
      if (val) {
        this.$apollo.queries.asset.refetch();
      }
      this.showUpdateMediaLibraryAsset = false;
    },
    showDeleteAssetModal() {
      this.showDelete = true;
    },
    closeDeleteAssetModal() {
      this.showDelete = false;
    },
    refreshData() {
      this.$apollo.queries.asset.refetch();
    },
  },
};
</script>

<style scoped>
.ecs-media-library-detail__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 45px;
  margin-top: 40px;
  padding: 20px;
}
.ecs-media-library-detail__info__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-media-library-detail__info__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}
.ecs-media-library-detail__info__top div {
  font-weight: 500;
}
.ecs-media-library-detail__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
}
.ecs-media-library-detail__features {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.ecs-media-library-detail__tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ecs-media-library-detail__tags--tag {
  display: flex;
  gap: 1px;
}

.ecs-media-library-detail__tags--tag >>> .cs-tag:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ecs-media-library-detail__tags--tag >>> .cs-tag:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.ecs-media-library-detail__info-content i.cs-icons-copy {
  color: var(--cs-primary-base);
  font-size: medium;
  cursor: pointer;
}
</style>
