<template>
  <div>
    <cs-button variant="primary" :disabled="disable" @click="showAddSiteModal">
      Add New Site
    </cs-button>
    <!-- Add Site Modal  -->
    <app-add-site-modal
      :show-add="showAdd"
      :step="step"
      @close-add-site-modal="closeAdd"
      @update-list="$emit('update-list')"
    />
  </div>
</template>

<script>
import AppAddSiteModal from '@/components/staticsite/AddSiteModal.vue';

export default {
  components: {
    AppAddSiteModal,
  },
  props: {
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAdd: false,
    };
  },
  computed: {
    step() {
      return this.$route.query.add_step
        ? parseInt(this.$route.query.add_step, 10)
        : 1;
    },
  },
  mounted() {
    if (this.$route.query.add_step) this.showAdd = true;
  },
  methods: {
    showAddSiteModal() {
      this.showAdd = true;
    },
    closeAdd() {
      this.step = 1;
      this.$router.push({
        name: 'StaticSiteList',
      });
      this.showAdd = false;
    },
  },
};
</script>
