<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Billing / <span>Cost Explorer</span>
      </div>
    </div>
    <!-- Cost(s) and Filters  -->
    <b-row class="ecs-cost-explorer__chart-filter-container">
      <!-- Cost(s) -->
      <b-col cols="9">
        <div class="ecs-cost-explorer__info">
          <div class="ecs-cost-explorer__info__top">
            <div class="cs-textstyle-section-heading">Costs($)</div>
            <div>
              <div class="ecs-cost-explorer__chart-icons">
                <i
                  v-for="icon in chartIcons"
                  :key="icon.id"
                  :class="[
                    icon.class,
                    { 'ecs-cost-explorer__chart-icons--active': icon.active },
                  ]"
                  @click="toggleChart(icon.id)"
                ></i>
              </div>
            </div>
          </div>
          <div class="ecs-cost-explorer__info__content">
            <stacked-bar-chart
              v-if="activeChart === 'stacked'"
              :chart-data="chartData"
            ></stacked-bar-chart>
            <bar-chart
              v-if="activeChart === 'bar'"
              :chart-data="chartData"
            ></bar-chart>
            <line-chart
              v-if="activeChart === 'line'"
              :chart-data="chartData"
              :options="options"
            ></line-chart>
          </div>
        </div>
      </b-col>
      <!-- Filters -->
      <b-col
        ><div class="ecs-cost-explorer__info">
          <div class="ecs-cost-explorer__info__top">
            <div class="cs-textstyle-section-heading">Filters</div>
          </div>
          <div v-if="filterData" class="ecs-cost-explorer__info__content">
            <cs-form v-model="formValid" class="ecs-cost-explorer__filter-form">
              <cs-select
                v-model="filterData.serviceId"
                :options="serviceOptions"
                label="Service"
              ></cs-select>
              <cs-select
                v-model="dateRange"
                :options="dateRangeOptions"
                label="Date Range"
              ></cs-select>
              <cs-date-input
                v-model="filterData.startDate"
                label="From"
                :disabled="dateRange === 'last_7_days'"
              ></cs-date-input>
              <cs-date-input
                v-model="filterData.endDate"
                label="To"
                :disabled="dateRange === 'last_7_days'"
              ></cs-date-input>
              <cs-select
                v-model="filterData.interval"
                :options="intervalOptions"
                label="Group By"
              ></cs-select>
            </cs-form>
          </div></div
      ></b-col>
    </b-row>
    <!-- Empty State -->
    <div
      v-if="list_project_charges && !list_project_charges.length"
      class="ecs-key__empty-state"
    >
      <app-empty-state
        :icon="graphIcon"
        :show-button="false"
        description="Please change the filter criteria"
      />
    </div>
    <!-- Table -->
    <div
      v-if="list_project_charges && list_project_charges.length"
      class="ecs-dashboard-list__table"
    >
      <b-table
        :items="listProjectCharges"
        :fields="fields"
        responsive
        borderless
        hover
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.list_project_charges.loading"
      >
        <template #table-busy>
          <div class="ecs-dashboard-list__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <template v-slot:cell(service)="data">
          <span>{{ data.item.service }} ($)</span>
        </template>
        <template #cell()="data">
          <span>{{ data.value ? `$${data.value}` : '' }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import ListProjectCharges from '@/gql/billing/ListProjectCharges.gql';
import {
  serviceOptions,
  intervalOptions,
  dateRangeOptions,
  getDynamicHeadings,
  groupByFormats,
  getDataSet,
  getListProjectChargesTransform,
  chartIcons,
  getTotalRow,
} from '@/services/utils';
import StackedBarChart from '@/components/charts/StackedBarChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
const graphIcon = require('@/assets/images/graph.svg');

export default {
  components: {
    StackedBarChart,
    BarChart,
    LineChart,
    AppEmptyState,
  },
  data() {
    return {
      date: new Date(),
      formValid: false,
      projectId: this.$route.params.id,
      serviceOptions,
      dateRange: 'last_7_days',
      dateRangeOptions,
      intervalOptions,
      filterData: {
        serviceId: '',
        startDate: '',
        endDate: '',
        interval: 'day',
      },
      fields: [],
      listProjectCharges: [],
      chartData: {},
      activeChart: 'stacked',
      chartIcons,
      graphIcon,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value) => `$${value}`,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    dateRange: {
      handler(val) {
        if (val === 'last_7_days') {
          this.defaultFromTo();
        }
      },
    },
    filterData: {
      handler(val) {
        if (val) {
          this.$apollo.queries.list_project_charges.refetch();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultFromTo();
  },
  apollo: {
    list_project_charges: {
      query: ListProjectCharges,
      variables() {
        const serviceId =
          this.filterData.serviceId !== ''
            ? { serviceId: this.filterData.serviceId }
            : {};
        return {
          projectId: this.projectId,
          endDate: this.filterData.endDate,
          startDate: this.filterData.startDate,
          interval: this.filterData.interval ? this.filterData.interval : 'day',
          ...serviceId,
        };
      },
      result({ data }) {
        const { list_project_charges: listProjectCharges } = data;
        const { startDate, endDate, interval: groupBy } = this.filterData;
        const dynamicHeadings = getDynamicHeadings(startDate, endDate, groupBy);
        this.fields = [
          {
            key: 'service',
            label: 'Service',
            thClass: 'ecs-dashboard__table-head',
          },
          ...dynamicHeadings.map((date) => ({
            key: this.$moment(date).format(groupByFormats[groupBy]),
            label: this.$moment(date).format(groupByFormats[groupBy]),
            thClass: 'ecs-dashboard__table-head',
          })),
        ];
        const listProjectChargesTransform = getListProjectChargesTransform(
          listProjectCharges,
          dynamicHeadings,
          groupBy
        );
        this.listProjectCharges = [
          getTotalRow(listProjectChargesTransform),
          ...listProjectChargesTransform,
        ];
        this.chartData = {
          labels: [
            ...dynamicHeadings.map((date) =>
              this.$moment(date).format(groupByFormats[groupBy])
            ),
          ],
          datasets: getDataSet(listProjectChargesTransform),
        };
      },
      skip() {
        return (
          !this.filterData.startDate ||
          !this.filterData.endDate ||
          !this.filterData.interval ||
          !this.dateRange
        );
      },
    },
  },
  methods: {
    defaultFromTo() {
      const d = new Date();
      this.filterData = {
        ...this.filterData,
        startDate: this.$moment(d).subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: this.$moment(d).format('YYYY-MM-DD'),
      };
      this.$apollo.queries.list_project_charges.refetch();
    },
    toggleChart(id) {
      this.activeChart = id;
      this.chartIcons.forEach((item) => {
        item.active = item.id === id;
      });
    },
  },
};
</script>

<style scoped>
.ecs-cost-explorer__chart-filter-container {
  margin-top: 30px;
}
.ecs-cost-explorer__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 20px;
}
.ecs-cost-explorer__info__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-cost-explorer__chart-icons {
  display: flex;
  gap: 20px;
}
.ecs-cost-explorer__chart-icons i {
  height: 22px;
  width: 22px;
  color: var(--cs-gray-04);
  cursor: pointer;
}
.ecs-cost-explorer__info__content .ecs-cost-explorer__filter-form {
  display: flex;
  flex-direction: column;
}
.ecs-cost-explorer__info__top div {
  font-weight: 500;
}
.ecs-cost-explorer__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 300px;
}
.ecs-cost-explorer__info-content i {
  color: var(--cs-gray-03);
  font-size: 15px;
}
.ecs-cost-explorer__chart-icons--active {
  color: #00c472 !important;
}
.ecs-dashboard-list__table >>> tr.ecs-dashboard__table-row:nth-child(1) {
  font-weight: bold !important;
}
</style>
