<template>
  <div class="ecs-sidebar">
    <div class="ecs-sidebar__title">
      <span class="ecs-sidebar__title-bold">Efficient</span>Cloud
    </div>
    <!-- Project -->
    <div
      class="ecs-sidebar__project-main"
      :class="{ projectActive: showProjectMenu }"
      @mouseenter="showProjectMenu = true"
      @mouseleave="showProjectMenu = false"
    >
      <div class="ecs-sidebar__project-title">PROJECTS</div>
      <div class="ecs-sidebar__project">
        <span @click="changeProjectRoute">{{
          lastActiveProject.name || 'Default Project'
        }}</span>
        <div @click="showProjects">
          <i class="cs-icons-play-filled"></i>
        </div>
      </div>
    </div>
    <div
      v-if="showProjectMenu && projects.length"
      class="ecs-sidebar__project--list"
      @mouseenter="showProjectMenu = true"
      @mouseleave="showProjectMenu = false"
    >
      <div
        v-for="(project, index) in projects"
        :key="index"
        @click="setNewActiveProject(project)"
      >
        <div
          class="ecs-sidebar__project--list-items"
          :class="{
            activeProjectItem: project.project.id == lastActiveProject.id,
          }"
        >
          <div class="ecs-sidebar__project-sub-items">
            {{ project.project.name }}
          </div>
          <i
            v-if="project.project.id == lastActiveProject.id"
            class="cs-icons-check"
          ></i>
        </div>
      </div>
      <div class="ecs-sidebar__project-new-button" @click="createNewProject">
        <i class="cs-icons-add mr-2"></i>New Project
      </div>
    </div>

    <!-- Services -->
    <ul>
      <li v-for="(link, index) in navLinks" :key="index">
        <router-link
          :to="serviceRoute(link)"
          :class="isActiveRoute(link) ? 'ecs-sidebar__active-items' : ''"
          class="ecs-sidebar__none-active-items"
          @click="toggleNav(index)"
        >
          <span class="ecs-sidebar__items">
            <i :class="link.icon" />
            {{ link.text }}
          </span>
        </router-link>
        <!-- Services sublinks -->
        <div
          v-if="
            link.sublinks && link.sublinks.length > 0 && isActiveRoute(link)
          "
          class="ecs-sidebar__sublinks"
        >
          <li
            v-for="(lnk, idx) in link.sublinks"
            :key="idx"
            @click="setActive(idx)"
          >
            <router-link
              v-if="lastActiveProject"
              :to="lnk.path"
              :class="
                $route.name === lnk.name ? 'ecs-sidebar__sub-active-items' : ''
              "
              class="ecs-sidebar__none-active-items"
            >
              <span class="ecs-sidebar__sub-items">
                {{ lnk.text }}
              </span>
            </router-link>
          </li>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
    lastActiveProject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      navLinks: [
        {
          text: 'Dashboard',
          path: '',
          name: 'ProjectDashboard',
          icon: 'cs-icons-home-filled',
        },
        {
          text: 'IAM',
          path: 'iam',
          icon: 'cs-icons-lock-filled',
          extra: 'users',
          sublinks: [
            {
              text: 'Project Users',
              path: 'users',
              icon: 'cs-icons-group-filled',
              extra: '',
              name: 'UserList',
            },
            {
              text: 'Project Keys',
              path: 'keys',
              icon: 'cs-icons-filter2-filled',
              extra: '',
              name: 'ProjectKeysList',
            },
          ],
        },
        {
          text: 'Billing',
          path: 'billing',
          name: 'Billing',
          icon: 'cs-icons-price-tag-filled',
          extra: 'paymentmethods',
          sublinks: [
            {
              text: 'Payment Methods',
              path: { name: 'PaymentMethods' },
              icon: 'cs-icons-database-backup-filled',
              name: 'PaymentMethods',
            },
            {
              text: 'Invoices',
              path: { name: 'InvoiceList' },
              icon: 'cs-icons-database-backup-filled',
              name: 'InvoiceList',
            },
            {
              text: 'Cost Explorer',
              path: { name: 'CostExplorer' },
              icon: 'cs-icons-database-backup-filled',
              name: 'CostExplorer',
            },
          ],
        },
        {
          text: 'Transcoding',
          path: 'transcoding',
          icon: 'cs-icons-remote-filled',
          extra: 'dashboard',
          name: 'Transcoding',
          sublinks: [
            {
              text: 'Dashboard',
              path: { name: 'TranscodingDashboard' },
              icon: 'cs-icons-database-backup-filled',
              name: 'TranscodingDashboard',
            },
            {
              text: 'All Jobs',
              path: { name: 'JobsList' },
              icon: 'cs-icons-database-backup-filled',
              name: 'JobsList',
            },
          ],
        },
        {
          text: 'Image Insights',
          path: 'imageinsight',
          icon: 'cs-icons-image-filled',
          extra: 'history',
          name: 'ImageInsight',
          sublinks: [
            {
              text: 'Jobs History',
              path: { name: 'ImageInsightHistory' },
              icon: 'cs-icons-database-backup-filled',
              name: 'ImageInsightHistory',
            },
          ],
        },
        {
          text: 'Video Insights',
          path: 'videoinsight',
          icon: 'cs-icons-image-filled',
          extra: 'history',
          name: 'VideoInsight',
          sublinks: [
            {
              text: 'Jobs History',
              path: { name: 'VideoInsightHistory' },
              icon: 'cs-icons-database-backup-filled',
              name: 'VideoInsightHistory',
            },
          ],
        },
        // {
        //   text: 'File Conversion',
        //   path: '',
        //   icon: 'cs-icons-application-file-filled',
        //   extra: '',
        // },
        // {
        //   text: 'Captions',
        //   path: '',
        //   icon: 'cs-icons-comments-filled',
        //   extra: '',
        // },
        // {
        //   text: 'Video Moderation',
        //   path: '',
        //   icon: 'cs-icons-video-filled',
        //   extra: '',
        // },
        // {
        //   text: 'Media Library',
        //   path: '',
        //   icon: 'cs-icons-library-filled',
        //   extra: '',
        // },
        {
          text: 'Media Library',
          path: 'medialibrary/dashboard',
          name: 'MediaLibraryDashboard',
          icon: 'cs-icons-library-filled',
        },
        {
          text: 'Static Site Hosting',
          path: 'staticsite',
          icon: 'cs-icons-channels-filled',
          extra: 'list',
          name: 'StaticSite',
          sublinks: [
            {
              text: 'All sites',
              path: { name: 'StaticSiteList' },
              icon: 'cs-icons-database-backup-filled',
              name: 'StaticSiteList',
            },
          ],
        },
        {
          text: 'File Conversion',
          path: 'fileconversion',
          icon: 'cs-icons-file-convert-filled',
          extra: 'dashboard',
          name: 'FileConversion',
          sublinks: [
            {
              text: 'Dashboard',
              path: { name: 'FileConversionDashboard' },
              icon: 'cs-icons-database-backup-filled',
              name: 'FileConversionDashboard',
            },
            {
              text: 'All Jobs',
              path: { name: 'FileConversionJobList' },
              icon: 'cs-icons-database-backup-filled',
              name: 'FileConversionJobList',
            },
          ],
        },
        {
          text: 'Captions',
          path: 'caption',
          icon: 'cs-icons-caption-filled',
          extra: 'dashboard',
          name: 'Caption',
          sublinks: [
            {
              text: 'Dashboard',
              path: { name: 'CaptionDashboard' },
              icon: 'cs-icons-database-backup-filled',
              name: 'CaptionDashboard',
            },
            {
              text: 'All Jobs',
              path: { name: 'CaptionJobList' },
              icon: 'cs-icons-database-backup-filled',
              name: 'CaptionJobList',
            },
          ],
        },
        // {
        //   text: 'CDN',
        //   path: '',
        //   icon: 'cs-icons-data-filled',
        // },
      ],
      show: null,
      bottomActive: null,
      showProjectMenu: false,
    };
  },
  watch: {
    $route() {
      if (this.$route.path === '/account') {
        this.showProjectMenu = false;
        this.bottomActive = null;
        this.active = null;
        this.show = null;
      }
    },
  },
  methods: {
    showProjects() {
      this.showProjectMenu = !this.showProjectMenu;
    },
    toggleNav(i) {
      this.showProjectMenu = false;
      this.bottomActive = null;
      this.show = i;
      this.setActive(0);
    },
    setActive() {
      this.showProjectMenu = false;
      this.bottomActive = null;
    },
    // Emits event for creating new project
    createNewProject() {
      this.$emit('open-project-modal');
    },
    // Emits event for changing the lastActiveProject
    setNewActiveProject(project) {
      this.bottomActive = null;
      this.show = null;
      this.showProjectMenu = !this.showProjectMenu;
      const activeProject = project.project;
      this.$emit('set-active-project', activeProject);
      this.$router.push({
        name: 'ProjectDashboard',
        params: { id: project.project.id },
      });
    },
    // Handle Active Link
    isActiveRoute(link) {
      if (!link.sublinks) return this.$route.name === link.name;
      let routeNameFound = false;
      link.sublinks.forEach((sublink) => {
        if (sublink.name === this.$route.name) {
          routeNameFound = true;
        }
      });
      if (routeNameFound) return routeNameFound;

      const matchedRoute = this.$route.matched;
      const parent = matchedRoute[matchedRoute.length - 1].parent
        ? matchedRoute[matchedRoute.length - 1].parent
        : null;
      if (parent && parent.name === link.name) routeNameFound = true;

      return routeNameFound;
    },
    // Handles routing
    serviceRoute(link) {
      const path = link.extra ? `${link.path}/${link.extra}` : link.path;
      return `/project/${this.lastActiveProject.id}/${path}`;
    },
    changeProjectRoute() {
      this.show = null;
      this.bottomActive = null;
      this.$router.push({
        name: 'ProjectDashboard',
        params: { id: this.lastActiveProject.id },
      });
    },
  },
};
</script>

<style scoped>
.ecs-sidebar__project-new-button {
  width: 100%;
  padding: 10px 20px 10px 20px;
  display: flex;
  background: #040521 !important;
  color: var(--cs-primary-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid var(--cs-gray-05);
  cursor: pointer;
}
.ecs-sidebar__project-items {
  background: #1a2632 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ecs-sidebar__project-sub-items {
  padding: 10px 20px;
  font-weight: 100;
  color: var(--cs-gray-00);
  cursor: pointer;
}
.ecs-sidebar__sublinks {
  background-color: #1a2632;
}
.projectActive {
  background-color: #1a2632;
}
.ecs-sidebar__title-bold {
  font-weight: 700;
}
.ecs-sidebar__title {
  padding: 20px 20px;
  color: var(--cs-primary-base);
  font-size: 20px;
}
.ecs-sidebar__project-title {
  color: var(--cs-gray-05);
  font-weight: 700;
  font-size: 12px;
}
.ecs-sidebar__project-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 20px;
  border-top: 1px solid var(--cs-gray-05);
  border-bottom: 1px solid var(--cs-gray-05);
}
.ecs-sidebar__project {
  display: flex;
  justify-content: space-between;
  color: var(--cs-gray-00);
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
.ecs-sidebar__project i {
  font-weight: 100;
  font-size: 12px;
  padding-top: 5px;
}

ul {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  flex-direction: column;
  margin: 0px;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}
a:hover {
  color: var(--cs-gray-00);
}
i {
  margin-right: 10px;
  font-size: 22px;
}
li {
  list-style-type: none;
  width: 100%;
}
.ecs-sidebar__active-items {
  background-color: var(--cs-primary-base) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.ecs-sidebar__sub-active-items {
  background-color: var(--cs-primary-dark) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.ecs-sidebar__items {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}
.ecs-sidebar__sub-items {
  padding: 10px 0px 10px 40px;
  font-weight: 400;
  margin-left: 15px;
  font-size: 12px;
}
.ecs-sidebar__none-active-items {
  background: transparent;
}
.ecs-sidebar__bottom-active-items {
  background: var(--cs-primary-base);
}
.ecs-sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #040521;
  position: relative;
}
.ecs-sidebar__project--list {
  position: absolute;
  right: 0px;
  top: 70px;
  transform: translateX(100%);
  z-index: 99;
}
.ecs-sidebar__project--list-items {
  background: #040521 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 250px;
  word-break: break-all;
}
.ecs-sidebar__project--list-items i {
  color: var(--cs-gray-00);
  font-size: 14px;
}
.activeProjectItem {
  background: var(--cs-primary-base) !important;
}
</style>
