<template>
  <div>
    <div class="cs-textstyle-paragraph-small-bold">Connect to Git Provider</div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Choose the Git provider where your site source is hosted. Choose from the
      options below.
    </div>
    <div class="ecs-buttons">
      <b-button
        v-for="button in buttons"
        :key="button.value"
        variant="outline"
        class="ecs-button"
        @click="connect(button.value)"
      >
        <img :src="button.icon" class="ecs-button-icon" />
        {{ button.label }}
      </b-button>
    </div>
  </div>
</template>

<script>
const bitImgUrl = require('@/assets/images/bitbucket.svg');
const gitImgUrl = require('@/assets/images/github.svg');

export default {
  data() {
    return {
      buttons: [
        {
          icon: gitImgUrl,
          label: 'GitHub',
          value: 'github',
        },
        {
          icon: bitImgUrl,
          label: 'Bitbucket',
          value: 'bitbucket',
        },
      ],
    };
  },
  methods: {
    connect(provider) {
      const token = localStorage.access_token;
      const projectId = this.$route.params.id;
      window.location = `${process.env.VUE_APP_REST_ENDPOINT}/gitconnect/${provider}?access_token=${token}&project_id=${projectId}`;
    },
  },
};
</script>

<style scoped>
.ecs-buttons {
  padding-top: 20px;
}
.ecs-modal-description {
  margin-top: 5px;
}
.ecs-button {
  background-color: var(--cs-gray-01);
  padding: 12px 25px;
  margin-right: 13px;
}
.ecs-button:focus {
  background-color: var(--cs-primary-lightest);
  border: 1px solid var(--cs-primary-base);
  outline: none;
  box-shadow: none;
}
.ecs-button-icon {
  margin-right: 10px;
}
</style>
