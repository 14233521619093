var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ecs-image-insights-detail__pan-zoom"},[_c('div',{staticClass:"ecs-image-insights-detail__pan-zoom-image"},[_c('panZoom',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgVisible),expression:"imgVisible"}],ref:"panZoom",attrs:{"options":{ minZoom: 1, maxZoom: 3 }},on:{"transform":_vm.onTransform}},[_c('img',{attrs:{"src":_vm.src}}),_vm._l((_vm.objects),function(object,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showObjects),expression:"showObjects"}],key:index,staticClass:"ecs-image-insights-detail__pan-zoom-image__object",style:({
          left: object.bbox[0] * _vm.currentScale + _vm.transformX + 'px',
          top: object.bbox[1] * _vm.currentScale + _vm.transformY + 'px',
          width: object.bbox[2] * _vm.currentScale + 'px',
          height: object.bbox[3] * _vm.currentScale + 'px',
          border: ("2px solid " + (object.color)),
          position: 'absolute',
        })},[_c('cs-tag',[_vm._v(_vm._s(object.class)+" ")])],1)})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.imgVisible),expression:"!imgVisible"}],staticClass:"blur-container"},[_c('img',{staticClass:"blur",attrs:{"src":_vm.src}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.imgVisible),expression:"!imgVisible"}],staticClass:"action"},[_c('i',{staticClass:"cs-icons-eye-hide-filled"}),_c('cs-button',{on:{"click":_vm.onViewImage}},[_vm._v("View Image")])],1)],1),_c('div',{staticClass:"ecs-image-insights-detail__pan-zoom-buttons"},[_c('cs-button',{attrs:{"size":"small","fill":"clear","variant":"secondary","disabled":_vm.currentScale === 1},on:{"click":_vm.onZoomOut}},[_c('i',{staticClass:"cs-icons-minus"})]),_c('cs-button',{attrs:{"size":"small","fill":"clear","variant":"secondary"},on:{"click":_vm.onZoomReset}},[_c('i',{staticClass:"cs-icons-search"})]),_c('cs-button',{attrs:{"size":"small","fill":"clear","variant":"secondary","disabled":_vm.currentScale === 3},on:{"click":_vm.onZoomIn}},[_c('i',{staticClass:"cs-icons-plus"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }