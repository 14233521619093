<template>
  <div>
    <cs-alert
      v-if="errorContent"
      class="ecs-alert"
      variant="danger"
      title="Errors on Adding Card."
      :content="errorContent"
    >
    </cs-alert>
    <cs-modal :show="showAddCard" @close="onClose">
      <div slot="header">Add Card</div>
      <div slot="body" class="ecs-payment__modal">
        <cs-input
          v-model="name"
          label="Name"
          placeholder="Enter your name"
          required
          class="ecs-payment__modal--inputs"
        />
        <cs-input
          v-model="line1"
          label="Address Line 1"
          placeholder="Enter address 1"
          required
          class="ecs-payment__modal--inputs"
        />
        <cs-input
          v-model="line2"
          label="Address Line 2"
          placeholder="Enter address 2"
          required
          class="ecs-payment__modal--inputs"
        />
        <div class="d-flex justify-content-between">
          <cs-input
            v-model="postal_code"
            label="Postal Code"
            placeholder="Enter Postal Code"
            required
            class="ecs-payment__modal--inputs-fifty"
          />
          <cs-input
            v-model="city"
            label="City"
            placeholder="Enter City"
            required
            class="ecs-payment__modal--inputs-fifty"
          />
        </div>
        <div class="d-flex justify-content-between">
          <cs-input
            v-model="state"
            label="State"
            placeholder="Enter State"
            required
            class="ecs-payment__modal--inputs-fifty"
          />
          <div class="d-flex flex-column ecs-payment__modal--inputs-fifty">
            <div class="ecs-payment__modal--inputs-fifty-country__label">
              Country <span class="text-danger">*</span>
            </div>
            <vue-country-code
              default-country="US"
              placeholder="Select Country"
              class="ecs-payment__modal--inputs-fifty-country"
              @onSelect="onSelect"
            >
            </vue-country-code>
          </div>
        </div>
        <cs-input
          v-model="cardNumber"
          label="Card Number"
          placeholder="**************"
          required
          class="ecs-payment__modal--inputs"
        />
        <div class="ecs-group-input">
          <cs-input
            v-model="expMonth"
            label="Expiry Month"
            type="number"
            required
            placeholder="Expiry Month(11)"
          />
          <cs-input
            v-model="expYear"
            label="Expiry Year"
            type="number"
            required
            placeholder="Expiry Year(2026)"
          />
          <cs-input
            v-model="cvv"
            label="CVV"
            placeholder="3 digit code ***"
            type="number"
            required
            :max-length="3"
          />
        </div>
      </div>
      <cs-button slot="main" :disabled="isDisabled" @click="addCard">
        Add Card
      </cs-button>
    </cs-modal>
  </div>
</template>

<script>
import AddCard from '@/gql/paymentMethods/AddCard.gql';
export default {
  props: {
    showAddCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardNumber: null,
      expMonth: null,
      expYear: null,
      cvv: null,
      isSaving: false,
      errorContent: '',
      name: null,
      line1: null,
      line2: null,
      city: null,
      state: null,
      postal_code: null,
      country: 'US',
    };
  },
  computed: {
    isDisabled() {
      return (
        this.isSaving ||
        !this.cardNumber ||
        !this.expMonth ||
        !this.cvv ||
        !this.name ||
        !this.line1 ||
        !this.line2 ||
        !this.state ||
        !this.country ||
        !this.city ||
        !this.postal_code
      );
    },
  },
  methods: {
    onSelect({ iso2 }) {
      this.country = iso2;
    },
    onClose() {
      this.$emit('close');
    },
    setDefaultValue() {
      this.cardNumber = '';
      this.expMonth = '';
      this.expYear = '';
      this.cvv = '';
      this.isSaving = false;
    },
    async addCard() {
      this.isSaving = true;
      try {
        await this.$apollo.mutate({
          mutation: AddCard,
          variables: {
            projectId: this.$route.params.id,
            cardNumber: this.cardNumber,
            expMonth: Number(this.expMonth),
            expYear: Number(this.expYear),
            cvv: this.cvv,
            name: this.name,
            line1: this.line1,
            line2: this.line2,
            state: this.state,
            city: this.city,
            country: this.country,
            postalCode: this.postal_code,
          },
        });
        this.$emit('fetch-cards');
      } catch (error) {
        if (error.graphQLErrors.length)
          this.errorContent = error.graphQLErrors[0].message;
        else this.errorContent = 'Oops Something went wrong';
        setTimeout(() => {
          this.errorContent = '';
        }, 5000);
      }
      this.setDefaultValue();
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.form-control {
  height: 40px;
}
.ecs-payment__modal {
  margin-top: 16px;
  padding-right: 10px;
}
.ecs-payment__modal--inputs {
  width: 100%;
}
.ecs-group-input {
  display: flex;
  justify-content: space-between;
}
.ecs-group-input label {
  margin-right: 20px;
}
.ecs-alert {
  position: absolute;
  top: 0;
  right: 0;
}
.ecs-payment__modal--inputs-fifty {
  width: 48%;
}
.ecs-payment__modal--inputs-fifty-country {
  width: 100%;
  height: 45px;
  border: 1px solid var(--cs-gray-02);
}
.ecs-payment__modal--inputs-fifty-country__label {
  margin-top: 2px;
  margin-bottom: 7px;
  color: var(--cs-gray-05);
}
</style>
