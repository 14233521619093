<template>
  <div class="ecs-dashboard ecs-account">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title"><span>My Account</span></div>
      <cs-button @click="showEditProfileModal">Edit Profile</cs-button>
    </div>
    <cs-card class="ecs-account__profile">
      <div slot="body" class="ecs-account__profile-details">
        <cs-profile
          class="ecs-account__profile-picture"
          avatar-position="left"
          avatar-size="large"
          :picture="user.picture"
          :detail="detail"
          :name="name"
        >
        </cs-profile>
        <table>
          <tr>
            <td class="cs-textstyle-paragraph-small-bold">Email Address</td>
            <td class="cs-textstyle-paragraph-small">
              {{ user.email }}
            </td>
          </tr>
          <tr>
            <td class="cs-textstyle-paragraph-small-bold">User Since</td>
            <td class="cs-textstyle-paragraph-small">
              {{ user.created_at | moment('from') }}
            </td>
          </tr>
        </table>
      </div>
    </cs-card>
    <app-edit-profile-modal
      v-if="user"
      :show="show"
      :user="user"
      @close-edit-profile-modal="closeEditProfileModal"
      @refetch-user="refetchUser"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import GetUser from '@/gql/user/GetUser.gql';
import AppEditProfileModal from '@/components/account/EditProfileModal.vue';

export default {
  components: { AppEditProfileModal },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          getUserId: $auth.getUserId(),
        };
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    name() {
      if (this.user) {
        const profileName = `${this.user.first_name} ${this.user.last_name}`;
        return profileName;
      }
      return '';
    },
    detail() {
      if (this.user && this.user.company && this.user.role)
        return `${this.user.role} at ${this.user.company}`;
      if (this.user && this.user.role) return this.user.role;
      return '';
    },
  },
  methods: {
    showEditProfileModal() {
      this.show = true;
    },
    closeEditProfileModal() {
      this.show = false;
    },
    async refetchUser() {
      await this.$apollo.queries.user.refetch();
    },
  },
};
</script>

<style scoped>
.ecs-account {
  display: flex;
  flex-direction: column;
}
.ecs-account__profile {
  margin: 40px 0px 20px 0px !important;
}
.ecs-account__profile-details {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
}
.ecs-account .cs-card {
  --cs-card-background: var(--cs-gray-01);
}
.ecs-account >>> .cs-profile {
  --cs-profile-background: var(--cs-gray-01);
}
.ecs-account >>> .cs-profile__main {
  padding: 10px 0px 18px 0px;
}
.ecs-account >>> .cs-profile__content {
  margin-top: auto;
  margin-bottom: auto;
}
table {
  width: 400px;
  color: var(--cs-gray-06);
}
td {
  padding-bottom: 12px;
}
</style>
