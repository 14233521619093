<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div v-if="job" class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        File Conversion / <span>#{{ job.id }}</span>
      </div>
    </div>
    <div v-if="job" class="ecs-file-conversion-detail__container">
      <div class="ecs-file-conversion-detail__info">
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Id</p>
          <div class="file-conversion-job__id">
            <app-truncated-text :max-chars="15" :long-text="job.id" />
            <i class="cs-icons-copy copy-icon" @click="copyText(job.id)"></i>
          </div>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Status</p>
          <cs-tag
            :class="['ecs-file-conversion__file-conversion-tag--' + job.status]"
            >{{ job.status || 'QUEUED' }}</cs-tag
          >
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Execution Time</p>
          <p class="cs-textstyle-paragraph">
            {{ job.chargeable_time ? `${job.chargeable_time}s` : '-' }}
          </p>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Cost</p>
          <p class="cs-textstyle-paragraph">${{ job.cost ? job.cost : '0' }}</p>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Started</p>
          <p class="cs-textstyle-paragraph">
            {{ job.created_at | moment('D MMM YYYY, h:mm a') }}
          </p>
        </div>
      </div>
      <div>
        <app-step-progress-bar
          :steps="mySteps"
          :current-step="currentStep"
          icon-class="cs-icons-check"
          passive-color="#E6EAEA"
          active-color="#00C472"
          :active-thickness="3"
          :passive-thickness="3"
          :line-thickness="3"
        />
      </div>
      <app-info-detail-card
        label="Input File"
        format="MOV"
        :source="job.source_url"
        :size="sourceFileSize"
        action-icon1="cs-icons-doc"
      />
      <app-info-detail-card
        label="Output File"
        :format="job.output_format"
        :destination="job.destination_url"
        :size="convertedFileSize"
        action-icon1="cs-icons-doc"
      />
    </div>
  </div>
</template>

<script>
import AppInfoDetailCard from '@/components/general/InfoDetailCard.vue';
import AppStepProgressBar from '@/components/general/StepProgressBar.vue';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import GetFileConversionJob from '@/gql/fileconversion/GetFileConversionJob.gql';
import { formatBytes } from '@/services/utils';

export default {
  components: {
    AppStepProgressBar,
    AppTruncatedText,
    AppInfoDetailCard,
  },
  apollo: {
    job: {
      query: GetFileConversionJob,
      variables() {
        return {
          getFileConversionJobId: this.$route.params.jobId,
        };
      },
    },
  },
  data() {
    return {
      mySteps: ['STARTED', 'CONVERTED', 'SAVED', 'COMPLETED'],
    };
  },
  computed: {
    currentStep() {
      if (this.job && this.job.milestones.length) {
        const latestStatus = this.job.milestones[0].status;
        return this.mySteps.indexOf(latestStatus) + 1;
      }
      return 0;
    },
    sourceFileSize() {
      const fileSize = this.job.original_size
        ? formatBytes(this.job.original_size)
        : '-';
      return fileSize;
    },
    convertedFileSize() {
      const fileSize = this.job.converted_size
        ? formatBytes(this.job.converted_size)
        : '-';
      return fileSize;
    },
  },
  methods: {
    async copyText(id) {
      await navigator.clipboard.writeText(id);
      this.$toast.open({
        message: 'ID copied',
        type: 'success',
        duration: 2000,
        dismissible: true,
        position: 'top',
      });
    },
  },
};
</script>

<style scoped>
.ecs-file-conversion-detail__container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
  padding: 32px;
  gap: 50px;
}
.ecs-file-conversion-detail__info {
  display: flex;
  justify-content: space-between;
  color: var(--cs-gray-05);
}
.ecs-file-conversion-detail__info p {
  margin-bottom: 5px;
}
.ecs-file-conversion__file-conversion-tag--QUEUED {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-file-conversion__file-conversion-tag--FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
}
.ecs-file-conversion__file-conversion-tag--STARTED {
  --cs-tag-color: var(--cs-info-base) !important;
}
.ecs-file-conversion__file-conversion-tag--COMPLETED {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.copy-icon {
  color: var(--cs-primary-base);
  font-size: 22px;
  cursor: pointer;
}
.file-conversion-job__id {
  display: flex;
  align-items: center;
}
</style>
