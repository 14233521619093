<template>
  <div>
    <div class="cs-textstyle-paragraph-small-bold">
      Site settings for "{{ selectedRepo }}"
    </div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Enter the name and build command you wish to add to your site.
    </div>
    <div class="ecs-modal-content">
      <div class="ecs-modal-content__label">Site Name</div>
      <cs-input
        v-model="name"
        class="ecs-modal-content__input"
        placeholder="ex: “cto-front”"
      />
      <div class="ecs-modal-content__label">
        Build Command
        <span class="light-txt">(Optional) </span>
      </div>
      <cs-input
        v-model="command"
        class="ecs-modal-content__input"
        placeholder="ex: “yarn build”"
      />
    </div>
    <cs-button class="ecs-button" :disabled="isDisabled" @click="createSite">
      Add site
    </cs-button>
  </div>
</template>

<script>
import CreateStaticSite from '@/gql/staticsite/CreateStaticSite.gql';
import DeploySite from '@/gql/staticsite/DeploySite.gql';

export default {
  data() {
    return {
      name: '',
      command: '',
      isSaving: false,
      selectedRepo: this.$route.query.repo,
    };
  },
  computed: {
    isDisabled() {
      return !this.name || this.isSaving;
    },
  },
  methods: {
    async createSite() {
      this.isSaving = true;
      const { data } = await this.$apollo.mutate({
        mutation: CreateStaticSite,
        variables: {
          repo_branch: this.$route.query.branch,
          repo_name: this.$route.query.repo,
          git_account_id: this.$route.query.git_account,
          name: this.name,
          project_id: this.$route.params.id,
          build_command: this.command,
        },
      });
      await this.$apollo.mutate({
        mutation: DeploySite,
        variables: {
          id: data.create_static_site.id,
        },
      });
      this.isSaving = false;
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-top: 5px;
}
.ecs-modal-content {
  margin: 16px 0;
}
.ecs-modal-content__label {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 8px 0;
}
.ecs-modal-content__input {
  width: 100%;
}
.ecs-button {
  float: right;
}
.light-txt {
  color: var(--cs-gray-04);
}
</style>
