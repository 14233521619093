<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        IAM / <span>Project Keys</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button
          variant="primary"
          :disabled="disable"
          @click="showCreateKeyModal"
          >Create Key</cs-button
        >
      </div>
    </div>
    <!-- Empty State -->
    <div
      v-if="project_keys && !project_keys.length"
      class="ecs-key__empty-state"
    >
      <app-empty-state
        :disable="disable"
        :icon="keyChain"
        button-text="Create Key"
        description="Create your first project key"
        @add-action="showCreateKeyModal"
      />
    </div>
    <!-- Spinner -->
    <div
      v-if="$apollo.queries.project_keys.loading"
      class="ecs-spinner__wrapper"
    >
      <cs-spinner class="ecs-spinner" />
    </div>
    <div
      v-if="project_keys && project_keys.length"
      class="ecs-dashboard__search"
    >
      <cs-search-bar
        v-model="val"
        placeholder="Search"
        @submit="search"
        @input="input"
      />
    </div>
    <div
      v-if="project_keys && project_keys.length"
      class="ecs-dashboard__table-head"
    >
      <app-project-key-list
        :is-admin="!disable"
        :project-keys="project_keys"
        :is-busy="$apollo.queries.project_keys.loading"
        :query="val"
        @show-edit-key-modal="showEditKeyModal"
        @show-delete-modal="showDeleteModal"
      />
    </div>

    <!-- Create key Modal  -->
    <app-create-key-modal
      :show-create-key="showCreateKey"
      @close-create-key="closeCreateKey"
    />

    <!-- Edit key Modal  -->
    <app-edit-key-modal
      v-if="showEditKey"
      :show-edit-key="showEditKey"
      :key-data="selectedKey"
      @close-edit-key="closeEditKeyModal"
    />

    <!-- Confirm Delete -->
    <app-confirm-delete-modal
      v-if="showDelete"
      :show-delete="showDelete"
      :item="selectedKey"
      title="Are you sure you want to delete this project key?"
      description="Please enter the key name you wish to delete. This change can't be reversed"
      label="Key Name"
      modal-title="Remove Key"
      @close-delete-site-modal="showDelete = false"
      @confirm-delete="removeKey()"
    />
  </div>
</template>

<script>
import $bus from '@/services/bus';
import { getAccessLevel } from '@/services/utils';
import ListProjectKeys from '@/gql/projectKey/ListProjectKeys.gql';
import DeleteProjectKey from '@/gql/projectKey/DeleteProjectKey.gql';
import AppCreateKeyModal from '@/components/keymanagement/CreateKeyModal.vue';
import AppEditKeyModal from '@/components/keymanagement/EditKeyModal.vue';
import AppProjectKeyList from '@/components/keymanagement/ProjectKeyList.vue';
import AppConfirmDeleteModal from '@/components/general/ConfirmDeleteModal.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';

const keyChain = require('@/assets/images/keychain.svg');
export default {
  components: {
    AppCreateKeyModal,
    AppEditKeyModal,
    AppConfirmDeleteModal,
    AppProjectKeyList,
    AppEmptyState,
  },
  data() {
    return {
      showCreateKey: false,
      showEditKey: false,
      selectedKey: null,
      projectId: this.$route.params.id,
      val: '',
      showDelete: false,
      keyChain,
      disable: null,
    };
  },
  apollo: {
    project_keys: {
      query: ListProjectKeys,
      variables() {
        return {
          project_id: this.projectId,
        };
      },
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'ADMIN') {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    // Closes the popover
    closeActionPopover() {
      if (this.$refs.actionPopover) this.$refs.actionPopover.$emit('close');
    },
    search() {
      // eslint-disable-next-line
      console.log('search');
    },
    input() {
      // eslint-disable-next-line
      console.log('input');
    },
    // Removes the key
    async removeKey() {
      this.closeActionPopover();
      this.showDelete = false;
      await this.$apollo.mutate({
        mutation: DeleteProjectKey,
        variables: {
          id: this.selectedKey.id,
        },
      });
      this.selectedKey = null;
      this.$apollo.queries.project_keys.refetch();
    },
    // Opens the create key modal
    showCreateKeyModal() {
      this.closeActionPopover();
      this.showCreateKey = true;
    },
    // Closes the create key modal
    closeCreateKey() {
      this.showCreateKey = false;
      this.$apollo.queries.project_keys.refetch();
    },
    // Opens the edit key modal
    showEditKeyModal(keyData) {
      this.selectedKey = keyData;
      this.closeActionPopover();
      this.showEditKey = true;
    },
    // Closes the edit key modal
    closeEditKeyModal() {
      this.showEditKey = false;
    },
    // Opens delete modal
    showDeleteModal(keyData) {
      this.selectedKey = keyData;
      this.showDelete = true;
    },
  },
};
</script>

<style scoped>
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
</style>
