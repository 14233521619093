<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Static Site / <span>{{ static_site.name }}</span>
      </div>
      <div>
        <i
          v-if="!disable"
          id="showPopover"
          class="cs-icons-settings-filled ecs-ss-detail__header-icon"
          tabindex="-1"
        ></i>
        <cs-button
          variant="primary"
          :disabled="disable"
          @click="openEditSiteModal"
          >Edit Site</cs-button
        >
        <b-popover
          ref="actionPopover"
          target="showPopover"
          triggers="click blur"
          custom-class="bg-dark"
          placement="bottom"
        >
          <div class="ecs-dashboard__popover">
            <div @click.stop="removeSite()">Remove Site</div>
          </div>
        </b-popover>
      </div>
    </div>
    <!-- Info -->
    <div class="ecs-ss-detail__info ecs-gray">
      <!-- Name -->
      <div class="ecs-ss-detail__info-content">
        <div class="cs-textstyle-paragraph-bold">Site Name</div>
        <div class="cs-textstyle-paragraph">
          {{ static_site.name }}
        </div>
      </div>
      <!-- URL -->
      <div class="ecs-ss-detail__info-content">
        <div class="cs-textstyle-paragraph-bold">Git Provider</div>
        <div>
          <div class="cs-textstyle-paragraph">
            {{ static_site.repo_name }}
            <!-- <span class="mx-2"><i class="cs-icons-services"></i></span> copy icon isn't known -->
          </div>
          <cs-tag class="ecs-tag__gray">{{ static_site.repo_branch }}</cs-tag>
        </div>
      </div>
      <!-- Last Deployed -->
      <div class="ecs-ss-detail__info-content">
        <div class="cs-textstyle-paragraph-bold">Last Deployed</div>
        <div class="cs-textstyle-paragraph">
          {{ deployDate | moment('DD MMM YYYY, h:mm A') }}
        </div>
      </div>
    </div>
    <!-- Cards -->
    <b-row>
      <div class="ecs-ss-detail__extras">
        <b-col cols="12" md="6" sm="12" class="ecs-ss-detail__extras-col">
          <div class="ecs-ss-detail__extras-content">
            <div class="ecs-ss-detail__extras-header">
              <div>Build History</div>
              <cs-button
                :disabled="disable"
                fill="outline"
                @click="triggerBuild"
                >Trigger Build</cs-button
              >
            </div>
            <div class="ecs-ss-detail__extras-card">
              <!-- If the static site has build history  -->
              <div
                v-if="buildHistory.length > 0"
                class="ecs-ss-detail__extras-card-content"
              >
                <div
                  v-for="(history, index) in buildHistory"
                  :key="index"
                  v-router-link="{
                    name: 'StaticSiteBuildDetail',
                    params: {
                      buildid: history.id,
                    },
                  }"
                  class="ecs-card-history"
                >
                  <div
                    class="d-flex flex-row justify-content-between align-items-center"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row">
                        <span class="cs-textstyle-paragraph-small-bold ml-3">{{
                          history.static_site.repo_branch
                        }}</span
                        ><cs-tag
                          class="ecs-tag mx-2"
                          :class="{ tagFail: history.status === 'FAILED' }"
                          >{{ history.status }}</cs-tag
                        >
                      </div>
                      <div class="d-flex flex-row ecs-gray">
                        <div class="cs-textstyle-paragraph">
                          {{ history.start_time | moment('DD MMM YYYY') }} |
                        </div>
                        <div class="px-1 cs-textstyle-paragraph">
                          {{ history.logs.split(/[0-9]+/).pop() }}
                        </div>
                      </div>
                    </div>
                    <i
                      class="cs-icons-next-angle cs-textstyle-informative-paragraph ecs-cursor"
                    ></i>
                  </div>
                </div>
              </div>
              <!-- If there is no build history for the static site  -->
              <div v-else class="ecs-ss-detail__extras-card-content border-top">
                <cs-empty-state
                  title="No builds"
                  description="This static site hasn't triggered any builds yet"
                />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" sm="12" class="ecs-ss-detail__extras-col">
          <div class="ecs-ss-detail__extras-content">
            <div class="ecs-ss-detail__extras-header">
              <div>CDN Info</div>
              <cs-button fill="outline" :disabled="disable"
                >Manage CDN</cs-button
              >
            </div>
            <div class="ecs-ss-detail__extras-card">
              <div class="ecs-ss-detail__extras-card-content">
                <div
                  v-if="buildHistory.length > 0"
                  class="ecs-ss-detail__chart"
                >
                  <app-cdn-spent
                    v-if="static_site"
                    :cdn-id="static_site.cdn_id"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </b-row>
    <app-edit-static-site-modal
      :show="showEditSiteModal"
      @close-edit-site-modal="closeEditSiteModal"
    />
    <!-- Delete Site Modal  -->
    <app-delete-modal
      :show-delete="showDelete"
      :item="item"
      @close-delete-site-modal="close"
    />
  </div>
</template>

<script>
import AppEditStaticSiteModal from '@/components/staticsite/EditStaticSiteModal.vue';
import AppDeleteModal from '@/components/staticsite/DeleteStaticSite.vue';
import ListStaticSiteBuild from '@/gql/staticsite/ListStaticSiteBuild.gql';
import GetStaticSite from '@/gql/staticsite/GetStaticSite.gql';
import ListCdnDomains from '@/gql/cdn/ListCdnDomains.gql';
import AppCdnSpent from '@/components/staticsite/CdnSpentGraph.vue';
import DeploySite from '@/gql/staticsite/DeploySite.gql';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';

export default {
  components: {
    AppEditStaticSiteModal,
    AppCdnSpent,
    AppDeleteModal,
  },
  apollo: {
    static_site: {
      query: GetStaticSite,
      variables() {
        return {
          getStaticSiteId: this.$route.params.siteid,
        };
      },
    },
    buildHistory: {
      query: ListStaticSiteBuild,
      variables() {
        return {
          staticSiteId: this.$route.params.siteid,
        };
      },
      result() {
        this.getDomains();
      },
    },
  },
  data() {
    return {
      disable: null,
      static_site: {
        name: '',
        repo_name: '',
        repo_branch: '',
      },
      buildHistory: [],
      showDelete: false,
      item: {},
      showEditSiteModal: false,
      domains: [],
    };
  },
  computed: {
    deployDate() {
      if (
        !this.$apollo.queries.buildHistory.loading &&
        this.buildHistory &&
        this.buildHistory.length > 0
      ) {
        return this.buildHistory[this.buildHistory.length - 1].end_time;
      }
      return 'N/A';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
    if (this.$route.query.git_account) this.showEditSiteModal = true;
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    async close(val) {
      this.item = {};
      this.showDelete = false;
      if (val) {
        await this.$apollo.queries.static_sites.refetch();
      }
    },
    removeSite() {
      this.closeActionPopover();
      this.item = this.static_site;
      this.showDelete = true;
    },
    async triggerBuild() {
      const variables = {
        id: this.$route.params.siteid,
      };
      try {
        await this.$apollo.mutate({
          mutation: DeploySite,
          variables,
        });
        this.$toast.open({
          message:
            'Site Deployed. Check back after few minutes to see the updated build',
          type: 'success',
          duration: 3000,
          dismissible: true,
          position: 'top',
        });
      } catch (err) {
        this.$toast.open({
          message: `${err}`,
          type: 'error',
          duration: 3000,
          dismissible: true,
          position: 'top',
        });
      }
    },
    async getDomains() {
      const { data } = await this.$apollo.query({
        query: ListCdnDomains,
        variables: {
          listCdnCustomDomainsId: this.static_site.cdn_id,
        },
      });
      this.domains = data.cdnDomains;
    },
    openEditSiteModal() {
      this.showEditSiteModal = true;
    },
    closeEditSiteModal() {
      this.showEditSiteModal = false;
    },
  },
};
</script>

<style scoped>
.ecs-ss-detail__info {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 100px;
  border: 1px solid var(--cs-primary-base);
  background-color: var(--cs-primary-lightest);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
}
.ecs-ss-detail__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ecs-ss-detail__extras {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ecs-ss-detail__extras-content {
  padding: 10px 10px;
  background-color: var(--cs-gray-01) !important;
  border-radius: 5px;
  border: 1px solid var(--cs-gray-02);
}
.ecs-ss-detail__extras-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-ss-detail__extras-header div {
  font-size: 22px;
  font-weight: 600;
}
.ecs-ss-detail__extras-card-content {
  background-color: var(--cs-gray-01) !important;
  height: 500px !important;
  overflow: auto;
}
.ecs-ss-detail__extras-card-content {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.ecs-ss-detail__extras-card-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.ecs-card-history {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid var(--cs-gray-02);
}
.ecs-card-history:first-child {
  border-top: 1px solid var(--cs-gray-02);
}
.ecs-card-history:last-child {
  border-bottom: none;
}
.ecs-tag {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.ecs-tag__gray {
  --cs-tag-color: var(--cs-gray-04) !important;
}
.ecs-ss-detail__extras-col:first-child {
  padding-right: 20px;
}
.ecs-ss-detail__extras-col:last-child {
  padding-left: 20px;
}
.ecs-ss-detail__header-icon {
  font-size: 27px;
  margin-right: 15px;
  cursor: pointer;
}
.ecs-gray {
  color: var(--cs-gray-05);
}
.tagFail {
  --cs-tag-color: var(--cs-danger-base) !important;
}
</style>
