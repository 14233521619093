<template>
  <div class="ecs-process-bar">
    Step {{ step }} of {{ totalStep }}
    <div class="progress-bar-wrapper">
      <b-progress
        v-for="index in totalStep"
        :key="index"
        value="100"
        height="4px"
        variant="--cs-primary-lightest"
        class="progress-bar"
        :class="index > step ? 'completed-bar' : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    totalStep: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      // totalStep: 3,
    };
  },
};
</script>

<style scoped>
.ecs-process-bar {
  padding: 16px 0;
  color: var(--cs-gray-04);
}
.progress-bar {
  background-color: var(--cs-primary-light);
  width: 75px;
  margin-top: 16px;
  margin-right: 5px;
}
.completed-bar {
  background-color: var(--cs-gray-02);
}
.progress-bar-wrapper {
  display: flex;
}
</style>
