<template>
  <div v-if="transcription_job" class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Captions Service / <span>#{{ transcription_job.id }}</span>
      </div>
    </div>
    <div class="ecs-caption-job-detail__video row">
      <caption-video-preview
        v-if="transcription_job && transcription_job.status === 'COMPLETED'"
        class="col-md-7"
        :job="transcription_job"
      />
      <cs-card class="col-md-5">
        <div slot="body" class="ecs-caption-job-detail__info">
          <table class="ecs-caption-job-detail__info-table">
            <tr>
              <td class="cs-textstyle-paragraph-bold">ID</td>
              <td class="cs-textstyle-paragraph">
                {{ transcription_job.id }}
              </td>
              <i class="cs-icons-copy copy-icon" @click="copyText()"></i>
            </tr>
            <tr>
              <td class="cs-textstyle-paragraph-bold">Status</td>
              <td class="cs-textstyle-paragraph">
                <cs-tag
                  :class="['ecs-captions--' + transcription_job.status]"
                  >{{ transcription_job.status }}</cs-tag
                >
              </td>
            </tr>
            <tr>
              <td class="cs-textstyle-paragraph-bold">Execution Time</td>
              <td class="cs-textstyle-paragraph">
                {{ transcription_job.chargeable_time }}ms
              </td>
            </tr>
            <tr>
              <td class="cs-textstyle-paragraph-bold">Cost</td>
              <td class="cs-textstyle-paragraph">
                {{
                  transcription_job.cost ? transcription_job.cost : 0 | currency
                }}
              </td>
            </tr>
            <tr>
              <td class="cs-textstyle-paragraph-bold">Date Created</td>
              <td class="cs-textstyle-paragraph">
                {{
                  transcription_job.created_at | moment('D MMM YYYY, h:mm a')
                }}
              </td>
            </tr>
            <tr>
              <td class="cs-textstyle-paragraph-bold">Language</td>
              <td class="cs-textstyle-paragraph">English (GB)</td>
            </tr>
          </table>
        </div>
      </cs-card>
    </div>
    <div
      v-if="transcription_job.status === 'COMPLETED'"
      class="ecs-caption-job-detail__buttons"
    >
      <cs-button @click="onDownloadVTT">Download in VTT</cs-button>
      <cs-button @click="onDownloadSRT">Download in SRT</cs-button>
      <cs-button @click="onDownloadJSON">Download in JSON</cs-button>
      <cs-button @click="onDownloadTXT">Download in TXT</cs-button>
    </div>
    <div
      v-if="transcription_job.status === 'COMPLETED'"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="captions"
        :fields="fields"
        borderless
        hover
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- ID -->
        <template v-slot:cell(id)="data"> #{{ data.index }} </template>
        <!-- Captions -->
        <template v-slot:cell(captions)="data">
          {{ data.item.text }}
        </template>
        <!-- Start Time -->
        <template v-slot:cell(start-time)="data">
          <span>{{ data.item.startTime.toFixed(2) }}</span>
        </template>
        <!-- End Time -->
        <template v-slot:cell(end-time)="data">
          <span>{{ data.item.endTime.toFixed(2) }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import GetTranscriptionJob from '@/gql/caption/GetTranscriptionJob.gql';
import CaptionVideoPreview from '@/components/caption/CaptionVideoPreview.vue';
import { download } from '@/services/utils';

export default {
  apollo: {
    transcription_job: {
      query: GetTranscriptionJob,
      variables() {
        return {
          getTranscriptionJobId: this.$route.params.jobId,
        };
      },
    },
  },
  components: {
    CaptionVideoPreview,
  },
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'captions',
          label: 'Captions',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'start-time',
          label: 'Start Time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'end-time',
          label: 'End Time',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    jobStatus() {
      return (
        this.transcription_job.status.toLowerCase().charAt(0).toUpperCase() +
        this.transcription_job.status.toLowerCase().slice(1)
      );
    },
    captions() {
      return JSON.parse(this.transcription_job.captions);
    },
  },
  methods: {
    async copyText() {
      await navigator.clipboard.writeText(this.$route.params.jobId);
      this.$toast.open({
        message: 'ID copied',
        type: 'success',
        duration: 2000,
        dismissible: true,
        position: 'top',
      });
    },
    onDownloadJSON() {
      download(
        JSON.stringify(this.transcription_job),
        `image-insight-${this.transcription_job.id}.json`,
        'text/json'
      );
    },
    onDownloadTXT() {
      download(
        JSON.stringify(this.transcription_job),
        `image-insight-${this.transcription_job.id}.txt`,
        'text/plain'
      );
    },
    onDownloadVTT() {
      download(
        JSON.stringify(this.transcription_job),
        `image-insight-${this.transcription_job.id}.vtt`,
        'text/vtt'
      );
    },
    onDownloadSRT() {
      download(
        JSON.stringify(this.transcription_job),
        `image-insight-${this.transcription_job.id}.srt`,
        'text/srt'
      );
    },
  },
};
</script>

<style scoped>
.ecs-caption-job-detail__video {
  margin-top: 40px;
  width: 100%;
}
.ecs-caption-job-detail__info {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
}
.ecs-caption-job-detail__video .cs-card {
  --cs-card-background: var(--cs-gray-01);
}
.ecs-caption-job-detail__info-table {
  width: 400px;
  color: var(--cs-gray-05);
}
td {
  padding-bottom: 12px;
  overflow: hidden;
  max-width: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.copy-icon {
  color: var(--cs-primary-base);
  cursor: pointer;
  font-size: 18px;
}
.ecs-caption-job-detail__buttons {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px;
}
.ecs-caption-job-detail__buttons .cs-button {
  margin-left: 10px;
}
.ecs-captions--QUEUED {
  --cs-tag-color: var(--cs-info-base) !important;
}
.ecs-captions--FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
}
.ecs-captions--IN {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-captions--COMPLETED {
  --cs-tag-color: var(--cs-success-base) !important;
}
</style>
