<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-modal" @close="onClose">
      <div slot="header">Add New Site</div>
      <div slot="body" class="ecs-ss">
        <app-add-progress-bar :step="step" />
        <app-add-site-provider v-if="step === 1" />
        <app-add-site-repo v-if="step === 2" />
        <app-add-site-final v-if="step === 3" @close-modal="updateList" />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppAddSiteProvider from '@/components/staticsite/AddSiteProvider.vue';
import AppAddSiteRepo from '@/components/staticsite/AddSiteRepo.vue';
import AppAddSiteFinal from '@/components/staticsite/AddSiteFinal.vue';
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';

export default {
  components: {
    AppAddSiteFinal,
    AppAddSiteProvider,
    AppAddSiteRepo,
    AppAddProgressBar,
  },
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-add-site-modal');
    },
    updateList() {
      this.$emit('update-list');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ecs-modal {
  ---cs-modal-width: 500px !important;
}
</style>
