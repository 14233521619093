<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        File Conversion / <span>History</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button variant="primary" :disabled="disable" @click="showFile">
          New Job
        </cs-button>
      </div>
    </div>
    <div v-if="file_conversion_jobs && file_conversion_jobs.length">
      <div class="ecs-file-conversion__search">
        <cs-search-bar
          class="ecs-file-conversion__search-bar"
          placeholder="Search Conversions"
        />
        <i
          class="cs-icons-excel ecs-file-conversion__search-bar-icon ecs-cursor"
          @click="downloadFile"
        ></i>
      </div>
    </div>
    <!-- Empty State -->
    <div v-if="file_conversion_jobs && !file_conversion_jobs.length">
      <app-empty-state
        title="No file conversions"
        description="You don't have any file conversions yet."
        button-text="New Job"
        :disable="disable"
        @add-action="showFile"
      />
    </div>
    <div
      v-if="file_conversion_jobs && file_conversion_jobs.length"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="file_conversion_jobs"
        :fields="fields"
        borderless
        hover
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        @row-clicked="myRowClickHandler"
      >
        <!-- Job ID -->
        <template v-slot:cell(id)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text slot="visible" :long-text="data.item.id" />
            <div slot="popover">{{ data.item.id }}</div>
          </app-hover>
        </template>
        <!-- Status -->
        <template v-slot:cell(status)="data">
          <span v-if="data.item.status">
            <cs-tag
              :class="['ecs-tag__' + data.item.status.split(' ').join('_')]"
              >{{ data.item.status }}</cs-tag
            >
          </span>
        </template>
        <!-- Source url -->
        <template #cell(source_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="data.item.source_url"
            />
            <div slot="popover">{{ data.item.source_url }}</div>
          </app-hover>
        </template>
        <!-- Destination url -->
        <template #cell(destination_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="data.item.destination_url"
            />
            <div slot="popover">{{ data.item.destination_url }}</div>
          </app-hover>
        </template>
        <template #cell(output_format)="data">
          {{ data.item.output_format.toUpperCase() }}
        </template>
        <!-- Start Time -->
        <template v-slot:cell(created_at)="data">
          <span>{{ data.item.created_at | moment('D MMM YYYY, h:mm a') }}</span>
        </template>
        <!-- Chargeable Time -->
        <template v-slot:cell(chargeable_time)="data">
          <span v-if="data.item.status === 'COMPLETED'"
            >{{ data.item.chargeable_time }}s</span
          >
          <span
            v-if="
              data.item.status === 'SAVED' ||
              data.item.status === 'CONVERTED' ||
              data.item.status === 'STARTED' ||
              data.item.status === 'QUEUED'
            "
            >-</span
          >
        </template>
      </b-table>
    </div>
    <app-file-conversion-modal
      :show-add="showFileConversionModal"
      @close-file-conversion-modal="closeFile"
      @update-jobs-history="closeAfterJobCreated"
    />
  </div>
</template>

<script>
import ListFileConversionJobs from '@/gql/fileconversion/ListFileConversionJobs.gql';
import { getLastNoOfElements, getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import $excel from '@/services/excel';
// Components
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppHover from '@/components/general/Hover.vue';
import AppFileConversionModal from '@/components/fileconversion/AddFileConversionJobModal.vue';

export default {
  components: {
    AppEmptyState,
    AppTruncatedText,
    AppHover,
    AppFileConversionModal,
  },
  apollo: {
    file_conversion_jobs: {
      query: ListFileConversionJobs,
      variables() {
        return {
          projectId: this.$route.params.id,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      showFileConversionModal: false,
      getLastNoOfElements,
      fields: [
        {
          key: 'id',
          label: 'Job ID',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'source_url',
          label: 'Origin',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'destination_url',
          label: 'Destination',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'output_format',
          label: 'Output Setting',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'chargeable_time',
          label: 'Execution',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },

  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showFile() {
      this.showFileConversionModal = true;
    },
    closeFile() {
      this.showFileConversionModal = false;
    },
    async closeAfterJobCreated() {
      this.showFileConversionModal = false;
      await this.$apollo.queries.file_conversion_jobs.refetch();
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'FileConversionDetail',
        params: { jobId: record.id },
      });
    },

    // Download job list into an excel file
    downloadFile() {
      const fields = [
        'id',
        'created_at',
        'status',
        'source_url',
        'destination_url',
        'output_format',
        'chargeable_time',
      ];

      // $csv.export(output, fields, 'file name');
      $excel.export(this.file_conversion_jobs, fields, 'File Conversion Jobs');
    },
  },
};
</script>

<style scoped>
.ecs-file-conversion__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-file-conversion__search-bar {
  width: 100%;
}
.ecs-file-conversion__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
</style>
