<template>
  <div class="ecs-media">
    <div class="ecs-media-box" @click.prevent="onOpenAssetDetail(asset)">
      <i
        :class="[fileExtIcon, 'cs-file-preview__content__icon']"
        class="icon-large"
      ></i>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <div class="ecs-media__name">{{ asset.name }}</div>
      <i
        :id="`showPopover${id}`"
        class="cs-icons-options ecs-cursor"
        tabindex="-1"
      ></i>
      <b-popover
        ref="actionPopover"
        :target="`showPopover${id}`"
        triggers="click blur"
        custom-class="bg-dark"
        placement="leftbottom"
      >
        <div class="ecs-dashboard__popover">
          <div @click.stop="showEditAssetModal()">Edit File</div>
          <div @click.stop="removeFile(asset)">Remove File</div>
          <div :id="`popover-target-${id}`" class="ecs-file-info">
            File Information
          </div>
        </div>
        <b-popover
          :target="`popover-target-${id}`"
          triggers="click blur hover"
          placement="right"
          custom-class="bg-dark"
        >
          <div class="ecs-dashboard__popover">
            <div class="cs-textstyle-label-text">Date Upload:</div>
            <div class="cs-textstyle-paragraph-small">
              {{ asset.created_at | moment('DD.MM.YYYY') }}
            </div>
            <div class="cs-textstyle-label-text">Original Size:</div>
            <div class="cs-textstyle-paragraph-small">
              {{ getSize(asset.original_size) }}
            </div>
            <div v-if="asset.tags.length > 0">Tags:</div>
            <div class="d-flex flex-row gap-1 flex-wrap">
              <div v-for="tag in asset.tags" :key="tag.key" class="">
                <span class="ecs-key-tag">
                  <cs-tag>{{ tag.key }}</cs-tag> </span
                ><span class="ecs-value-tag">
                  <cs-tag>{{ tag.value }}</cs-tag>
                </span>
              </div>
            </div>
          </div>
        </b-popover>
      </b-popover>
    </div>
    <app-delete-asset
      :item="item"
      :show-delete="showDelete"
      @close-delete-asset-modal="closeDeleteAssetModal"
      @deleted-asset="deletedAsset"
    />
    <app-edit-media-library-asset-modal
      v-if="asset"
      :show-update-media-library-asset="showUpdateMediaLibraryAsset"
      :asset="asset"
      @close-update-media-library-asset="closeUpdateMediaLibraryAssetModal"
    />
  </div>
</template>

<script>
const iconMap = {
  'text/plain': 'cs-icons-txt',
  'application/pdf': 'cs-icons-pdf',
  'application/msword': 'cs-icons-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'cs-icons-word',
  'application/vnd.ms-excel': 'cs-icons-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'cs-icons-excel',
  'application/zip': 'cs-icons-zip',
  'application/x-zip-compressed': 'cs-icons-zip',
};
const defaultIconMap = {
  'image/': 'cs-icons-image-document',
  'audio/': 'cs-icons-audio-file',
  'video/': 'cs-icons-video-file',
  'text/': 'cs-icons-txt',
  'application/': 'cs-icons-application-file',
};
import AppDeleteAsset from '@/components/medialibrary/DeleteMediaLibraryAsset.vue';
import AppEditMediaLibraryAssetModal from '@/components/medialibrary/EditMediaLibraryAssetModal.vue';
export default {
  components: { AppDeleteAsset, AppEditMediaLibraryAssetModal },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    fileExtIcon() {
      const type = this.asset.content_type || 'application/*';
      if (iconMap[type]) return iconMap[type];

      const key = Object.keys(defaultIconMap).find((k) => type.indexOf(k) > -1);
      return defaultIconMap[key] || 'cs-icons-application-file';
    },
  },
  data() {
    return {
      item: {},
      showDelete: false,
      showUpdateMediaLibraryAsset: false,
    };
  },
  methods: {
    closeUpdateMediaLibraryAssetModal(val) {
      if (val) {
        this.$emit('update-after-edit', val);
      }
      this.showUpdateMediaLibraryAsset = false;
    },
    showEditAssetModal() {
      this.showUpdateMediaLibraryAsset = true;
      this.$root.$emit('bv::hide::popover');
    },
    onOpenAssetDetail(asset) {
      this.$router.push({
        name: 'MediaLibraryAssetDetail',
        params: {
          mediaid: this.$route.params.mediaid,
          assetid: asset.id,
        },
      });
    },
    removeFile(val) {
      this.item = val;
      this.showDelete = true;
    },
    getSize(val) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (!val || val < 0) return '0 bytes';
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i === 0) return `${val} ${sizes[i]}`;
      return `${(val / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    deletedAsset() {
      this.$emit('deleted-asset-preview');
    },
    closeDeleteAssetModal() {
      this.showDelete = false;
    },
  },
};
</script>

<style scoped>
.ecs-media {
  display: flex;
  flex-direction: column;
}

.icon-large {
  font-size: 60px;
}

.ecs-media-box {
  background: var(--cs-gray-00);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 171.72px;
  width: 220px;
}

.ecs-key-tag >>> .cs-tag {
  border-radius: 3px 0px 0px 3px;
  background-color: var(--cs-gray-05) !important;
}

.ecs-value-tag >>> .cs-tag {
  border-radius: 0px 3px 3px 0px;
  background-color: var(--cs-gray-04) !important;
  margin-left: 1px;
}

.ecs-media__name {
  word-break: break-word;
}

.ecs-file-info:hover {
  color: var(--cs-primary-base);
}
</style>
