<template>
  <cs-modal :show="show" @close="onClose">
    <div slot="header">Edit Account</div>
    <div slot="body" class="ecs-edit-profile__modal">
      <cs-input
        v-model="firstName"
        label="First Name"
        class="ecs-edit-profile__modal--inputs"
        required
      />
      <cs-input
        v-model="lastName"
        label="Last Name"
        class="ecs-edit-profile__modal--inputs"
        required
      />
      <cs-input
        v-model="company"
        label="Company"
        class="ecs-edit-profile__modal--inputs"
      />
      <cs-input
        v-model="role"
        label="Role"
        class="ecs-edit-profile__modal--inputs"
      />
    </div>
    <cs-button slot="main" @click="updateUser">Save Changes</cs-button>
  </cs-modal>
</template>

<script>
import UpdateUser from '@/gql/user/UpdateUser.gql';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      company: '',
      role: '',
    };
  },
  created() {
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
    this.company = this.user.company;
    this.role = this.user.role;
  },
  methods: {
    onClose() {
      this.$emit('close-edit-profile-modal');
    },
    async updateUser() {
      await this.$apollo.mutate({
        mutation: UpdateUser,
        variables: {
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.company,
          role: this.role,
        },
      });
      this.$emit('refetch-user');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ecs-edit-profile__modal--inputs {
  width: 100%;
  margin-top: 17px;
}
</style>
