<template>
  <cs-modal :show="show" :backdrop="backdrop" @close="onClose">
    <div slot="header" class="ecs-edit-staticsite__header">Edit Site</div>
    <div slot="body" class="ecs-edit-staticsite__body">
      <div>
        <p class="cs-textstyle-label-text ecs-edit-staticsite__git-provider">
          Git Provider
        </p>
        <div class="ecs-edit-staticsite__buttons">
          <b-button
            v-for="button in buttons"
            :key="button.value"
            variant="outline"
            :class="{
              'bitbucket-label-color': button.label === 'Bitbucket',
              'github-label-color': button.label === 'GitHub',
            }"
            class="btn"
            :pressed.sync="button.state"
            @click="onSelectProvider(button)"
          >
            <img :src="button.icon" class="btn-icon" />
            {{ button.label }}
          </b-button>
        </div>
      </div>
      <div class="ecs-edit-staticsite__content">
        <div class="content-row">
          <div class="label">Repo</div>
          <cs-select-search
            v-model="selectedRepo"
            :options="repos"
            :value="selectedRepo"
            icon="cs-icons-expand-arrow"
          />
        </div>
        <cs-input
          v-model="branch"
          class="form-input"
          placeholder="Branch"
          label="Branch"
        />
        <cs-input
          v-model="name"
          class="form-input"
          placeholder="ex: “cto-front”"
          label="Site Name"
        />
        <div>
          <div class="label">
            Build Command
            <span class="light-txt">(Optional) </span>
          </div>
          <cs-input
            v-model="command"
            class="form-input"
            placeholder="ex: “yarn build”"
          />
        </div>
      </div>
    </div>
    <cs-button
      slot="main"
      variant="primary"
      :disabled="isDisabled"
      @click="updateStaticSite"
      >Save Changes</cs-button
    >
  </cs-modal>
</template>

<script>
import ListRepos from '@/gql/staticsite/ListRepos.gql';
import UpdateStaticSite from '@/gql/staticsite/UpdateStaticSite.gql';
import GetStaticSite from '@/gql/staticsite/GetStaticSite.gql';
import DeploySite from '@/gql/staticsite/DeploySite.gql';

const bitImgUrl = require('@/assets/images/bitbucket.svg');
const gitImgUrl = require('@/assets/images/github.svg');

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      backdrop: true,
      buttons: [
        {
          icon: gitImgUrl,
          label: 'GitHub',
          value: 'github',
          state: false,
        },
        {
          icon: bitImgUrl,
          label: 'Bitbucket',
          value: 'bitbucket',
          state: false,
        },
      ],
      repos: [],
      branch: '',
      name: '',
      selectedRepo: '',
      command: '',
      isUpdating: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.name || this.isUpdating;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        if (newValue === true && !this.$route.query.git_account) {
          this.selectedRepo = this.static_site.repo_name;
          this.branch = this.static_site.repo_branch;
          this.name = this.static_site.name;
          if (this.static_site.gitconnect.external_service === 'BITBUCKET') {
            this.buttons[1].state = true;
            this.buttons[0].state = false;
          }
          if (this.static_site.gitconnect.external_service === 'GITHUB') {
            this.buttons[0].state = true;
            this.buttons[1].state = false;
          }
        }
      },
    },
  },
  mounted() {
    if (this.$route.query.provider === 'Bitbucket') {
      this.buttons[1].state = true;
      this.buttons[0].state = false;
    }
    if (this.$route.query.provider === 'Github') {
      this.buttons[0].state = true;
      this.buttons[1].state = false;
    }
  },
  methods: {
    onClose() {
      this.$emit('close-edit-site-modal');
      this.$router.push(this.$route.path);
    },
    async onSelectProvider(button) {
      // eslint-disable-next-line array-callback-return
      this.buttons.map((btn) => {
        if (btn.label !== button.label) btn.state = false;
      });
      const provider = button.value;
      const token = localStorage.access_token;
      const projectId = this.$route.params.id;
      window.location = `${process.env.VUE_APP_REST_ENDPOINT}/gitconnect/${provider}?access_token=${token}&project_id=${projectId}&static_site_id=${this.static_site.id}`;
    },
    repoSelect(repo) {
      this.selectedRepo = repo.value;
    },
    async updateStaticSite() {
      this.isUpdating = true;
      const { data } = await this.$apollo.mutate({
        mutation: UpdateStaticSite,
        variables: {
          repoBranch: this.branch,
          repoName: this.selectedRepo,
          oldRepoName: this.static_site.repo_name,
          gitAccountId: this.$route.query.git_account
            ? this.$route.query.git_account
            : this.static_site.git_account_id,
          oldGitAccountId: this.static_site.git_account_id,
          name: this.name,
          updateStaticSiteId: this.static_site.id,
          projectId: this.static_site.project_id,
        },
      });
      await this.$apollo.mutate({
        mutation: DeploySite,
        variables: {
          id: data.update_static_site.id,
        },
      });
      this.isUpdating = false;
      this.onClose();
    },
    async listRepos() {
      const { data } = await this.$apollo.query({
        query: ListRepos,
        variables: {
          git_account_id: this.$route.query.git_account
            ? this.$route.query.git_account
            : this.static_site.git_account_id,
        },
      });
      this.repos = data.list_repos.map((repo) => ({
        label: repo,
        value: repo,
      }));
    },
  },
  apollo: {
    static_site: {
      query: GetStaticSite,
      variables() {
        return {
          getStaticSiteId: this.$route.params.siteid,
        };
      },
      result({ data }) {
        this.listRepos();
        if (!this.$route.query.provider) {
          this.selectedRepo = data.static_site.repo_name;
          this.branch = data.static_site.repo_branch;
          this.name = data.static_site.name;
          if (data.static_site.gitconnect.external_service === 'BITBUCKET') {
            this.buttons[1].state = true;
            this.buttons[0].state = false;
          }
          if (data.static_site.gitconnect.external_service === 'GITHUB') {
            this.buttons[0].state = true;
            this.buttons[1].state = false;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: var(--cs-gray-01);
  padding: 13px 25px;
  margin-right: 12px;
  box-shadow: none !important;
  font-weight: 700;
}
.btn-icon {
  margin-right: 10px;
}
.ecs-edit-staticsite__content {
  margin: 10px 0px 33px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.label {
  font-size: 16px;
  color: var(--cs-gray-05);
  font-weight: 500;
  text-align: left;
  padding-bottom: 8px;
}
.light-txt {
  color: var(--cs-gray-04);
}
.form-input {
  width: 100%;
}
.cs-select-search
  >>> .cs-select-search__results-wrapper
  .cs-select-search__results {
  max-height: 171px;
}
.ecs-edit-staticsite__git-provider {
  margin-top: 16px;
  color: var(--cs-gray-05);
}
* >>> .cs-modal__footer {
  margin-top: 0px !important;
}
* >>> .active {
  background-color: var(--cs-primary-lightest);
  border: 1px solid var(--cs-primary-base);
  box-shadow: none !important;
}
.bitbucket-label-color {
  color: #03316f;
}
.bitbucket-label-color:hover {
  color: #03316f;
}
.github-label-color {
  color: var(--cs-secondary-light);
}
</style>
