<template>
  <div>
    <cs-modal :show="showUpdateUser" class="ecs-users" @close="onClose">
      <div slot="header">
        Update {{ user.user.first_name }} {{ user.user.last_name }}
      </div>
      <div slot="body" class="ecs-users__body">
        <div class="ecs-users__select">
          <div class="cs-textstyle-label-text ecs-users__select-label">
            Permission Level <span>*</span>
            <i
              id="infoIcon"
              class="cs-icons-info mx-1 ecs-icon"
              tabindex="-1"
            ></i>
            <!-- Info popover -->
            <b-popover
              ref="infoPopover"
              target="infoIcon"
              placement="rightbottom"
              fallback-placement="counterclockwise"
              triggers="hover"
              custom-class="bg-dark"
            >
              <div class="ecs-info">
                <div v-for="(i, index) in info" :key="index">
                  <div>
                    <span class="ecs-info__label">{{ i.label }}</span> -
                    {{ i.text }}
                  </div>
                </div>
              </div>
            </b-popover>
          </div>
          <cs-select
            v-model="selectedRole"
            :options="permissions"
            placeholder="Select Permission Level"
            class="ecs-users__select-component"
            required
          />
        </div>
      </div>
      <cs-button slot="main" variant="primary" @click="updateUser"
        >Save Changes</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import UpdateProjectMember from '@/gql/user/UpdateProjectMember.gql';

export default {
  props: {
    showUpdateUser: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permissions: [
        {
          label: 'Admin',
          value: 'ADMIN',
        },
        {
          label: 'Developer',
          value: 'DEVELOPER',
        },
        {
          label: 'Viewer',
          value: 'VIEWER',
        },
      ],
      info: [
        {
          label: 'ADMIN',
          text: 'able to do anything in the account including delete projects, delete keys, manage users, and manage billing',
        },
        {
          label: 'DEVELOPER',
          text: 'able to do anything in the account except delete projects, delete keys, manage users, and manage billing',
        },
        {
          label: 'VIEWER',
          text: 'only able to view information (read-only access). For now this will include read-only access to billing',
        },
      ],
      selectedRole: this.user.role,
    };
  },
  methods: {
    onClose() {
      this.$emit('close-update-user');
    },
    async updateUser() {
      await this.$apollo.mutate({
        mutation: UpdateProjectMember,
        variables: {
          role: this.selectedRole,
          userId: this.user.id,
        },
      });
      this.$emit('close-update-user', true);
    },
  },
};
</script>

<style scoped>
.ecs-users__body {
  width: 100%;
}
.ecs-users__body-header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-users__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-users__select-component {
  width: 100%;
}
.ecs-users__select-label {
  margin: 10px 0px;
}
.ecs-users__select-label span {
  color: var(--cs-danger-base);
}
.ecs-icon {
  color: var(--cs-gray-05);
  cursor: pointer;
}
.ecs-info {
  color: var(--cs-gray-00);
  font-size: 10px;
}
.ecs-info__label {
  color: var(--cs-info-base);
  font-weight: bold;
}
</style>
