var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-progress__wrapper"},[_c('div',{staticClass:"step-progress__wrapper-before",style:({
      'background-color': _vm.passiveColor,
      height: _vm.lineThickness + 'px',
    })}),_c('div',{staticClass:"step-progress__bar"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step-progress__step",class:{
      "step-progress__step--active": index === _vm.currentStep,
      "step-progress__step--valid": index < _vm.currentStep
    },style:({
        '--activeColor': _vm.activeColor,
        '--passiveColor': _vm.passiveColor,
        '--activeBorder': _vm.activeThickness + 'px',
        '--passiveBorder': _vm.passiveThickness + 'px',
      })},[_c('div',{staticClass:"cs-textstyle-paragraph-small-bold step-progress__step-label"},[_vm._v(" "+_vm._s(step)+" ")]),_c('span',[_c('i',{class:_vm.iconClass})]),_c('div',{staticClass:"step-progress__step-icon",class:_vm.iconClass})])}),0),_c('div',{staticClass:"step-progress__wrapper-after",style:({
      transform:
        'scaleX(' + _vm.scaleX + ') translateY(-50%) perspective(1000px)',
      'background-color': _vm.activeColor,
      height: _vm.lineThickness + 'px',
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }