/* eslint-disable */
const $excel = this;

// Exports an array of objects (values) to Excel using fields as the column titles
$excel.export = function (values, fields, fileName) {
  /* eslint-disable */
  const wb = XLSX.utils.book_new();

  const data = [fields];
  values.forEach((item) => {
    const itemProps = [];
    fields.forEach((field) => {
      itemProps.push(item[field]);
    });
    data.push(itemProps);
  });

  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}
