<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-create-library-modal" @close="onClose">
      <div slot="header">Create Library</div>
      <div slot="body">
        <div class="ecs-create-library__inputs">
          <cs-input
            v-model="libraryName"
            placeholder="ex: Project Library"
            label="Name"
            required
          />
          <cs-textarea
            v-model="description"
            label="Description"
            placeholder="ex: My library for 'Default' project"
          >
          </cs-textarea>
          <cs-alert
            variant="warning"
            title="Please note"
            content="Feature selection is one time only and cannot be changed later. It will apply to all the assets of the media library. "
          >
          </cs-alert>
          <div class="ecs-create-library__features">
            <div>
              <p class="cs-textstyle-paragraph-bold">Video Features</p>
              <cs-checkbox
                v-for="(feature, index) in videoFeatures"
                :key="feature.label"
                class="my-2"
                :label="feature.label"
                :value="feature.value"
                @input="onInputVideoFeature($event, index)"
              ></cs-checkbox>
            </div>
            <div>
              <p class="cs-textstyle-paragraph-bold">Image Features</p>
              <cs-checkbox
                v-for="(feature, index) in imageFeatures"
                :key="feature.label"
                class="my-2"
                :label="feature.label"
                :value="feature.value"
                @input="onInputImageFeature($event, index)"
              ></cs-checkbox>
            </div>
            <div>
              <p class="cs-textstyle-paragraph-bold">Audio Features</p>
              <cs-checkbox
                v-for="(feature, index) in audioFeatures"
                :key="feature.label"
                class="my-2"
                :label="feature.label"
                :value="feature.value"
                @input="onInputAudioFeature($event, index)"
              ></cs-checkbox>
            </div>
          </div>
        </div>
      </div>
      <cs-button slot="main" :disabled="!valid" @click="createMediaLibrary">
        Create Library
      </cs-button>
    </cs-modal>
  </div>
</template>

<script>
import CreateMediaLibrary from '@/gql/medialibrary/CreateMediaLibrary.gql';

export default {
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      libraryName: '',
      description: '',
      videoFeatures: [
        {
          label: 'Content Moderation',
          value: false,
        },
        {
          label: 'Optical Character Recognition',
          value: false,
        },
        {
          label: 'Tagging',
          value: false,
        },
        {
          label: 'Object Detection and Recognition',
          value: false,
        },
        {
          label: 'Metadata',
          value: false,
        },
        {
          label: 'Transcoding',
          value: false,
        },
        {
          label: 'Captions',
          value: false,
        },
      ],
      imageFeatures: [
        {
          label: 'Content Moderation',
          value: false,
        },
        {
          label: 'Optical Character Recognition',
          value: false,
        },
        {
          label: 'Tagging',
          value: false,
        },
        {
          label: 'Object Detection and Recognition',
          value: false,
        },
        {
          label: 'Metadata',
          value: false,
        },
      ],
      audioFeatures: [
        {
          label: 'Transcoding',
          value: false,
        },
        {
          label: 'Captions',
          value: false,
        },
      ],
    };
  },
  computed: {
    selectedVideoFeatures() {
      return this.formatData(this.videoFeatures);
    },
    selectedImageFeatures() {
      return this.formatData(this.imageFeatures);
    },
    selectedAudioFeatures() {
      return this.formatData(this.audioFeatures);
    },
    valid() {
      return (
        this.libraryName &&
        (this.selectedVideoFeatures.length ||
          this.selectedImageFeatures.length ||
          this.selectedAudioFeatures.length)
      );
    },
  },
  methods: {
    reset() {
      this.libraryName = '';
      this.description = '';
      this.videoFeatures.forEach((elem) => {
        elem.value = false;
      });
      this.imageFeatures.forEach((elem) => {
        elem.value = false;
      });
      this.audioFeatures.forEach((elem) => {
        elem.value = false;
      });
    },
    onClose() {
      this.reset();
      this.$emit('close');
    },
    formatData(features) {
      const filteredData = features.filter(
        (feature) => feature.value && feature
      );
      return filteredData.map((feature) =>
        feature.label.toLowerCase().split(' ').join('-')
      );
    },
    onInputVideoFeature(checked, index) {
      this.videoFeatures[index].value = checked;
    },
    onInputImageFeature(checked, index) {
      this.imageFeatures[index].value = checked;
    },
    onInputAudioFeature(checked, index) {
      this.audioFeatures[index].value = checked;
    },
    async createMediaLibrary() {
      await this.$apollo.mutate({
        mutation: CreateMediaLibrary,
        variables: {
          name: this.libraryName,
          projectId: this.$route.params.id,
          audioFeatures: this.selectedAudioFeatures,
          imageFeatures: this.selectedImageFeatures,
          videoFeatures: this.selectedVideoFeatures,
          description: this.description,
        },
      });
      this.reset();
      this.$emit('close-after-create');
    },
  },
};
</script>

<style scoped>
.ecs-create-library-modal {
  ---cs-modal-width: 900px !important;
}
.ecs-create-library__inputs {
  margin: 20px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ecs-create-library__features {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.ecs-button {
  float: right;
}
</style>
