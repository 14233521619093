<template>
  <div>
    <div class="ecs-truncated-text">
      <span>{{ truncateText }} </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    longText: {
      type: String,
      required: false,
      default: '',
    },
    maxChars: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  computed: {
    truncateText() {
      if (this.longText && this.longText.length > this.maxChars) {
        const result = this.longText.substring(0, this.maxChars);
        return `${result} ...`;
      }
      return this.longText;
    },
  },
};
</script>
<style scoped></style>
