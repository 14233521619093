var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cs-modal',{staticClass:"ecs-edit-library-modal",attrs:{"show":_vm.showUpdateMediaLibrary},on:{"close":_vm.onClose}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("Edit Library")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"ecs-edit-library-modal__inputs"},[_c('cs-input',{attrs:{"placeholder":"ex: Project Library","label":"Name","required":""},model:{value:(_vm.libraryName),callback:function ($$v) {_vm.libraryName=$$v},expression:"libraryName"}}),_c('cs-textarea',{attrs:{"label":"Description","placeholder":"ex: My library for 'Default' project"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),(_vm.mediaLibrary)?_c('div',{staticClass:"ecs-edit-library-modal__features"},[_c('div',[_c('p',{staticClass:"cs-textstyle-paragraph-bold"},[_vm._v("Video Features")]),(
                _vm.mediaLibrary.video_features &&
                _vm.mediaLibrary.video_features.length
              )?_c('div',_vm._l((_vm.mediaLibrary.video_features),function(feature){return _c('p',{key:feature},[_vm._v(" "+_vm._s(_vm.makeFeature(feature))+" ")])}),0):_c('div',[_vm._v("No features")])]),_c('div',[_c('p',{staticClass:"cs-textstyle-paragraph-bold"},[_vm._v("Image Features")]),(
                _vm.mediaLibrary.image_features &&
                _vm.mediaLibrary.image_features.length
              )?_c('div',_vm._l((_vm.mediaLibrary.image_features),function(feature){return _c('p',{key:feature},[_vm._v(" "+_vm._s(_vm.makeFeature(feature))+" ")])}),0):_c('div',[_vm._v("No features")])]),_c('div',[_c('p',{staticClass:"cs-textstyle-paragraph-bold"},[_vm._v("Audio Features")]),(
                _vm.mediaLibrary.audio_features &&
                _vm.mediaLibrary.audio_features.length
              )?_c('div',_vm._l((_vm.mediaLibrary.audio_features),function(feature){return _c('p',{key:feature},[_vm._v(" "+_vm._s(_vm.makeFeature(feature))+" ")])}),0):_c('div',[_vm._v("No features")])])]):_vm._e()],1)]),_c('cs-button',{attrs:{"slot":"main","disabled":!_vm.libraryName,"variant":"primary"},on:{"click":_vm.updateMediaLibrary},slot:"main"},[_vm._v("Save Changes")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }