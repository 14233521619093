<template>
  <div>
    <cs-modal :show="showDelete" @close="onClose">
      <div slot="header">Remove File</div>
      <div slot="body" class="ecs-media-asset" style="width: 100%">
        <div class="ecs-media-asset__header">
          Are you sure you want to remove {{ item.name }}?
        </div>
        <div class="ecs-media-asset__sub-header">
          To confirm, type the name of the file you wish to delete. This change
          can't be reversed.
        </div>

        <div class="ecs-media-asset__prompt">
          <cs-input
            v-model="name"
            label="File Name"
            :placeholder="placeholder"
            required
            class="ecs-media-asset__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="danger"
        :disabled="!valid"
        @click="deleteAsset"
        >Confirm Delete</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import DeleteMediaLibaryAsset from '@/gql/medialibrary/DeleteMediaLibraryAsset.gql';

export default {
  props: {
    showDelete: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    placeholder() {
      return `Type here: ${this.item.name}`;
    },
    valid() {
      return this.name === this.item.name;
    },
  },
  methods: {
    onClose(val) {
      this.name = '';
      this.$emit('close-delete-asset-modal', val);
    },
    async deleteAsset() {
      if (this.name === this.item.name) {
        try {
          await this.$apollo.mutate({
            mutation: DeleteMediaLibaryAsset,
            variables: {
              deleteMediaLibraryAssetId: this.item.id,
            },
          });
          this.$emit('deleted-asset');
        } catch (e) {
          console.error(e);
        }
        this.onClose(true);
      }
    },
  },
};
</script>

<style scoped>
.ecs-media-asset__header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-media-asset__sub-header {
  color: var(--cs-gray-04);
  margin: 5px 0px;
  font-size: 14px;
}
.ecs-media-asset__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-media-asset__wide-input {
  width: 100%;
}
* >>> .cs-input__label-text {
  font-weight: 600;
}
</style>
