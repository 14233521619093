<template>
  <div>
    <p class="info-detail-card__label cs-textstyle-paragraph-bold">
      {{ label }}
    </p>
    <div class="info-detail-card__info">
      <div class="info-detail-card__group">
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Format</p>
          <p class="cs-textstyle-paragraph">{{ format.toUpperCase() }}</p>
        </div>
        <div v-if="source">
          <p class="cs-textstyle-paragraph-small-bold">Source</p>
          <p class="cs-textstyle-paragraph">
            {{ source }}
          </p>
        </div>
        <div v-if="destination">
          <p class="cs-textstyle-paragraph-small-bold">Destination</p>
          <p class="cs-textstyle-paragraph">
            {{ destination }}
          </p>
        </div>
      </div>
      <div class="info-detail-card__group">
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Size</p>
          <p class="cs-textstyle-paragraph">{{ size }}</p>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Action</p>
          <div class="info-detail-card__icons">
            <i :class="actionIcon1"></i>
            <i :class="actionIcon2"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    format: {
      type: String,
      required: false,
      default: '',
    },
    source: {
      type: String,
      required: false,
      default: '',
    },
    destination: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    actionIcon1: {
      type: String,
      required: false,
      default: 'cs-icons-play-filled',
    },
    actionIcon2: {
      type: String,
      required: false,
      default: 'cs-icons-download-filled',
    },
  },
};
</script>

<style scoped>
.info-detail-card__label {
  color: var(--cs-primary-base);
}
.info-detail-card__info {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--cs-gray-02);
  padding: 15px 20px 0px 20px;
  background-color: var(--cs-gray-00);
  color: var(--cs-gray-05);
}
.info-detail-card__group {
  display: flex;
  gap: 70px;
}
.info-detail-card__icons {
  display: flex;
  gap: 25px;
  color: var(--cs-gray-06);
  font-size: 17px;
}
</style>
