<template>
  <div class="ecs-home">
    <b-row class="g-0">
      <b-col md="2">
        <!-- Sidebar -->
        <app-sidebar
          v-if="projects && activeProject"
          :projects="projects"
          :last-active-project="activeProject"
          @open-project-modal="openProjectModal"
          @set-active-project="setActiveProject"
        />
      </b-col>
      <b-col md="10">
        <!-- Topbar -->
        <app-topbar :last-active-project="activeProject" />
        <router-view />
      </b-col>
    </b-row>
    <!-- Create New Project Modal -->
    <cs-modal
      :show="showCreateModal"
      class="ecs-home__modal"
      @close="closeProjectCreateModal"
    >
      <div slot="header" class="ecs-home__modal--header">New Project</div>
      <div slot="body" class="ecs-home__modal--body">
        <cs-input v-model="project.name" label="Name" type="text" required />
        <cs-input
          v-model="project.company_name"
          label="Company Name"
          type="text"
          required
        />
        <cs-input
          v-model="project.company_national_tax_id"
          label="Company National Tax ID"
          type="text"
          required
        />
        <cs-textarea
          v-model="project.company_address"
          label="Company Address"
          type="text"
          required
        />
        <cs-textarea
          v-model="project.description"
          label="Description"
          type="text"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        :disabled="!valid"
        @click="createProject(project)"
        >Create</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
// GQL
import ListUserProjects from '@/gql/project/ListUserProjects.gql';
import CreateProject from '@/gql/project/CreateProject.gql';
// Component
import AppSidebar from '@/components/general/Sidebar.vue';
import AppTopbar from '@/components/general/Topbar.vue';

export default {
  components: {
    AppTopbar,
    AppSidebar,
  },
  data() {
    return {
      userId: $auth.getUserId(),
      showCreateModal: false,
      project: {},
      activeProject: {},
    };
  },
  computed: {
    inactiveProjects() {
      const projects =
        this.projects &&
        this.activeProject &&
        this.projects.filter((p) => p.project.id !== this.activeProject.id);
      return projects;
    },
    valid() {
      return (
        !!this.project.name &&
        !!this.project.company_name &&
        !!this.project.company_address &&
        !!this.project.company_national_tax_id
      );
    },
  },
  watch: {
    projects() {
      if (this.projects && !this.projects.length) {
        this.createProject();
      }
    },
  },
  apollo: {
    projects: {
      query: ListUserProjects,
      variables() {
        return {
          userId: this.userId,
        };
      },
      result(data) {
        this.getLastActiveProject();
        const projectId = this.activeProject
          ? this.activeProject.id
          : data.data.projects[0].project.id;
        const projectName = this.activeProject
          ? this.activeProject.name
          : data.data.projects[0].project.name;
        if (!this.$route.params.id && this.$route.path !== '/account') {
          this.$router.push({
            name: 'ProjectDashboard',
            params: { id: projectId },
          });
          this.setAccessLevel(projectId);
          this.setLastActiveProject(projectId, projectName);
          this.getLastActiveProject();
        } else if (this.$route.path === '/account') {
          this.$router.push({
            name: 'Account',
          });
        }
      },
    },
  },
  async mounted() {
    $bus.$on('update-active-project', (project) => {
      this.setActiveProject(project);
    });
    $bus.$on('create-new-project', () => {
      this.openProjectModal();
    });
    $bus.$on('refresh-projects', async (deletedProject) => {
      const remainingProjects = this.projects.filter(
        (project) => project.project_id !== deletedProject.id
      );
      this.setActiveProject(remainingProjects[0].project);
      this.$router.push({
        name: 'ProjectDashboard',
        params: { id: remainingProjects[0].project_id },
      });
      await this.$apollo.queries.projects.refetch();
    });
  },
  methods: {
    // Creates new project
    async createProject(project) {
      const projectInfo = {
        name: project ? project.name : 'Default Project',
        companyAddress: project ? project.company_address : ' ',
        companyName: project ? project.company_name : ' ',
        companyNationalTaxId: project ? project.company_national_tax_id : ' ',
        description: project
          ? project.description
          : 'This is your default project.',
      };
      await this.$apollo.mutate({
        mutation: CreateProject,
        variables: projectInfo,
      });
      this.$apollo.queries.projects.refetch({
        userId: this.userId,
      });
      if (this.project) this.showCreateModal = false;
      this.getLastActiveProject();

      const projectId = this.activeProject
        ? this.activeProject.id
        : this.projects[0].project.id;
      const projectName = this.activeProject
        ? this.activeProject.name
        : this.projects[0].project.name;

      this.setLastActiveProject(projectId, projectName);
      this.getLastActiveProject();
    },
    // Opens new project modal
    openProjectModal() {
      this.showCreateModal = true;
    },
    // Closes new project modal
    closeProjectCreateModal() {
      this.showCreateModal = false;
    },
    // Sets last active project in the localstorage
    setLastActiveProject(projectId, projectName) {
      localStorage.setItem(
        'lastActiveProject',
        JSON.stringify({
          id: projectId,
          name: projectName,
        })
      );
    },
    // Retrieves last active project from the localstorage
    getLastActiveProject() {
      this.activeProject = JSON.parse(
        localStorage.getItem('lastActiveProject')
      );
    },
    // Updates new active project
    setActiveProject(project) {
      this.setAccessLevel(project.id);
      const projectId = project.id;
      const projectName = project.name;
      this.setLastActiveProject(projectId, projectName);
      this.getLastActiveProject();
    },
    // Sets the accessLevel for the project
    setAccessLevel(projectID) {
      const accessLevel =
        this.projects &&
        this.projects.filter((p) => p.project_id === projectID);
      localStorage.setItem(
        'accessLevel',
        JSON.stringify({
          role: accessLevel[0].role,
        })
      );

      $bus.$emit('access-level-updated');
    },
  },
};
</script>

<style scoped>
.ecs-home {
  flex: 1;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: var(--cs-gray-00);
}
.ecs-home__modal {
  ---cs-modal-width: 450px !important;
}
.ecs-home__modal--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
</style>
