import { render, staticRenderFns } from "./AddFileConversionFinal.vue?vue&type=template&id=3580b5f6&scoped=true&"
import script from "./AddFileConversionFinal.vue?vue&type=script&lang=js&"
export * from "./AddFileConversionFinal.vue?vue&type=script&lang=js&"
import style0 from "./AddFileConversionFinal.vue?vue&type=style&index=0&id=3580b5f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3580b5f6",
  null
  
)

export default component.exports