/* eslint-disable no-return-assign */
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export const groupByFormats = {
  day: 'MMM DD',
  week: 'YYYY-MM-DD',
  month: 'MMM YYYY',
};

export const chartIcons = [
  {
    id: 'stacked',
    class: 'cs-chart-stacked',
    active: true,
  },
  {
    id: 'line',

    class: 'cs-chart-line',
    active: false,
  },
  {
    id: 'bar',
    class: 'cs-chart-bar',
    active: false,
  },
];

export const convertSecToMinSec = (seconds) => {
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');
  return `${m}m ${s}s`;
};

export const getLastNoOfElements = (array, noOfElements) =>
  array.slice(1).slice(-noOfElements);

export const serviceOptions = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: 'Image Intelligence',
    value: 'Image Intelligence',
  },
  {
    label: 'Link Intelligence',
    value: 'Link Intelligence',
  },
  { label: 'Link Preview', value: 'Link Preview' },
  { label: 'Transcoding', value: 'Transcoding' },
  { label: 'Transcription', value: 'Transcription' },
  { label: 'File Conversion', value: 'File Conversion' },
  { label: 'Media Services', value: 'Media Services' },
];

export const intervalOptions = [
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
];

export const dateRangeOptions = [
  { label: 'Last 7 Days', value: 'last_7_days' },
  { label: 'Custom', value: 'custom' },
];

const getFirstDayOfWeek = (d) => {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const getDynamicHeadings = (startDate, endDate, groupBy) => {
  const addDatesFormat = {
    day: 'd',
    week: 'w',
    month: 'M',
  };
  const dynamicHeadings = [];

  const startDateMoment =
    groupBy === 'week'
      ? moment(getFirstDayOfWeek(new Date(startDate)))
      : moment(startDate);

  const endDateMoment = moment(endDate);

  while (startDateMoment.diff(endDateMoment) <= 0) {
    dynamicHeadings.push(startDateMoment.format('YYYY-MM-DD'));
    startDateMoment.add(1, addDatesFormat[groupBy]);
  }
  return dynamicHeadings;
};

export const getDataSetValues = (listProjectChargesTransform, service) => {
  const tmp = listProjectChargesTransform.find(
    (item) => item.service === service
  );
  const tmpValues = tmp && Object.values(tmp);
  return tmpValues ? tmpValues.filter((value) => value !== service) : [];
};

export const mergeObjects = (obj1, obj2) => {
  const merged = { ...obj1 };
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj2) {
    if (merged[key] === undefined || merged[key] === null || merged[key] === '')
      merged[key] = obj2[key];
  }
  return merged;
};

export const randomColor = () => {
  const code = Math.random().toString(16).slice(-6);
  return `#${code}`;
};

const getLabelColor = (label) => {
  if (label === 'Image Intelligence') {
    return '#FF54A6';
  }
  if (label === 'Link Intelligence') {
    return '#FF54A6';
  }
  if (label === 'Link Preview') {
    return '#EC8348';
  }
  if (label === 'Media Services') {
    return '#E0D03E';
  }
  if (label === 'Transcoding') {
    return '#3DAFD1';
  }
  if (label === 'File Conversion') {
    return '#3DD1B6';
  }

  return randomColor();
};

export const getDataSet = (projectCharges) => {
  const dataSet = [];

  projectCharges.forEach((v) => {
    const { service: label } = v;
    dataSet.push({
      label,
      backgroundColor: getLabelColor(label),
      data: [...getDataSetValues(projectCharges, label)],
    });
  });
  return dataSet;
};

export const getDashboardDataset = (data) => {
  const dsData = data.length > 0 ? Object.keys(data[0]).fill(0) : [];
  if (data.length > 0) {
    data
      .reverse()
      .forEach((o) =>
        Object.values(o).forEach((v, i) => (dsData[i] += Number(v)))
      );
  }
  return {
    label: '',
    fill: false,
    borderColor: '#00C472',
    borderWidth: 2,
    data: dsData.length > 0 ? dsData : [],
  };
};

export const getListProjectChargesTransform = (
  listProjectCharges,
  dynamicHeadings,
  groupBy
) => {
  const listProjectChargesTransform = [];
  listProjectCharges.forEach((item) => {
    const temp = {};
    const { service, period, total_cost: totalCost } = item;
    temp.service = service;
    dynamicHeadings.forEach((date) => {
      temp[moment(date).format(groupByFormats[groupBy])] =
        moment(date).format(groupByFormats[groupBy]) ===
        moment(period).format(groupByFormats[groupBy])
          ? `${totalCost}`
          : '';
    });
    const itemFound = listProjectChargesTransform.find(
      (v) => v.service === temp.service
    );
    if (itemFound) {
      listProjectChargesTransform[
        listProjectChargesTransform.indexOf(itemFound)
      ] = { ...mergeObjects(itemFound, temp) };
    } else {
      listProjectChargesTransform.push(temp);
    }
  });
  return listProjectChargesTransform;
};
export const getdashboardChartData = (
  dashboardChart,
  dynamicHeadings,
  groupBy
) => {
  const listDashboardChart = [];
  dashboardChart.forEach((item) => {
    const temp = {};
    const { created_at: createdAt, chargeable_time: chargeableTime } = item;
    dynamicHeadings.forEach((date) => {
      temp[moment(date).format(groupByFormats[groupBy])] =
        moment(date).format(groupByFormats[groupBy]) ===
        moment(createdAt).format(groupByFormats[groupBy])
          ? `${chargeableTime}`
          : '';
    });
    listDashboardChart.push(temp);
  });
  return listDashboardChart;
};

export const getTotalRow = (listProjectChargesTransform) => {
  const totalRow = { service: 'Total' };

  listProjectChargesTransform.forEach((v) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(v)) {
      if (key !== 'service') {
        if (totalRow[key]) {
          totalRow[key] += Number(value);
        } else {
          totalRow[key] = Number(value);
        }
      }
    }
  });
  return totalRow;
};

export const download = (content, fileName, contentType) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatDate = (date) => {
  const formattedDate = moment(date).format('MMMM Do YYYY');
  return formattedDate;
};

export const getAccessLevel = () => {
  const access = JSON.parse(localStorage.getItem('accessLevel'));
  return access.role;
};
