<template>
  <div>
    <cs-modal
      :show="showUpdateMediaLibraryAsset"
      class="ecs-edit-library-asset-modal"
      @close="onClose"
    >
      <div slot="header">Edit File</div>
      <div slot="body">
        <div class="ecs-edit-library-asset-modal__inputs">
          <cs-input
            v-model="name"
            placeholder="ex: Name"
            label="Name"
            required
          />
        </div>
        <div
          class="ecs-edit-library-asset-modal__inputs"
          @keydown.enter="onAddTag"
        >
          <cs-input
            v-model="tag_data"
            placeholder="ex: 'Owner = Janakan'"
            label="Tags"
          />
          <cs-button
            icon="cs-icons-add-filled"
            variant="primary"
            fill="outline"
            @click="onAddTag"
            >&nbsp;Add tag</cs-button
          >
          <div
            v-if="asset.tags"
            class="cs-textstyle-paragraph-small ecs-media-library-detail__tags"
          >
            <div
              v-for="tag in tags"
              :key="tag.key"
              class="ecs-media-library-detail__tags--tag"
            >
              <cs-tag>{{ tag.key | formatTag }}</cs-tag>
              <cs-tag
                :style="{
                  '--cs-tag-color': '#87939B',
                  '--cs-tag-texcolor': '#ffffff',
                }"
                >{{ tag.value | formatTag }}&nbsp;<i
                  class="cs-icons-close"
                  @click="onRemoveTag(tag.key)"
                ></i
              ></cs-tag>
            </div>
          </div>
        </div>
      </div>
      <cs-button
        slot="main"
        :disabled="!name"
        variant="primary"
        @click="updateMediaLibraryAsset"
        >Save Changes</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import UpdateMediaLibraryAsset from '@/gql/medialibrary/UpdateMediaLibraryAsset.gql';

export default {
  props: {
    showUpdateMediaLibraryAsset: {
      type: Boolean,
      required: true,
    },
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      tag_data: '',
      tags: [],
    };
  },
  watch: {
    showUpdateMediaLibraryAsset: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.name = this.asset.name;
          this.tags = [...this.asset.tags];
        }
      },
    },
  },
  methods: {
    onAddTag() {
      if (!this.tag_data) return;
      const [key, value] = this.tag_data.split('=').map((v) => v.trim());
      if (!key || !value) return;
      if (this.tags.find((tag) => tag.key === key)) {
        return;
      }
      this.tags.push({
        key,
        value,
      });
      this.tag_data = '';
    },
    onRemoveTag(key) {
      this.tags = this.tags.filter((tag) => tag.key !== key);
    },
    onClose() {
      this.$emit('close-update-media-library-asset');
    },
    formatTags(tags) {
      if (!tags.length) {
        return [];
      }
      const tagsData = [];
      tags.forEach((tag) => tagsData.push(`${tag.key}=${tag.value}`));
      return tagsData;
    },
    async updateMediaLibraryAsset() {
      await this.$apollo.mutate({
        mutation: UpdateMediaLibraryAsset,
        variables: {
          name: this.name,
          mediaLibraryAssetId: this.asset.id,
          tags: this.formatTags(this.tags),
        },
      });
      this.$emit('close-update-media-library-asset', true);
    },
  },
};
</script>

<style scoped>
.ecs-edit-library-asset-modal {
  ---cs-modal-width: 900px !important;
}
.ecs-edit-library-asset-modal__inputs {
  margin: 20px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
.ecs-edit-library-asset-modal__inputs .cs-button {
  position: absolute;
  right: 20px;
  top: 35px;
}
.ecs-media-library-detail__tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ecs-media-library-detail__tags--tag {
  display: flex;
  gap: 1px;
}

.ecs-media-library-detail__tags--tag >>> .cs-tag:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ecs-media-library-detail__tags--tag >>> .cs-tag:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
@media (max-width: 767px) {
  .ecs-edit-library-asset-modal__inputs .cs-button {
    top: 30px;
  }
}
</style>
