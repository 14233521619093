<template>
  <div>
    <cs-modal
      :show="showProjectModal"
      class="ecs-project__modal"
      @close="onClose"
    >
      <div slot="header" class="ecs-project__modal--header">
        {{ headerTitle }}
      </div>
      <div slot="body" class="ecs-project__modal--body">
        <cs-input
          v-if="!invoiceOnly"
          v-model="name"
          label="Name"
          type="text"
          required
        />
        <cs-input
          v-model="companyName"
          label="Company Name"
          type="text"
          required
        />
        <cs-input
          v-model="companyNationalTaxId"
          label="Company National Tax ID"
          type="text"
          required
        />
        <cs-textarea
          v-model="companyAddress"
          label="Company Address"
          type="text"
          required
        />
        <cs-textarea
          v-if="!invoiceOnly"
          v-model="description"
          label="Description"
          type="text"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        :disabled="!valid"
        @click="updateProjectSetting"
        >Save Changes</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import UpdateProject from '@/gql/project/UpdateProject.gql';
import $bus from '@/services/bus';

export default {
  props: {
    showProjectModal: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    invoiceOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      companyName: null,
      companyNationalTaxId: null,
      companyAddress: null,
    };
  },
  computed: {
    headerTitle() {
      return this.invoiceOnly ? 'Edit Invoice Info' : 'Edit Project';
    },
    valid() {
      return (
        !!this.name &&
        !!this.companyAddress &&
        !!this.companyAddress &&
        !!this.companyNationalTaxId
      );
    },
  },
  watch: {
    project: {
      immediate: true,
      handler(newVal) {
        this.name = newVal.name;
        this.companyAddress = newVal.company_address;
        this.companyName = newVal.company_name;
        this.description = newVal.description;
        this.companyNationalTaxId = newVal.company_national_tax_id;
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close-edit-project-modal');
    },
    async updateProjectSetting() {
      const values = {
        projectId: this.project.id,
        name: this.name,
        description: this.description,
        companyName: this.companyName,
        companyAddress: this.companyAddress,
        companyNationalTaxId: this.companyNationalTaxId,
      };
      const response = await this.$apollo.mutate({
        mutation: UpdateProject,
        variables: values,
      });
      const updatedProject = response.data.update_project;
      $bus.$emit('update-active-project', updatedProject);
      this.$emit('close-edit-project-modal');
    },
  },
};
</script>

<style scoped>
.ecs-project__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ecs-project__header span {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.ecs-icon {
  font-size: 24px;
  color: var(--cs-gray-04);
  cursor: pointer;
}
.ecs-project__modal {
  ---cs-modal-width: 450px !important;
}
.ecs-project__modal--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
* >>> .cs-card {
  margin: 0px !important;
}
.ecs-project__header--icon {
  font-size: 27px;
  margin-right: 15px;
  cursor: pointer;
}
</style>
