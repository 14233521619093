import jwt from 'jsonwebtoken';

const isLoggedIn = () => {
  if (!localStorage.access_token) return false;
  if (localStorage.access_token) {
    const token = localStorage.access_token;
    const secret = process.env.VUE_APP_JWT_SECRET;
    jwt.verify(token, secret, (err) => {
      if (err) {
        localStorage.clear();
        return false;
      }
      return true;
    });
  }
  return true;
};

const getUserId = () => {
  if (localStorage.access_token) {
    const token = localStorage.access_token;
    const decoded = jwt.decode(token);
    return decoded.id;
  }
  return null;
};

export default {
  isLoggedIn,
  getUserId,
};
