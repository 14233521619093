<template>
  <div>
    <cs-modal :show="showDelete" @close="onClose">
      <div slot="header">Delete Site</div>
      <div slot="body" class="ecs-ss" style="width: 100%">
        <div class="ecs-ss__header">
          Are you sure you want to delete "{{ item.name }}"?
        </div>
        <div class="ecs-ss__sub-header">
          The action can't be reversed. Type "{{ item.name }}" to confirm
        </div>

        <div class="ecs-ss__prompt">
          <cs-input
            v-model="name"
            label="Site Name"
            :placeholder="placeholder"
            required
            class="ecs-ss__wide-input"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="danger"
        :disabled="!valid"
        @click="deleteSite"
        >Confirm Delete</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import DeleteStaticSite from '@/gql/staticsite/DeleteStaticSite.gql';

export default {
  props: {
    showDelete: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    placeholder() {
      return `Type ${this.item.name} to confirm`;
    },
    valid() {
      return this.name === this.item.name;
    },
  },
  methods: {
    onClose(val) {
      this.name = '';
      this.$emit('close-delete-site-modal', val);
    },
    async deleteSite() {
      if (this.name === this.item.name) {
        await this.$apollo.mutate({
          mutation: DeleteStaticSite,
          variables: {
            deleteStaticSiteId: this.item.id,
          },
        });
        this.onClose(true);
      }
    },
  },
};
</script>

<style scoped>
.ecs-ss__header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-ss__sub-header {
  color: var(--cs-gray-04);
  margin: 5px 0px;
  font-size: 14px;
}
.ecs-ss__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-ss__wide-input {
  width: 100%;
}
* >>> .cs-input__label-text {
  font-weight: 600;
}
</style>
