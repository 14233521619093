<template>
  <div v-if="showAddUser">
    <cs-modal
      :show="showAddUser"
      class="ecs-users__modal"
      @close="closeAddUserModal"
    >
      <div slot="header" class="ecs-users__modal--header">Add User</div>
      <div slot="body" class="ecs-users__modal--body">
        <cs-input v-model="email" label="Email" type="text" required />
        <div v-if="!emailIsValid && email !== ''" class="ecs-error">
          Invalid email address
        </div>
        <div class="ecs-users__select">
          <div class="cs-textstyle-label-text ecs-users__select-label">
            Permission Level <span>*</span>
            <i
              id="infoIcon"
              class="cs-icons-info mx-1 ecs-icon"
              tabindex="-1"
            ></i>
            <!-- Info popover -->
            <b-popover
              ref="infoPopover"
              target="infoIcon"
              placement="rightbottom"
              fallback-placement="counterclockwise"
              triggers="hover"
              custom-class="bg-dark"
            >
              <div class="ecs-info">
                <div v-for="(i, index) in info" :key="index">
                  <div>
                    <span class="ecs-info__label">{{ i.label }}</span> -
                    {{ i.text }}
                  </div>
                </div>
              </div>
            </b-popover>
          </div>
          <cs-select
            v-model="selectedRole"
            :options="permissions"
            placeholder="Select Permission Level"
            class="ecs-users__select-component"
            required
          />
        </div>
      </div>
      <cs-button
        slot="main"
        variant="primary"
        :disabled="!valid"
        @click="addUser"
        >Add User</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import CreateProjectMember from '@/gql/user/CreateProjectMember.gql';

export default {
  props: {
    showAddUser: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      permissions: [
        {
          label: 'Admin',
          value: 'ADMIN',
        },
        {
          label: 'Developer',
          value: 'DEVELOPER',
        },
        {
          label: 'Viewer',
          value: 'VIEWER',
        },
      ],
      info: [
        {
          label: 'ADMIN',
          text: 'able to do anything in the account including delete projects, delete keys, manage users, and manage billing',
        },
        {
          label: 'DEVELOPER',
          text: 'able to do anything in the account except delete projects, delete keys, manage users, and manage billing',
        },
        {
          label: 'VIEWER',
          text: 'only able to view information (read-only access).',
        },
      ],
      selectedRole: '',
      email: '',
    };
  },
  computed: {
    valid() {
      const valid = this.emailIsValid ? this.email : '';
      return !!valid && !!this.selectedRole;
    },
    emailIsValid() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
    },
  },
  methods: {
    reset() {
      this.email = '';
      this.selectedRole = '';
    },
    closeAddUserModal() {
      this.reset();
      this.$emit('close-add-user');
    },
    async addUser() {
      try {
        const userInfo = {
          email: this.email,
          role: this.selectedRole,
          projectId: this.projectId,
        };
        await this.$apollo.mutate({
          mutation: CreateProjectMember,
          variables: userInfo,
        });
        this.reset();
        this.$emit('close-add-user', true);
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error',
          position: 'top',
          duration: 4000,
          dismissable: true,
          pauseOnHover: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.ecs-users__modal {
  ---cs-modal-width: 450px !important;
}
.ecs-users__modal--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.ecs-users__select-component {
  width: 100%;
}
.ecs-users__select-label {
  margin: 10px 0px;
}
.ecs-users__select-label span {
  color: var(--cs-danger-base);
}
.ecs-icon {
  color: var(--cs-gray-05);
  cursor: pointer;
}
.ecs-info {
  color: var(--cs-gray-00);
  font-size: 10px;
}
.ecs-info__label {
  color: var(--cs-info-base);
  font-weight: bold;
}
.ecs-error {
  font-size: 14px;
  color: var(--cs-danger-base);
}
</style>
