import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.access_token}`,
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPH_ENDPOINT,
  headers: getHeaders(),
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
