<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-transcoding-job" @close="onClose">
      <div slot="header">Transcode Video/Audio File</div>
      <div slot="body">
        <app-add-progress-bar :step="step" />
        <app-add-transcoding-job-select
          v-if="step === 1"
          @next-step="onGoToStep($event, 2)"
        ></app-add-transcoding-job-select>
        <app-add-transcoding-job-format
          v-if="step === 2"
          :data="modalData"
          @next-step="onGoToStep($event, 3)"
        ></app-add-transcoding-job-format>
        <app-add-transcoding-job-final
          v-if="step === 3"
          :data="modalData"
          @close-modal="updateList"
        ></app-add-transcoding-job-final>
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';
import AppAddTranscodingJobSelect from '@/components/transcoding/AddTranscodingJobSelect.vue';
import AppAddTranscodingJobFormat from '@/components/transcoding/AddTranscodingJobFormat.vue';
import AppAddTranscodingJobFinal from '@/components/transcoding/AddTranscodingJobFinal.vue';

export default {
  components: {
    AppAddProgressBar,
    AppAddTranscodingJobSelect,
    AppAddTranscodingJobFormat,
    AppAddTranscodingJobFinal,
  },
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      modalData: {},
    };
  },
  methods: {
    onGoToStep(e, step) {
      this.modalData = { ...this.modalData, ...e };
      this.step = step;
    },
    onClose() {
      this.step = 1;
      this.$emit('close-add-transcoding-job-modal');
    },
    updateList() {
      this.$emit('update-transcoding-jobs');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ecs-transcoding-job {
  ---cs-modal-max-height: 100vh !important;
}
</style>
