<template>
  <b-table
    :items="projectKeys"
    :fields="fields"
    :busy="isBusy"
    borderless
    hover
    striped
    :filter="filteredResult"
    table-variant="light"
    tbody-tr-class="ecs-dashboard__table-row"
  >
    <template #table-busy>
      <div class="ecs-dashboard__table-busy">
        <cs-spinner class="ecs-spinner" />
      </div>
    </template>
    <template v-slot:cell(name)="data">
      <span>{{ data.item.name }}</span>
    </template>
    <template v-slot:cell(id)="data">
      <span>{{ data.item.id }}</span>
    </template>
    <template v-slot:cell(created_at)="data">
      <span>{{ data.item.created_at | moment('from') }}</span>
    </template>
    <template v-slot:cell(role)="data">
      <span v-if="data.item.role">
        <cs-tag class="ecs-tag">{{ data.item.role }}</cs-tag>
      </span>
    </template>
    <template v-slot:cell(action)="data">
      <i
        v-if="isAdmin"
        :id="`showPopover${data.index}`"
        class="cs-icons-options ecs-dashboard__table-body"
        tabindex="-1"
      >
      </i>
      <b-popover
        ref="actionPopover"
        :target="`showPopover${data.index}`"
        triggers="click blur"
        custom-class="bg-dark"
        placement="leftbottom"
      >
        <div class="ecs-dashboard__popover">
          <div @click="showEditKeyModal(data.item)">Edit Key</div>
          <div @click="showDeleteKeyModal(data.item)">Remove Key</div>
        </div>
      </b-popover>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    projectKeys: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      required: false,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Key Name',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'id',
          label: 'Key Id',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'role',
          label: 'Key Permissions',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Created at',
          thClass: 'ecs-dashboard__table-head',
        },
        // last_access not added
        { key: 'action', label: '', thClass: 'ecs-dashboard__table-head' },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.query) {
        return this.query;
      }
      return '';
    },
  },
  methods: {
    showEditKeyModal(item) {
      this.$root.$emit('bv::hide::popover');
      this.$emit('show-edit-key-modal', item);
    },
    showDeleteKeyModal(item) {
      this.$root.$emit('bv::hide::popover');
      this.$emit('show-delete-modal', item);
    },
  },
};
</script>
