<template>
  <div class="ecs-dashboard">
    <div class="ecs-project__header">
      <div>
        <span>PROJECT</span>
        <h3 v-if="project">{{ project.name }}</h3>
      </div>
      <div>
        <cs-button
          variant="secondary"
          fill="clear"
          class="ecs-dashboard__settings"
          :disabled="role !== 'ADMIN'"
        >
          <i
            id="deleteProject"
            class="cs-icons-settings-filled ecs-project__header--icon"
          />
        </cs-button>

        <b-popover
          ref="actionPopover"
          target="deleteProject"
          triggers="click blur"
          custom-class="bg-dark"
          placement="bottom"
        >
          <div class="ecs-dashboard__popover">
            <div @click.stop="deleteProject">Delete Project</div>
          </div>
        </b-popover>
        <cs-button :disabled="disable" @click="openProjectSettingModal"
          >Edit Project</cs-button
        >
      </div>
    </div>
    <cs-card v-if="project">
      <div slot="body">
        <p>ID: {{ project.id }}</p>
        <p>Name: {{ project.name }}</p>
        <p>Description: {{ project.description }}</p>
      </div>
    </cs-card>
    <!-- edit project modal  -->
    <app-edit-project-modal
      :show-project-modal="showEditProjectModal"
      :project="project"
      @close-edit-project-modal="closeEditProjectModal"
    />
    <!-- delete project modal  -->
    <app-delete-project-modal
      :show-delete-project-modal="showDeleteProjectModal"
      :project="project"
      @close-delete-project-modal="closeDeleteProjectModal"
    />
  </div>
</template>

<script>
// GQL
import GetProject from '@/gql/project/GetProject.gql';
import $bus from '@/services/bus';
// Components
import AppDeleteProjectModal from '@/components/project/DeleteProjectModal.vue';
import AppEditProjectModal from '@/components/project/EditProjectModal.vue';
import { getAccessLevel } from '@/services/utils';

export default {
  components: {
    AppDeleteProjectModal,
    AppEditProjectModal,
  },
  data() {
    return {
      showDeleteProjectModal: false,
      showEditProjectModal: false,
      project: {},
      disable: null,
      role: null,
    };
  },
  apollo: {
    project: {
      query: GetProject,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    // Opens project delete modal
    deleteProject() {
      this.$refs.actionPopover.$emit('close');
      this.showDeleteProjectModal = true;
    },
    // Closes project delete modal
    closeDeleteProjectModal() {
      this.showDeleteProjectModal = false;
    },
    // Opens project edit modal
    openProjectSettingModal() {
      this.showEditProjectModal = true;
    },
    // Closes project edit modal
    closeEditProjectModal() {
      this.showEditProjectModal = false;
    },
  },
};
</script>

<style scoped>
.ecs-project__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ecs-project__header span {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.ecs-icon {
  font-size: 24px;
  color: var(--cs-gray-04);
  cursor: pointer;
}
.ecs-project__modal {
  ---cs-modal-width: 450px !important;
}
.ecs-project__modal--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
* >>> .cs-card {
  margin: 0px !important;
}
.ecs-project__header--icon {
  font-size: 27px;
  margin-right: 15px;
  cursor: pointer;
}
.ecs-dashboard__settings {
  --cs-button-padding: 7px 0 !important;
}
</style>
