<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Connect your source cloud provider and select the image you want to
      analyze.
    </div>
    <div class="cs-textstyle-informative-paragraph-bold my-2">
      Select Source
    </div>
    <div class="ecs-image-insights__list">
      <div v-for="(item, index) in items" :key="index">
        <div
          :class="active === index ? 'ecs-image-insights__active-items' : ''"
          class="ecs-image-insights__non-active-items"
          @click="toggleSource(index, item)"
        >
          <div>
            <span v-if="!item.img">{{ item.text }}</span
            ><img
              v-else
              :src="item.img"
              :class="item.text === 'aws' ? 'ecs-image-insights__aws-img' : ''"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ecs-image-insights__input-wrapper my-2">
      <div v-if="active === 0" class="ecs-image-insights__input-wrapper--url">
        <cs-input
          v-model="sourceUrl"
          label="Enter URL"
          class="ecs-image-insights__input-wrapper-inputs"
          required
        />
      </div>
      <div v-else class="ecs-image-insights__input-wrapper--secret-keys">
        <div class="ecs-image-insights__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="bucketUrl"
            label="Bucket Name"
            class="ecs-image-insights__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="bucketPath"
            label="Bucket Path"
            class="ecs-image-insights__input-wrapper-inputs"
            required
          />
        </div>
        <div class="ecs-image-insights__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="accessKeyId"
            label="Access Key ID"
            class="ecs-image-insights__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="secretAccessKey"
            label="Secret Access Key"
            class="ecs-image-insights__input-wrapper-inputs"
            required
          />
        </div>
      </div>
    </div>
    <cs-button class="ecs-button" :disabled="!valid" @click="nextStep">
      Continue
    </cs-button>
  </div>
</template>

<script>
const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');

export default {
  data() {
    return {
      active: 0,
      items: [
        {
          text: 'URL',
          path: 'aws',
        },
        {
          text: 'aws',
          path: 'aws',
          img: awsImgUrl,
        },
        {
          text: 'gcp',
          path: 'gcp',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          path: 'digitalocean',
          img: doImgUrl,
        },
        {
          text: 'azure',
          path: 'azure',
          img: azureImgUrl,
        },
      ],
      sourceUrl: null,
      bucketUrl: null,
      accessKeyId: null,
      bucketPath: null,
      secretAccessKey: null,
    };
  },
  computed: {
    valid() {
      if (this.active === 0) {
        return !!this.sourceUrl;
      }
      if (this.active !== 0) {
        return (
          !!this.accessKeyId &&
          !!this.secretAccessKey &&
          !!this.bucketUrl &&
          !!this.bucketPath
        );
      }
      return false;
    },
  },
  methods: {
    toggleSource(i) {
      this.active = i;
    },
    nextStep() {
      if (this.active === 0) {
        this.$emit('next-step', {
          sourceUrl: this.sourceUrl,
          sourceProvider: 'URL',
        });
      } else {
        this.$emit('next-step', {
          sourceUrl: this.sourceUrl,
          sourceProvider: this.items[this.active].sourceProvider.toUpperCase(),
          sourceBucketName: this.bucketUrl,
          sourceBucketPath: this.bucketPath,
          sourceAccessKeyId: this.accessKeyId,
          sourceSecretAccessKey: this.secretAccessKey,
        });
      }
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 16px;
}
.ecs-button {
  float: right;
}
.ecs-image-insights__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-image-insights__active-items i {
  color: var(--cs-gray-06);
}
.ecs-image-insights__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 113px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-image-insights__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-image-insights__non-active-items img {
  width: 80px;
}
.ecs-image-insights__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.ecs-image-insights__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-image-insights__input-wrapper {
  background: var(--cs-gray-01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}
.ecs-image-insights__input-wrapper-inputs {
  width: 100%;
}
.ecs-image-insights__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-image-insights__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-image-insights__settings-input {
  width: 80%;
}
.ecs-image-insights__aws-img {
  padding-left: 5px;
  width: 40px !important;
}
</style>
