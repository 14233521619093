<template>
  <div>
    <cs-modal :show="showCreateKey" @close="onClose">
      <div slot="header">{{ header }}</div>
      <div slot="body" class="ecs-key">
        <app-create-key-form v-if="showForm" @show-created-key="showCreated" />
        <app-created-key-detail
          v-if="showCreatedView"
          :key-property="keyProperty"
          @close-created-key-detail="closeCreatedKeyDetail"
        />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppCreateKeyForm from '@/components/keymanagement/CreateKeyForm.vue';
import AppCreatedKeyDetail from '@/components/keymanagement/CreatedKeyDetail.vue';

export default {
  components: {
    AppCreateKeyForm,
    AppCreatedKeyDetail,
  },
  props: {
    showCreateKey: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showForm: true,
      showCreatedView: false,
      header: 'Create Key',
      keyProperty: null,
    };
  },
  methods: {
    // Closes the create key modal
    onClose() {
      this.showForm = true;
      this.header = 'Create Key';
      this.showCreatedView = false;
      this.$emit('close-create-key');
    },
    // Change the view from create key form to created key detail view
    showCreated(keyProperty) {
      this.keyProperty = keyProperty;
      this.showCreatedView = true;
      this.showForm = false;
      this.header = 'Key Secret';
    },
    // Closes the create key modal
    closeCreatedKeyDetail() {
      this.keyProperty = null;
      this.showCreatedView = false;
      this.header = 'Create Key';
      this.$emit('close-create-key');
      this.showForm = true;
    },
  },
};
</script>

<style scoped>
.ecs-key {
  width: 100%;
}
</style>
