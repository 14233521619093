<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div v-if="get_static_site_build" class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Static Site /
        <span> {{ get_static_site_build.static_site.name }} / </span>
        <span
          >{{ get_static_site_build.static_site.repo_branch
          }}<span v-if="commitId">@</span>{{ commitId }}</span
        >
      </div>
    </div>
    <!-- Info -->
    <div v-if="get_static_site_build" class="ecs-ss-build-detail__info">
      <!-- Branch Name -->
      <div
        v-if="get_static_site_build.static_site.repo_branch"
        class="ecs-ss-build-detail__info-content"
      >
        <div class="cs-textstyle-paragraph-small-bold">Branch Name</div>
        <div class="cs-textstyle-paragraph-small">
          {{ get_static_site_build.static_site.repo_branch }}
        </div>
      </div>

      <!-- Start Time -->
      <div
        v-if="get_static_site_build.start_time"
        class="ecs-ss-build-detail__info-content"
      >
        <div class="cs-textstyle-paragraph-small-bold">Start Time</div>
        <div class="cs-textstyle-paragraph-small">
          {{
            get_static_site_build.start_time | moment('DD MMM YYYY, hh:mm A')
          }}
          (UTC)
        </div>
      </div>
      <!-- Duration -->
      <div v-if="duration" class="ecs-ss-build-detail__info-content">
        <div class="cs-textstyle-paragraph-small-bold">Duration</div>
        <div class="cs-textstyle-paragraph-small">{{ duration }}</div>
      </div>
      <!-- Status -->
      <div
        v-if="get_static_site_build.status"
        class="ecs-ss-build-detail__info-content"
      >
        <div class="cs-textstyle-paragraph-small-bold">Status</div>
        <div class="cs-textstyle-paragraph-small">
          <cs-tag :class="['ecs-tag__' + get_static_site_build.status]">{{
            get_static_site_build.status
          }}</cs-tag>
        </div>
      </div>
      <!-- Git Commit -->
      <div v-if="commitId" class="ecs-ss-build-detail__info-content">
        <div class="cs-textstyle-paragraph-small-bold">Git Commit</div>
        <div class="cs-textstyle-paragraph-small">
          {{ commitId }}
        </div>
      </div>
    </div>
    <!-- Cards -->
    <div v-if="get_static_site_build" class="ecs-ss-build-detail__extras">
      <div class="ecs-ss-build-detail__extras-header">
        <div class="cs-textstyle-item-heading">Deploy Log</div>
      </div>
    </div>
    <div v-if="logs && logs.length" class="ecs-ss-build-detail__extras-content">
      <div class="ecs-ss-build-detail-timeline">
        <div class="outer">
          <div
            v-for="(log, i) in logs"
            :key="i"
            class="ecs-ss-build-detail-timeline-item"
          >
            <span class="ecs-ss-build-detail-timeline-number">{{ i + 1 }}</span>
            <div
              class="cs-textstyle-paragraph-small ecs-ss-build-detail-timeline-item-content"
            >
              <span v-if="log.date">{{ log.date | moment('h:mm:ss A') }}: </span
              ><span v-if="log.message">{{ log.message }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$apollo.queries.get_static_site_build.loading"
      class="ecs-spinner__wrapper"
    >
      <cs-spinner class="ecs-spinner" />
    </div>
  </div>
</template>

<script>
import GetStaticSiteBuild from '@/gql/staticsite/GetStaticSiteBuild.gql';
import { convertSecToMinSec } from '@/services/utils';

export default {
  data() {
    return {};
  },
  apollo: {
    get_static_site_build: {
      query: GetStaticSiteBuild,
      variables() {
        return {
          getStaticSiteBuildId: this.$route.params.buildid,
        };
      },
    },
  },
  computed: {
    commitId() {
      return this.get_static_site_build.commit_url
        ? this.get_static_site_build.commit_url.substring(
            this.get_static_site_build.commit_url.lastIndexOf('/') + 1
          )
        : '';
    },
    logs() {
      const data = [];
      if (this.get_static_site_build && this.get_static_site_build.logs) {
        this.get_static_site_build.logs.split('\n').forEach((log) => {
          if (log) {
            data.push({
              date: Number(log.split(' ')[0]),
              message: log.split(' ').slice(1).join(' '),
            });
          }
        });
        return data;
      }
      return [];
    },
    duration() {
      return convertSecToMinSec(this.get_static_site_build.duration);
    },
  },
};
</script>

<style scoped>
.ecs-tag__COMPLETED {
  --cs-tag-color: var(--cs-primary-base) !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}
.ecs-tag__FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}

.ecs-ss-build-detail__info {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: flex-start;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
  gap: 32px;
}
.ecs-ss-build-detail__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 300px;
}
.ecs-ss-build-detail__extras {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ecs-ss-build-detail__extras-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-ss-build-detail__extras-content {
  flex: 1;
  background-color: var(--cs-gray-07) !important;
  color: var(--cs-gray-03) !important;
  overflow: auto;
  padding-bottom: 75px;
}
/* ecs-ss-build-detail-Timeline Container */
.ecs-ss-build-detail-timeline {
  padding: 32px;
  overflow: auto;
  position: relative;
}
/* Outer Layer with the ecs-ss-build-detail-timeline border */
.outer {
  border-left: 1px solid var(--cs-gray-04);
  padding-top: 20px;
  padding-bottom: 10px;
}
.ecs-ss-build-detail-timeline-item {
  margin-bottom: 7px;
}
.ecs-ss-build-detail-timeline-item-content {
  margin-left: 21px;
  color: var(--cs-gray-03);
}
.ecs-ss-build-detail-timeline-number {
  position: absolute;
  color: var(--cs-gray-00);
  font-size: 8px;
  border-radius: 50%;
  background-color: var(--cs-gray-05);
  margin-left: -8px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
</style>
