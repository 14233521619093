<template>
  <div class="ecs-key__empty-state">
    <cs-empty-state>
      <template v-slot:title>
        <div>
          <img :src="icon" />
          <div class="mt-3">{{ description }}</div>
          <cs-button
            v-if="showButton"
            class="mt-3"
            :disabled="disable"
            @click="$emit('add-action')"
          >
            {{ buttonText }}
          </cs-button>
        </div>
      </template>
    </cs-empty-state>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Add Item',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.ecs-key__empty-state {
  background-color: var(--cs-gray-01) !important;
  margin-top: 20px !important;
  height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cs-gray-03);
  border-radius: 5px;
}
</style>
