<template>
  <div>
    <cs-modal :show="showPay" @close="onClose">
      <div slot="header">Choose Card</div>
      <div slot="body" class="ecs-payment-modal">
        <div v-if="!$apollo.queries.customer.loading">
          <div v-if="paymentMethodList && paymentMethodList.length > 0">
            <div
              v-for="(item, index) in paymentMethodList"
              :key="item.id"
              class="ecs-payment-modal__cardlist__items"
              :class="{
                'ecs-payment-modal__cardlist__items-active': index === active,
              }"
              @click="toggleCard(index, item)"
            >
              <div class="ecs-payment-modal__cardlist__items--placement">
                <img
                  :src="brandImg(item.card.brand)"
                  class="ecs-payment-modal__card-img"
                />
                <div class="placement">
                  <div>
                    {{ item.card.brand }} ending in {{ item.card.last4 }}
                  </div>
                  <div
                    class="ecs-payment-modal__cardlist__items--placement__expires"
                  >
                    Expires {{ item.card.exp_month }}/{{ item.card.exp_year }}
                  </div>
                </div>
              </div>
              <div class="ecs-payment-modal__cardlist__items--placement">
                <cs-radio :checked="active === index" value="" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="paymentMethodList && paymentMethodList.length < 1">
          <cs-empty-state
            title="No card added"
            description="Click on manage card to add a card"
          />
        </div>
      </div>
      <cs-button
        slot="main"
        :disabled="
          loading || (paymentMethodList && paymentMethodList.length < 1)
        "
        @click="pay"
        >Pay Now</cs-button
      >
      <cs-button slot="fallback" fill="outline" :to="{ name: 'PaymentMethods' }"
        >Manage Cards</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import FetchCustomer from '@/gql/paymentMethods/FetchCustomer.gql';
import CreatePaymentIntent from '@/gql/paymentMethods/CreatePaymentIntent.gql';
import PaymentUnderProgress from '@/gql/paymentMethods/PaymentUnderProgress.gql';
import { loadStripe } from '@stripe/stripe-js';

export default {
  apollo: {
    customer: {
      query: FetchCustomer,
      variables() {
        return {
          projectId: this.$route.params.id,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  props: {
    showPay: {
      type: Boolean,
      required: true,
    },
    invoiceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      card: {},
      stripe: null,
      active: 0,
      loading: false,
      customer: {
        payment_methods: null,
      },
    };
  },
  computed: {
    paymentMethodList() {
      let defaultMethod = {};
      const methodList = [];
      if (!this.$apollo.queries.customer.loading) {
        if (this.customer.payment_methods.length > 0) {
          // eslint-disable-next-line array-callback-return
          this.customer.payment_methods.map((data) => {
            if (
              this.customer.invoice_settings.default_payment_method === data.id
            ) {
              this.card = data;
              defaultMethod = {
                ...data,
                isDefault: true,
              };
            } else methodList.push(data);
          });
          return [defaultMethod, ...methodList];
        }
        return [];
      }
      return [];
    },
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
  },
  methods: {
    brandImg(brand) {
      switch (brand) {
        case 'visa':
          return 'https://usa.visa.com/dam/VCOM/blogs/visa-blue-gradient-800x450.jpg';
        case 'mastercard':
          return 'https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg';
        case 'amex':
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/601px-American_Express_logo_%282018%29.svg.png?20191022102801';
        case 'jcb':
          return 'http://www.jcbeurope.eu/about/emblem_slogan/images/index/logo_img01.jpg';
        case 'unionpay':
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/UnionPay_logo.svg/120px-UnionPay_logo.svg.png?20180128134319';
        case 'discover':
          return 'https://seeklogo.com/images/D/Discover_Card-logo-4BC5D7C02C-seeklogo.com.png';
        case 'diners':
          return 'https://i.pinimg.com/736x/dd/e9/b3/dde9b3ac8f3ae9dde573e3607c36f1a1.jpg';
        default:
          return 'https://img.freepik.com/free-vector/illustration-credit-card-icon_53876-5915.jpg';
      }
    },
    onClose() {
      this.name = '';
      this.$emit('close-pay-invoice-modal');
    },
    toggleCard(i, item) {
      this.active = i;
      this.card = item;
    },
    async pay() {
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: CreatePaymentIntent,
          variables: {
            paymentMethodId: this.card.id,
            invoiceId: this.invoiceId,
          },
        });
        const secret = response.data.stripe_payment_intent;

        const { error } = await this.stripe.confirmCardPayment(secret, {
          payment_method: this.card.id,
        });
        if (error) {
          this.$toast.open({
            message: `${error}`,
            type: 'error',
            duration: 3000,
            dismissible: true,
            position: 'top',
          });
          this.loading = false;
          return;
        }
        this.$toast.open({
          message: 'Processing',
          type: 'success',
          duration: 3000,
          dismissible: true,
          position: 'top',
        });
        await this.$apollo.mutate({
          mutation: PaymentUnderProgress,
          variables: {
            invoiceId: this.invoiceId,
          },
        });
        this.loading = false;
        this.$emit('payment-done');
      } catch (error) {
        this.$toast.open({
          message: `${error}`,
          type: 'error',
          duration: 3000,
          dismissible: true,
          position: 'top',
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.ecs-payment-modal {
  margin-top: 16px;
  border: 1px solid var(--cs-gray-02);
}
.ecs-payment-modal__cardlist__items {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--cs-gray-02);
  background: var(--cs-gray-01);
}
.ecs-payment-modal__cardlist__items-active {
  border: 1px solid var(--cs-primary-base) !important;
  background: var(--cs-primary-lightest);
}
.ecs-payment-modal__cardlist__items--placement {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  text-align: center;
  align-items: center;
  gap: 30px;
}
.ecs-payment-modal__cardlist__items--placement img {
  width: 60px;
  height: 35px;
}
.ecs-payment-modal__cardlist__items--placement i {
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}
.ecs-payment-modal__cardlist__items--placement__expires {
  justify-self: flex-end;
}
.ecs-payment-modal__cardlist__items--placement_tag {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.placement {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 400px;
}
.ecs-payment-modal__card-img {
  object-fit: cover;
}
</style>
