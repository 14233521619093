<template>
  <div v-if="url">
    <video class="captions-video" controls>
      <source :src="url" type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  </div>
</template>

<script>
import GetSignedUrl from '@/gql/caption/GetSignedUrl.gql';
export default {
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    url: {
      query: GetSignedUrl,
      variables() {
        return {
          bucketPath: this.job.source_bucket_path,
          bucketName: this.job.source_bucket_name,
          secretKey: this.job.source_secret_key,
          accessKey: this.job.source_access_key,
          provider: this.job.destination_provider,
        };
      },
    },
  },
};
</script>

<style scoped>
.captions-video {
  width: 100%;
}
</style>
