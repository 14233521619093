<template>
  <div>
    <div class="cs-textstyle-paragraph-small-bold">
      You are connected to {{ $route.query.provider }}
    </div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Choose the repository you want to link to your site.
    </div>
    <div class="ecs-modal-content">
      <div class="ecs-modal-content__label">Repo</div>
      <div class="ecs-spinner__wrapper">
        <cs-spinner v-if="!repos.length" class="spinner" />
      </div>
      <cs-select-search
        placeholder="Select Repo"
        :options="repos"
        icon="cs-icons-expand-arrow"
        @select="repoSelect"
      />
      <div class="ecs-modal-content__label">Branch</div>
      <cs-input
        v-model="branch"
        class="ecs-modal-content__input"
        placeholder="Branch"
      />
    </div>
    <cs-button
      class="ecs-button"
      :disabled="!selectedRepo || !branch"
      @click="nextStep"
    >
      Continue
    </cs-button>
  </div>
</template>

<script>
import ListRepos from '@/gql/staticsite/ListRepos.gql';

export default {
  apollo: {
    list_repos: {
      query: ListRepos,
      variables() {
        return {
          git_account_id: this.$route.query.git_account,
        };
      },
      skip() {
        return !this.$route.query.git_account;
      },
      result({ data }) {
        this.repos = data.list_repos.map((repo) => ({
          label: repo,
          value: repo,
        }));
      },
    },
  },
  data() {
    return {
      repos: [],
      selectedRepo: '',
      branch: '',
    };
  },
  mounted() {
    if (!this.$route.query.git_account) {
      this.$router.push({
        name: 'StaticSiteList',
        query: {
          add_step: 1,
        },
      });
    }
  },
  methods: {
    nextStep() {
      this.$router.push({
        name: 'StaticSiteList',
        query: {
          ...this.$route.query,
          add_step: 3,
          repo: this.selectedRepo,
          branch: this.branch,
        },
      });
    },
    repoSelect(repo) {
      this.selectedRepo = repo.value;
    },
  },
};
</script>

<style scoped>
.ecs-modal-content {
  min-height: 200px;
  margin: 16px 0;
}
.ecs-modal-description {
  margin-top: 5px;
}
.ecs-modal-content__label {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 8px 0;
}
.ecs-button {
  float: right;
}
.ecs-modal-content__input {
  width: 100%;
}
.ecs-spinner__wrapper {
  position: relative;
}
.spinner {
  position: absolute;
  left: 45%;
}
.cs-select-search
  >>> .cs-select-search__results-wrapper
  .cs-select-search__results {
  max-height: 171px;
  z-index: 10;
}
</style>
