<template lang="html">
  <img v-bind="$attrs" :src="src" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: false,
      default: '',
    },
    original: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
    altImg: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
