<template>
  <div class="ecs-image-insights-detail__file">
    <div
      class="cs-textstyle-paragraph-bold ecs-image-insights-detail__file--heading"
    >
      {{ headingText }}
    </div>
    <div class="ecs-image-insights-detail__file__content">
      <div>
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">{{ fileLabel }}</div>
          <div class="cs-textstyle-paragraph-small">
            <app-hover :index="`${Date.now()}`">
              <app-truncated-text
                slot="visible"
                :max-chars="100"
                :long-text="file"
              />
              <div slot="popover">{{ file }}</div>
            </app-hover>
          </div>
        </div>
      </div>
      <div>
        <!-- TODO Make Download icon functional -->
        <div class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Action</div>
          <div
            class="cs-textstyle-paragraph-small ecs-image-insights-detail__icon--center"
          >
            <i class="cs-icons-download"></i>
          </div>
        </div>
        <div v-if="size" class="ecs-image-insights-detail__info-content">
          <div class="cs-textstyle-paragraph-small-bold">Size</div>
          <div v-if="size" class="cs-textstyle-paragraph-small">
            {{ formatBytes(size) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppHover from '@/components/general/Hover.vue';
import { formatBytes } from '@/services/utils';

export default {
  components: {
    AppTruncatedText,
    AppHover,
  },
  props: {
    headingText: {
      type: String,
      required: true,
    },
    fileLabel: {
      type: String,
      default: 'File',
    },
    file: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formatBytes,
    };
  },
};
</script>

<style scoped>
.ecs-image-insights-detail__file {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
.ecs-image-insights-detail__file--heading {
  color: var(--cs-primary-base);
}
.ecs-image-insights-detail__file__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-00);
  padding: 15px 20px;
}
.ecs-image-insights-detail__file__content > div {
  display: flex;
  gap: 40px;
}
.ecs-image-insights-detail__icon--center {
  text-align: center;
}
</style>
