<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Select the output format of your source file, and enter webhook url for
      notification.
    </div>
    <div class="cs-textstyle-informative-paragraph-bold">Convert Settings</div>
    <div class="d-flex flex-column">
      <cs-select
        v-model="selectedInput"
        class="my-3"
        :options="options"
        label="Input Format"
        @select="onSelect"
      />
      <cs-select
        v-model="selectedOutput"
        class="my-3"
        :options="outputOptions"
        label="Output Format"
        @select="onSelectOutput"
      />
      <cs-input
        v-model="webhookUrl"
        label="Webhook Url (Optional)"
        class="ecs-caption__input-wrapper-inputs mb-2"
      />
      <cs-button
        class="ecs-button"
        :disabled="!selectedInputNotNull"
        @click="nextStep"
      >
        Continue
      </cs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedInput: null,
      selectedOutput: null,
      outputOptions: [],
      options: [
        {
          label: 'PDF',
          value: 'pdf',
        },
        {
          label: 'DOCX/DOC',
          value: 'docx',
        },
        {
          label: 'JPG',
          value: 'jpg',
        },
        {
          label: 'PNG',
          value: 'png',
        },
        {
          label: 'PPT',
          value: 'ppt',
        },
        {
          label: 'CSV',
          value: 'csv',
        },
        {
          label: 'XLS',
          value: 'xls',
        },
      ],
      webhookUrl: null,
    };
  },
  computed: {
    selectedInputNotNull() {
      return !!this.selectedOutput;
    },
  },
  methods: {
    onSelect(data) {
      if (data && data.value) {
        this.selectedInput = data;
        switch (data.value) {
          case 'pdf':
            this.outputOptions = [
              {
                label: 'DOCX/DOC',
                value: 'docx',
              },
              {
                label: 'JPG',
                value: 'jpg',
              },
              {
                label: 'PNG',
                value: 'png',
              },
              {
                label: 'TXT',
                value: 'txt',
              },
              {
                label: 'PPT',
                value: 'ppt',
              },
            ];
            break;
          case 'docx':
            this.outputOptions = [
              {
                label: 'PDF',
                value: 'pdf',
              },
              {
                label: 'JPG',
                value: 'jpg',
              },
              {
                label: 'PNG',
                value: 'png',
              },
              {
                label: 'TXT',
                value: 'txt',
              },
            ];
            break;
          case 'jpg':
            this.outputOptions = [
              {
                label: 'WEBP',
                value: 'webp',
              },
              {
                label: 'PNG',
                value: 'png',
              },
              {
                label: 'PDF',
                value: 'pdf',
              },
            ];
            break;
          case 'png':
            this.outputOptions = [
              {
                label: 'WEBP',
                value: 'webp',
              },
              {
                label: 'JPG',
                value: 'jpg',
              },
              {
                label: 'PDF',
                value: 'pdf',
              },
            ];
            break;
          case 'ppt':
            this.outputOptions = [
              {
                label: 'TXT',
                value: 'txt',
              },
              {
                label: 'JPG',
                value: 'jpg',
              },
              {
                label: 'PNG',
                value: 'png',
              },
              {
                label: 'PDF',
                value: 'pdf',
              },
            ];
            break;
          case 'csv':
            this.outputOptions = [
              {
                label: 'PDF',
                value: 'pdf',
              },
            ];
            break;
          case 'xls':
            this.outputOptions = [
              {
                label: 'PDF',
                value: 'pdf',
              },
            ];
            break;
          default:
            this.outputOptions = [];
        }
      }
    },
    onSelectOutput(data) {
      if (data && data.value) {
        this.selectedOutput = data;
      }
    },
    nextStep() {
      this.$emit('next-step', {
        output: this.selectedOutput,
        webhookUrl: this.webhookUrl,
      });
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 16px;
}
.ecs-button {
  display: flex;
  align-self: flex-end;
}
</style>
