<template>
  <div class="ecs-topbar">
    <app-search :last-active-project="lastActiveProject" />
    <div class="ecs-topbar__right">
      <a target="_blank" :href="docsUrl">
        <i class="cs-icons-help"></i>
      </a>
      <app-user-avatar :name="username" :profile-picture="user.picture" />
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import GetUser from '@/gql/user/GetUser.gql';
import AppSearch from '@/components/topbar/Search.vue';
import AppUserAvatar from '@/components/topbar/UserAvatar.vue';

export default {
  components: {
    AppUserAvatar,
    AppSearch,
  },
  props: {
    lastActiveProject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          getUserId: $auth.getUserId(),
        };
      },
    },
  },
  data() {
    return {
      search: '',
      user: {
        first_name: '',
        last_name: '',
        picture: '',
      },
    };
  },
  computed: {
    docsUrl() {
      return process.env.VUE_APP_DOCS;
    },
    username() {
      return this.user && `${this.user.first_name} ${this.user.last_name}`;
    },
  },
};
</script>

<style scoped>
.ecs-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--cs-gray-00);
  border-bottom: 1px solid var(--cs-gray-02);
}
.ecs-topbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
  padding-right: 20px;
  font-weight: 700;
}
.ecs-topbar__right i {
  font-size: 24px;
  padding: 5px 10px 0px 5px;
  color: var(--cs-gray-04);
}
</style>
