<template>
  <div class="ecs-key">
    <div class="ecs-key__name">
      <cs-input
        v-model="name"
        label="Name"
        placeholder="ex: Bitbucket Deployment key"
        required
        class="ecs-key__wide-input"
      />
    </div>
    <div class="ecs-key__description">
      <cs-textarea
        v-model="description"
        label="Description"
        placeholder="My key for deployment in bitbucket"
        class="ecs-key__wide-input"
      />
    </div>
    <div class="ecs-key__select">
      <div class="cs-textstyle-label-text ecs-key__select-label">
        Permission Level <span>*</span>
        <i class="cs-icons-info mx-1 ecs-icon"></i>
      </div>
      <cs-select
        placeholder="Select"
        :options="options"
        icon="cs-icons-expand-arrow-filled"
        class="ecs-key__wide-input"
        @select="onSelect"
      >
      </cs-select>
    </div>
    <div class="ecs-key__button">
      <cs-button variant="primary" :disabled="!valid" @click="createKey">
        Create Key
      </cs-button>
    </div>
  </div>
</template>

<script>
import CreateProjectKey from '@/gql/projectKey/CreateProjectKey.gql';

export default {
  data() {
    return {
      name: '',
      description: '',
      options: [
        { value: 'readonly', label: 'Read Only' },
        { value: 'readwrite', label: 'Read & Write' },
      ],
      selected: null,
    };
  },
  computed: {
    valid() {
      return !!this.name && !!this.selected;
    },
  },
  methods: {
    // Closes the form view and moves to the created key detail view
    async createKey() {
      if (this.valid) {
        const { data } = await this.$apollo.mutate({
          mutation: CreateProjectKey,
          variables: {
            description: this.description,
            name: this.name,
            role: this.selected,
            project_id: this.$route.params.id,
          },
        });
        const keyProperty = data.create_project_key;
        this.$emit('show-created-key', keyProperty);
      }
    },
    onSelect(i) {
      this.selected = i.value;
    },
  },
};
</script>

<style scoped>
.ecs-key {
  display: flex;
  flex-direction: column;
}
.ecs-key__name {
  margin-top: 20px;
}
.ecs-key__description {
  margin: 20px 0px;
}
.ecs-key__button {
  margin-top: 20px;
  align-self: flex-end;
}
.ecs-key__wide-input {
  width: 100%;
}
.ecs-key__select-label {
  margin-bottom: 10px;
}
.ecs-key__select-label span {
  color: var(--cs-danger-base);
}
.ecs-icon {
  color: var(--cs-gray-05);
}
</style>
