<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Static Site / <span>All Sites</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <app-add-site-button :disable="disable" @update-list="refetchSite" />
      </div>
    </div>
    <div
      v-if="static_sites && static_sites.length"
      class="ecs-static-site__search"
    >
      <cs-search-bar
        v-model="val"
        placeholder="Search"
        class="ecs-static-site__search-bar"
      />
      <i
        class="cs-icons-excel ecs-static-site__search-bar-icon ecs-cursor"
        @click="downloadFile"
      ></i>
    </div>
    <!-- Empty State -->
    <div v-if="static_sites && !static_sites.length">
      <app-empty-state
        title="No static sites"
        description="You have not deployed any sites yet."
        button-text="Add New Site"
        :disable="disable"
      />
    </div>
    <!-- Spinner -->
    <div
      v-if="$apollo.queries.static_sites.loading"
      class="ecs-spinner__wrapper"
    >
      <cs-spinner class="ecs-spinner" />
    </div>
    <div
      v-if="activeSites && activeSites.length"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="activeSites"
        :fields="fields"
        borderless
        hover
        :filter="filteredResult"
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.static_sites.loading"
        @row-clicked="myRowClickHandler"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- Site Name -->
        <template #cell(name)="data">
          <div>{{ data.item.name }}</div>
        </template>
        <!-- Git Provider -->
        <template #cell(git_provider)="data">
          <img
            :src="
              data.item.gitconnect.external_service === 'BITBUCKET'
                ? bitImgUrl
                : gitImgUrl
            "
            class="ecs-provider-icon"
          />
          {{ data.item.repo_name }}
          <span
            ><cs-tag class="ecs-tag mx-1">{{
              data.item.repo_branch
            }}</cs-tag></span
          >
        </template>
        <!-- Last Deployed -->
        <template #cell(updated_at)="data">
          <div v-if="data.item.builds.length > 0">
            {{ data.item.builds[0].end_time | moment('DD MMM YYYY, h:mm A') }}
            <span
              ><cs-tag
                v-if="data.item.builds[0].status === 'COMPLETED'"
                :style="{ '--cs-tag-color': '#00c471' }"
                >{{ data.item.builds[0].status }}</cs-tag
              >
              <cs-tag
                v-if="data.item.builds[0].status === 'FAILED'"
                :style="{ '--cs-tag-color': '#d43230' }"
                >{{ data.item.builds[0].status }}</cs-tag
              ></span
            >
          </div>
          <div v-else>
            <cs-tag class="ecs-tag__progress">In Progress</cs-tag>
          </div>
        </template>
        <!-- Action -->
        <template #cell(action)="data">
          <i
            v-if="data.item.status === 'ACTIVE' && !disable"
            :id="`showPopover${data.index}`"
            class="cs-icons-options ecs_icon-class"
            tabindex="-1"
          >
          </i>
          <b-popover
            ref="actionPopover"
            :target="`showPopover${data.index}`"
            triggers="click blur"
            custom-class="bg-dark"
            placement="leftbottom"
          >
            <div class="ecs-dashboard__popover">
              <div @click.stop="removeSite(data.item)">Remove Site</div>
            </div>
          </b-popover>
        </template>
      </b-table>
    </div>
    <!-- Delete Site Modal  -->
    <app-delete-modal
      :show-delete="showDelete"
      :item="item"
      @close-delete-site-modal="close"
    />
  </div>
</template>

<script>
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import $excel from '@/services/excel';
import ListStaticSites from '@/gql/staticsite/ListStaticSites.gql';
// Components
import AppDeleteModal from '@/components/staticsite/DeleteStaticSite.vue';
import AppAddSiteButton from '@/components/staticsite/AddSiteButton.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';

const gitImgUrl = require('@/assets/images/github.svg');
const bitImgUrl = require('@/assets/images/bitbucket.svg');

export default {
  components: {
    AppDeleteModal,
    AppAddSiteButton,
    AppEmptyState,
  },
  data() {
    return {
      disable: null,
      val: '',
      projectId: this.$route.params.id,
      showDelete: false,
      item: {},
      gitImgUrl,
      bitImgUrl,
      fields: [
        {
          key: 'name',
          label: 'Site Name',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'git_provider',
          label: 'Git Provider',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'updated_at',
          label: 'Last Deployed',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'action',
          label: '',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  apollo: {
    static_sites: {
      query: ListStaticSites,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
    },
  },
  computed: {
    activeSites() {
      const active =
        this.static_sites &&
        this.static_sites.filter((sites) => sites.status === 'ACTIVE');
      return active;
    },
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Download static sites list into an excel file
    downloadFile() {
      const fields = [
        'id',
        'name',
        'created_at',
        'status',
        'git_account_id',
        'repo_branch',
        'repo_name',
      ];

      // $csv.export(output, fields, 'file name');
      $excel.export(this.static_sites, fields, 'Static Sites');
    },
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    async close(val) {
      this.item = {};
      this.showDelete = false;
      if (val) {
        await this.$apollo.queries.static_sites.refetch();
      }
    },
    async refetchSite() {
      await this.$apollo.queries.static_sites.refetch();
    },
    removeSite(d) {
      this.closeActionPopover();
      this.item = d;
      this.showDelete = true;
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'StaticSiteDetail',
        params: { siteid: record.id },
      });
    },
  },
};
</script>

<style scoped>
.ecs-provider-icon {
  height: 20px;
  width: 20px;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.ecs_icon-class {
  font-size: 20px;
}
.ecs-tag__progress {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-static-site__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}

.ecs-static-site__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-static-site__search-bar {
  width: 100%;
}
</style>
