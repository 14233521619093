<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-image-insights" @close="onClose">
      <div slot="header">Analyze Image</div>
      <div slot="body">
        <app-add-progress-bar :step="step" />
        <app-analyze-select
          v-if="step === 1"
          @next-step="onGoToStep($event, 2)"
        ></app-analyze-select>
        <app-analyze-features
          v-if="step === 2"
          :data="modalData"
          @next-step="onGoToStep($event, 3)"
        ></app-analyze-features>
        <app-analyze-final
          v-if="step === 3"
          :data="modalData"
          @close-modal="updateList"
        ></app-analyze-final>
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppAnalyzeSelect from '@/components/imageinsight/AnalyzeSelect.vue';
import AppAnalyzeFeatures from '@/components/imageinsight/AnalyzeFeatures.vue';
import AppAnalyzeFinal from '@/components/imageinsight/AnalyzeFinal.vue';
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';

export default {
  components: {
    AppAnalyzeSelect,
    AppAnalyzeFeatures,
    AppAnalyzeFinal,
    AppAddProgressBar,
  },
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      modalData: {},
    };
  },
  methods: {
    onGoToStep(e, step) {
      this.modalData = { ...this.modalData, ...e };
      this.step = step;
    },
    onClose() {
      this.step = 1;
      this.$emit('close-analyze-image-modal');
    },
    updateList() {
      this.$emit('update-jobs-history');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ecs-image-insights {
  ---cs-modal-max-height: 100vh !important;
}
</style>
