<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        IAM / <span>Project Users </span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button
          :disabled="disable"
          variant="primary"
          @click="openAddUserModal"
          >Add User</cs-button
        >
      </div>
    </div>
    <div class="ecs-dashboard__search">
      <cs-search-bar v-model="val" placeholder="Search" />
    </div>
    <div class="ecs-dashboard__table-head">
      <b-table
        :items="users"
        :fields="fields"
        borderless
        hover
        :filter="filteredResult"
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.users.loading"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- Name -->
        <template v-slot:cell(name)="data">
          <span
            >{{ data.item.user.first_name || 'Invited' }}
            {{ data.item.user.last_name || 'User' }}
            <cs-tag v-if="isInvited(data.item.user)" class="ecs-tag__invited"
              >Invited</cs-tag
            ></span
          >
        </template>
        <!-- Email -->
        <template v-slot:cell(email)="data">
          <span>{{ data.item.user.email }}</span>
        </template>
        <!-- Role -->
        <template v-slot:cell(role)="data">
          <span v-if="data.item.role">
            <cs-tag class="ecs-tag">{{ data.item.role }}</cs-tag>
          </span>
        </template>
        <!-- Last Active -->
        <template v-slot:cell(last_active)="data">
          <span v-if="!isInvited(data.item.user)">{{
            data.item.user.created_at | moment('from')
          }}</span>
        </template>
        <!-- Action Icon -->
        <template v-slot:cell(action)="data">
          <i
            v-if="!isLoggedInUser(data.item) && !disable"
            :id="`showPopover${data.index}`"
            class="cs-icons-options ecs-dashboard__table-body"
            tabindex="-1"
          >
          </i>
          <b-popover
            v-if="!isAdmin"
            ref="actionPopover"
            :target="`showPopover${data.index}`"
            triggers="click blur"
            custom-class="bg-dark"
            placement="leftbottom"
          >
            <div class="ecs-dashboard__popover">
              <div
                v-if="!isInvited(data.item.user)"
                @click="openEditUserModal(data.item)"
              >
                Edit User
              </div>
              <div @click="openRemoveUserModal(data.item)">Remove User</div>
              <div
                v-if="isInvited(data.item.user)"
                @click="openResendInviteModal(data.item)"
              >
                Resend Invite
              </div>
            </div>
          </b-popover>
        </template>
      </b-table>
    </div>
    <!-- Add User Modal -->
    <app-add-user
      :show-add-user="showAddUser"
      :project-id="projectId"
      @close-add-user="closeAddUserModal"
    />

    <!-- Delete User Modal -->
    <app-delete-user
      :show-delete-user="showDeleteUser"
      :user="user"
      :project-id="projectId"
      @close-delete-user="closeRemoveUserModal"
    />

    <!-- Update User Modal -->
    <app-update-user
      v-if="showUpdateUser"
      :show-update-user="showUpdateUser"
      :user="user"
      @close-update-user="closeUpdateUserModal"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import { getAccessLevel } from '@/services/utils';
import ListProjectMembers from '@/gql/user/ListProjectMembers.gql';
import ResendInvite from '@/gql/user/ResendInvite.gql';
// Components
import AppAddUser from '@/components/usermanagement/AddUser.vue';
import AppDeleteUser from '@/components/usermanagement/DeleteUser.vue';
import AppUpdateUser from '@/components/usermanagement/UpdateUser.vue';

export default {
  components: {
    AppAddUser,
    AppDeleteUser,
    AppUpdateUser,
  },
  data() {
    return {
      showAddUser: false,
      showDeleteUser: false,
      showUpdateUser: false,
      user: {},
      projectId: this.$route.params.id,
      val: '',
      disable: null,
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'role',
          label: 'Permission Level',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'last_active',
          label: 'Last Active',
          thClass: 'ecs-dashboard__table-head',
        },
        { key: 'action', label: '', thClass: 'ecs-dashboard__table-head' },
      ],
    };
  },
  apollo: {
    users: {
      query: ListProjectMembers,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
    },
  },
  computed: {
    isAdmin() {
      return this.users && this.adminStatus();
    },
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    isLoggedInUser(d) {
      const result = d.user.id === $auth.getUserId();
      return result;
    },
    isInvited(user) {
      const result = user.first_name;
      return !result;
    },
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'ADMIN') {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    adminStatus() {
      const user =
        this.users &&
        this.users.filter((usr) => usr.user.id === $auth.getUserId());
      const admin = user[0].role !== 'ADMIN';
      return admin;
    },
    // Closes the action popover
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    // Opens and closes add user modal
    openAddUserModal() {
      this.showAddUser = true;
    },
    closeAddUserModal(val) {
      if (val) {
        this.$apollo.queries.users.refetch();
      }
      this.showAddUser = false;
    },
    // Opens and closes edit user modal
    openEditUserModal(d) {
      this.user = d;
      this.showUpdateUser = true;
      this.closeActionPopover();
    },
    closeUpdateUserModal(val) {
      if (val) {
        this.$apollo.queries.users.refetch();
      }
      this.closeActionPopover();
      this.showUpdateUser = false;
    },
    // Opens and closes remove user modal
    openRemoveUserModal(d) {
      this.closeActionPopover();
      this.user = d.user;
      this.showDeleteUser = true;
    },
    closeRemoveUserModal(val) {
      if (val) {
        this.$apollo.queries.users.refetch();
      }
      this.closeActionPopover();
      this.showDeleteUser = false;
    },
    // Opens resend invite modal
    async openResendInviteModal(member) {
      await this.$apollo.query({
        query: ResendInvite,
        variables: {
          projectMemberId: member.id,
        },
      });
      this.closeActionPopover();
    },
  },
};
</script>

<style scoped>
.ecs-users__modal {
  ---cs-modal-width: 450px !important;
}
.ecs-users__modal--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.ecs-tag__invited {
  --cs-tag-color: var(--cs-warning-base) !important;
}
</style>
