<template>
  <div class="row">
    <div class="col-md-7">
      <app-pan-zoom
        :src="sourceUrl ? sourceUrl : fileData.source_url"
        :objects="objects"
        :show-objects="currentAccordianItem === 'object-detection'"
        :show-img="fileData.moderation_status === 'UNSAFE' ? false : true"
      ></app-pan-zoom>
    </div>
    <div class="col-md-5">
      <app-image-data-accordian
        :items="imageDataAccordianItems"
        :job="fileData"
        :objects="objects"
        @open-accordian-item="onOpenAccordianItem"
      ></app-image-data-accordian>
    </div>
  </div>
</template>

<script>
import AppPanZoom from '@/components/general/PanZoom.vue';
import AppImageDataAccordian from '@/components/imageinsight/ImageDataAccordian.vue';
import { randomColor } from '@/services/utils';

export default {
  components: { AppPanZoom, AppImageDataAccordian },
  props: {
    fileData: {
      type: Object,
      required: true,
    },
    sourceUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      imageDataAccordianItems: [
        'content-moderation',
        'ocr',
        'object-detection',
        'tagging',
        'metadata',
      ],
      currentAccordianItem: 'content-moderation',
    };
  },
  computed: {
    objects() {
      return this.fileData.objects.map((obj) => ({
        ...obj,
        color: randomColor(),
      }));
    },
  },
  methods: {
    onOpenAccordianItem(e) {
      this.currentAccordianItem = e;
    },
  },
};
</script>

<style scoped></style>
