<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header d-flex flex-row justify-content-between">
      <div class="ecs-dashboard__header-title">
        Caption / <span>Dashboard</span>
      </div>
      <cs-button
        class="d-flex align-self-end justify-self-end"
        :disabled="disable"
        @click="showAddJob"
        >New Job</cs-button
      >
    </div>
    <app-dashboard-box-row view="caption" />
    <!-- Add New Job Modal  -->
    <app-add-transcoding-job-modal
      :show-add="showNew"
      @close-add-transcoding-job-modal="closeAdd"
      @update-transcoding-jobs="closeAfterJobCreated"
    />
  </div>
</template>
<script>
import AppDashboardBoxRow from '@/components/general/DashboardBoxRow.vue';
import AppAddTranscodingJobModal from '@/components/transcoding/AddTranscodingJobModal.vue';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';

export default {
  components: { AppDashboardBoxRow, AppAddTranscodingJobModal },
  data() {
    return {
      disable: null,
      showNew: false,
    };
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showAddJob() {
      this.showNew = true;
    },
    closeAdd() {
      this.showNew = false;
    },
    async closeAfterJobCreated() {
      this.showNew = false;
    },
  },
};
</script>
<style scoped>
.ecs-transcoding {
  flex: 1;
  display: flex;
}
</style>
