<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Billing / <span>Invoices / Invoice #{{ invoiceID }}</span>
      </div>
      <div>
        <cs-button
          fill="outline"
          class="ecs-invoice-detail__download-invoice-button"
          @click="generateReport"
          >Download Invoice</cs-button
        >
        <cs-button v-if="isPaid || isProcessing">Download Receipt</cs-button>
        <cs-button
          v-if="!isPaid && !isProcessing"
          :disabled="disable"
          @click="openPaymentModal"
          >Pay Now</cs-button
        >
      </div>
    </div>
    <!-- Spinner -->
    <div v-if="$apollo.queries.invoice.loading" class="ecs-spinner__wrapper">
      <cs-spinner class="ecs-spinner" />
    </div>
    <!-- Info -->
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="invoiceName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @beforeDownload="beforeDownload"
      @hasDownloaded="hasDownloaded"
    >
      <section slot="pdf-content">
        <div v-if="invoice" class="ecs-invoice-detail__info">
          <div class="ecs-invoice-detail__info__top">
            <div class="cs-textstyle-section-heading">Invoice Info</div>
          </div>
          <div class="ecs-invoice-detail__content">
            <div class="ecs-invoice-detail__column">
              <table class="ecs-invoice-detail__first-column">
                <tr>
                  <td class="cs-textstyle-paragraph-small-bold">Invoice ID:</td>
                  <td class="cs-textstyle-paragraph-small">#{{ invoiceID }}</td>
                </tr>
                <tr>
                  <td class="cs-textstyle-paragraph-small-bold">
                    Invoice Period:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    {{ invoicePeriod }}
                  </td>
                </tr>
                <tr v-if="isPaid">
                  <td class="cs-textstyle-paragraph-small-bold">
                    Payment Date:
                  </td>
                  <td class="cs-textstyle-paragraph-small">15 December 2022</td>
                </tr>
                <tr v-if="isPaid || isProcessing">
                  <td class="cs-textstyle-paragraph-small-bold">
                    Payment Method:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    {{ paymentMethod }}
                  </td>
                </tr>
                <tr v-if="!isPaid">
                  <td class="cs-textstyle-paragraph-small-bold">Due Date:</td>
                  <td class="cs-textstyle-paragraph-small">{{ dueDate }}</td>
                </tr>
                <tr>
                  <td class="cs-textstyle-paragraph-small-bold">
                    Invoice Status:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    <span
                      v-if="beforeDownloadBool"
                      class="ecs-invoice-details__invoice-status cs-textstyle-tags"
                      :class="[
                        invoice.status === 'PAID'
                          ? 'ecs-invoice-details__invoice-status-paid'
                          : invoice.status === 'UNPAID'
                          ? ''
                          : 'ecs-invoice-details__invoice-status-processing',
                      ]"
                      >{{ invoice.status }}</span
                    >
                    <cs-tag
                      v-else
                      :class="[
                        invoice.status === 'PAID'
                          ? 'ecs-tag__success'
                          : invoice.status === 'UNPAID'
                          ? 'ecs-tag__danger'
                          : 'ecs-tag__processing',
                      ]"
                      >{{ invoice.status }}</cs-tag
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div class="ecs-invoice-detail__column">
              <table class="ecs-invoice-detail__second-column">
                <tr v-if="invoice.project.company_name">
                  <td class="cs-textstyle-paragraph-small-bold">
                    Company Name:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    {{ invoice.project.company_name }}
                  </td>
                </tr>
                <tr v-if="invoice.project.company_address">
                  <td
                    class="cs-textstyle-paragraph-small-bold ecs-invoice-detail__address"
                  >
                    Company Address:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    {{ invoice.project.company_address }}
                  </td>
                </tr>
                <tr v-if="invoice.project.company_national_tax_id">
                  <td class="cs-textstyle-paragraph-small-bold">
                    National Tax ID:
                  </td>
                  <td class="cs-textstyle-paragraph-small">
                    {{ invoice.project.company_national_tax_id }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="ecs-invoice-detail__details-section">
            <div class="cs-textstyle-section-heading">Details:</div>
            <p class="cs-textstyle-informative-paragraph">
              Total:
              <span class="cs-textstyle-informative-paragraph-bold">
                {{ invoice.total_usd | currency }}</span
              >
            </p>
          </div>
          <!-- Table -->
          <div class="ecs-dashboard-list__table">
            <b-table-simple outlined>
              <b-thead class="b-table__head">
                <b-tr>
                  <b-th class="small-tab">Service</b-th>
                  <b-th>Units</b-th>
                  <b-th>Price Per Unit</b-th>
                  <b-th>Total Price</b-th>
                </b-tr>
              </b-thead>
              <b-tbody
                v-for="(service, index) in servicesGroupedByService"
                :key="index"
              >
                <b-tr class="category-line">
                  <b-th class="small-tab cs-textstyle-paragraph-small-bold">{{
                    index
                  }}</b-th>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-th class="cs-textstyle-paragraph-small-bold">{{
                    service.reduce(function (prev, curr) {
                      return prev + curr.total_units * curr.price_per_unit;
                    }, 0) | currency
                  }}</b-th>
                </b-tr>
                <b-tr
                  v-for="serviceItem in service"
                  :key="serviceItem.id"
                  class="item-line"
                >
                  <b-td class="big-tab cs-textstyle-paragraph-small">{{
                    serviceItem.billing_sku_name
                  }}</b-td>
                  <b-td class="cs-textstyle-paragraph-small">{{
                    serviceItem.total_units
                  }}</b-td>
                  <b-td class="cs-textstyle-paragraph-small">{{
                    serviceItem.price_per_unit | currency
                  }}</b-td>
                  <b-td class="cs-textstyle-paragraph-small">{{
                    (serviceItem.total_units * serviceItem.price_per_unit)
                      | currency
                  }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- open pay modal  -->
    <app-pay-invoice-bill-modal
      :show-pay="showPaymentModal"
      :invoice-id="invoice.id"
      @close-pay-invoice-modal="closePaymentModal"
      @payment-done="paymentDone"
    />
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import GetInvoice from '@/gql/billing/GetInvoice.gql';
import FetchCustomer from '@/gql/paymentMethods/FetchCustomer.gql';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import AppPayInvoiceBillModal from '@/components/billing/PayInvoiceBillModal.vue';
import { formatDate, getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';

export default {
  components: {
    AppPayInvoiceBillModal,
    VueHtml2pdf,
  },
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
  apollo: {
    invoice: {
      query: GetInvoice,
      variables() {
        return {
          projectId: this.projectId,
          month: this.month,
        };
      },
      error(error) {
        console.log('errors', error.message);
      },
    },
    customer: {
      query: FetchCustomer,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      projectId: this.$route.params.id,
      month: this.$route.params.month,
      showPaymentModal: false,
      invoice: {
        id: '',
        project: {
          company_name: '',
          company_national_tax_ID: '',
          company_address: '',
        },
      },
      beforeDownloadBool: false,
    };
  },
  computed: {
    servicesGroupedByService() {
      return _.groupBy(this.invoice.invoice, 'service');
    },
    isPaid() {
      if (this.invoice && this.invoice.status === 'PAID') return true;
      return false;
    },
    isProcessing() {
      if (this.invoice && this.invoice.status === 'PROCESSING') return true;
      return false;
    },
    dueDate() {
      const due = this.invoice.due_date
        ? formatDate(this.invoice.due_date)
        : '-';
      return due;
    },
    invoiceID() {
      return this.invoice && this.invoice.id.slice(0, 4);
    },
    invoicePeriod() {
      const firstDay = new Date(this.invoice.month).toLocaleString('default', {
        month: 'long',
        day: 'numeric',
      });
      const lastDay = new Date(
        new Date(this.invoice.month).getFullYear(),
        new Date(this.invoice.month).getMonth() + 1,
        0
      ).toLocaleString('default', {
        month: 'long',
        day: 'numeric',
      });
      return `${firstDay} - ${lastDay}`;
    },
    invoiceName() {
      return `ECS Invoice #${this.invoiceID}`;
    },
    paymentMethod() {
      if (this.customer && this.customer?.payment_methods) {
        const brandName =
          this.customer.payment_methods[0].card.brand.toUpperCase();
        const lastDigits = this.customer.payment_methods[0].card.last4;
        const expireMonth = this.customer.payment_methods[0].card.exp_month;
        const expireYear = this.customer.payment_methods[0].card.exp_year;
        return `${brandName} ending in ${lastDigits} expiring in ${expireMonth}/${expireYear}`;
      }
      return '-';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'ADMIN') {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    paymentDone() {
      this.showPaymentModal = false;
      window.location.reload();
    },
    beforeDownload() {
      this.beforeDownloadBool = true;
    },
    hasDownloaded() {
      this.beforeDownloadBool = false;
    },
    openPaymentModal() {
      this.showPaymentModal = true;
    },
    closePaymentModal() {
      this.showPaymentModal = false;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.ecs-tag__success {
  --cs-tag-color: var(--cs-primary-base) !important;
  color: var(--cs-gray-00) !important;
}
.ecs-tag__processing {
  --cs-tag-color: var(--cs-warning-base) !important;
  color: var(--cs-gray-00) !important;
}
.ecs-tag__danger {
  --cs-tag-color: var(--cs-danger-base) !important;
  color: var(--cs-gray-00) !important;
}
.ecs-dashboard__header {
  margin-bottom: 40px;
}
.ecs-invoice-detail__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 120px;
  padding: 32px;
}
.ecs-invoice-detail__info__top {
  margin-bottom: 32px;
}
.ecs-invoice-detail__download-invoice-button {
  margin-right: 10px;
}
.ecs-invoice-detail__content {
  display: flex;
  flex-direction: row;
  gap: 150px;
}
.ecs-invoice-detail__column {
  display: flex;
  flex-direction: column;
}
.ecs-invoice-detail__first-column {
  max-width: 450px;
  color: var(--cs-gray-06);
}
td {
  padding-bottom: 12px;
  width: fit-content;
  padding-right: 30px;
}
.ecs-invoice-detail__second-column {
  max-width: 400px;
  color: var(--cs-gray-06);
}
.ecs-invoice-detail__address {
  vertical-align: top;
}
.ecs-invoice-detail__details-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 25px;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.b-table__head {
  background-color: var(--cs-gray-04);
  color: var(--cs-gray-00);
}
.b-table__head th {
  vertical-align: middle;
}
.category-line {
  background-color: var(--cs-gray-01);
  color: var(--cs-gray-06);
}
.item-line {
  background-color: var(--cs-gray-00);
  color: var(--cs-gray-06);
}
.big-tab {
  padding-left: 50px;
}
.small-tab {
  padding-left: 32px;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.ecs-invoice-details__invoice-status {
  background: var(--cs-danger-base);
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  color: white;
}
.ecs-invoice-details__invoice-status-paid {
  background: var(--cs-primary-base);
  padding: 5px;
  color: white;
}
.ecs-invoice-details__invoice-status-processing {
  background: var(--cs-warning-base);
  padding: 5px;
  color: white;
}
</style>
