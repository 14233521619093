<template>
  <div>
    <cs-modal :show="showEditKey" @close="closeModal">
      <div slot="header">Modify Key</div>
      <div slot="body">
        <div class="ecs-key">
          <div class="ecs-key__name">
            <cs-input
              v-model="name"
              label="Name"
              placeholder="ex: Bitbucket Deployment key"
              required
              class="ecs-key__wide-input"
            />
          </div>
          <div class="ecs-key__description">
            <cs-textarea
              v-model="description"
              label="Description"
              placeholder="My key for deployment in bitbucket"
              class="ecs-key__wide-input"
            />
          </div>
          <div class="ecs-key__select">
            <div class="ecs-key__select-label">
              Permission Level <span>*</span>
              <i class="cs-icons-info mx-1 ecs-icon"></i>
            </div>
            <cs-select
              placeholder="Select"
              :options="options"
              :value="selected"
              icon="cs-icons-expand-arrow-filled"
              class="ecs-key__wide-input"
              @select="onSelect"
            >
            </cs-select>
          </div>
          <div class="ecs-key__button">
            <cs-button variant="primary" :disabled="!valid" @click="modifyKey"
              >Modify Key</cs-button
            >
          </div>
        </div>
      </div>
    </cs-modal>
  </div>
</template>

<script>
import UpdateProjectKey from '@/gql/projectKey/UpdateProjectKey.gql';

export default {
  props: {
    showEditKey: {
      type: Boolean,
      required: true,
    },
    keyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: this.keyData.name,
      description: this.keyData.description,
      options: [
        { value: 'readonly', label: 'Read Only' },
        { value: 'readwrite', label: 'Read & Write', default: true },
      ],
      selected: this.keyData.role,
    };
  },
  computed: {
    valid() {
      return !!this.name && !!this.selected;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-edit-key');
    },
    async modifyKey() {
      if (this.valid) {
        await this.$apollo.mutate({
          mutation: UpdateProjectKey,
          variables: {
            description: this.description,
            name: this.name,
            role: this.selected,
            id: this.keyData.id,
          },
        });
        this.$emit('close-edit-key');
      }
    },
    onSelect(i) {
      this.selected = i.value;
    },
  },
};
</script>

<style scoped>
.ecs-key__header {
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}
.ecs-key__prompt {
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ecs-key__wide-input {
  width: 100%;
}
.ecs-key {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ecs-key__name {
  margin-top: 20px;
}
.ecs-key__description {
  margin: 20px 0px;
}
.ecs-key__button {
  margin-top: 20px;
  align-self: flex-end;
}
.ecs-key__select-label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.ecs-key__select-label span {
  color: var(--cs-danger-base);
}
</style>
