<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Image Insights / <span>History</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button variant="primary" :disabled="disable" @click="showAddJob"
          >New Job</cs-button
        >
      </div>
    </div>
    <div v-if="$apollo.queries.jobs.loading" class="ecs-spinner__wrapper">
      <cs-spinner class="ecs-spinner" />
    </div>
    <div v-if="jobs && jobs.length">
      <div class="ecs-image-insights__search">
        <cs-search-bar
          v-model="val"
          class="ecs-image-insights__search-bar"
          placeholder="Search"
        />
        <i
          class="cs-icons-excel ecs-image-insights__search-bar-icon ecs-cursor"
          @click="downloadFile"
        ></i>
      </div>
      <div class="ecs-dashboard__table-head">
        <b-table
          :items="jobs"
          :fields="fields"
          borderless
          hover
          :filter="filteredResult"
          striped
          table-variant="light"
          tbody-tr-class="ecs-dashboard__table-row"
          @row-clicked="myRowClickHandler"
        >
          <template #table-busy>
            <div class="ecs-dashboard__table-busy">
              <cs-spinner class="ecs-spinner" />
            </div>
          </template>
          <!-- Job ID -->
          <template v-slot:cell(jobId)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text slot="visible" :long-text="data.item.id" />
              <div slot="popover">{{ data.item.id }}</div>
            </app-hover>
          </template>
          <!-- Status -->
          <template v-slot:cell(status)="data">
            <span v-if="data.item.status">
              <cs-tag :class="['ecs-tag__' + data.item.status]">{{
                data.item.status
              }}</cs-tag>
            </span>
          </template>
          <!-- Origin -->
          <template v-slot:cell(origin)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text
                slot="visible"
                :max-chars="30"
                :long-text="data.item.source_url"
              />
              <div slot="popover">{{ data.item.source_url }}</div>
            </app-hover>
          </template>
          <!-- Destination -->
          <template v-slot:cell(destination)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text
                slot="visible"
                :max-chars="30"
                :long-text="data.item.destination_url"
              />
              <div slot="popover">{{ data.item.destination_url }}</div>
            </app-hover>
          </template>
          <!-- Time -->
          <template v-slot:cell(time)="data">
            <span>{{
              data.item.created_at | moment('D MMM YYYY, h:mm a')
            }}</span>
          </template>
          <!-- Features -->
          <template v-slot:cell(features)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <div slot="visible" class="ecs-image-insights__features">
                <cs-tag
                  v-for="feature in data.item.features[0].split(',')"
                  :key="feature"
                  class="imageinsight-history__feature"
                  >{{ feature }}</cs-tag
                >
                <span v-if="data.item.features && data.item.features.length > 1"
                  >...</span
                >
              </div>
              <div slot="popover">
                <cs-tag
                  v-for="feature in data.item.features"
                  :key="feature"
                  class="imageinsight-history__feature"
                  >{{ feature }}</cs-tag
                >
              </div>
            </app-hover>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Empty State -->
    <div v-if="jobs && !jobs.length">
      <app-empty-state
        :icon="Image"
        button-text="New Job"
        :disable="disable"
        description="You have not created any job yet"
        @add-action="showAddJob"
      />
    </div>
    <app-analyze-image-modal
      :show-add="showNew"
      :project-id="projectId"
      @close-analyze-image-modal="closeAdd"
      @update-jobs-history="closeAfterJobCreated"
    />
  </div>
</template>

<script>
import ListImageInsightJobs from '@/gql/imageinsight/ListImageInsightJobs.gql';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppAnalyzeImageModal from '@/components/imageinsight/AnalyzeImageModal.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppHover from '@/components/general/Hover.vue';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import $excel from '@/services/excel';

const Image = require('@/assets/images/image.svg');

export default {
  components: {
    AppTruncatedText,
    AppAnalyzeImageModal,
    AppEmptyState,
    AppHover,
  },
  apollo: {
    jobs: {
      query: ListImageInsightJobs,
      variables() {
        return {
          projectId: this.$route.params.id,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      showNew: false,
      projectId: this.$route.params.id,
      val: '',
      Image,
      fields: [
        {
          key: 'jobId',
          label: 'Job Id',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'origin',
          label: 'Origin',
          thClass: 'ecs-dashboard__table-head',
        },
        // {
        //   key: 'destination',
        //   label: 'Destination',
        //   thClass: 'ecs-dashboard__table-head',
        // },
        {
          key: 'time',
          label: 'Time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'features',
          label: 'Features',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showAddJob() {
      this.showNew = true;
    },
    closeAdd() {
      this.showNew = false;
    },
    async closeAfterJobCreated() {
      this.showNew = false;
      await this.$apollo.queries.jobs.refetch();
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'ImageInsightsDetail',
        params: { jobid: record.id },
      });
    },

    // Download job list into an excel file
    downloadFile() {
      const fields = ['id', 'status', 'source_url', 'created_at', 'features'];
      const output = this.jobs.map((item) => {
        const job = {
          ...item,
          features: item.features == null ? null : item.features.join(', '),
        };
        return job;
      });
      // $csv.export(output, fields, 'file name');
      $excel.export(output, fields, 'Image Insight Jobs');
    },
  },
};
</script>

<style scoped>
.ecs-image-insights__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-image-insights__search-bar {
  width: 100%;
}
.ecs-image-insights__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.imageinsight-history__feature {
  margin: 5px;
}
.ecs-image-insights__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.ecs-tag__COMPLETED {
  --cs-tag-color: var(--cs-primary-base) !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}
.ecs-tag__FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}
.ecs-tag__QUEUED {
  --cs-tag-color: var(--cs-info-base) !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}
.ecs-tag__PROGRESS {
  --cs-tag-color: #e8bc28 !important;
  --cs-tag-textcolor: var(--cs-gray-00) !important;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
</style>
