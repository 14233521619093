<template>
  <span class="name"
    ><i :class="[fileExtIcon, 'cs-file-preview__content__icon']" />{{
      fileName
    }}</span
  >
</template>

<script>
const iconMap = {
  'text/plain': 'cs-icons-txt',
  'application/pdf': 'cs-icons-pdf',
  'application/msword': 'cs-icons-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'cs-icons-word',
  'application/vnd.ms-excel': 'cs-icons-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'cs-icons-excel',
  'application/zip': 'cs-icons-zip',
  'application/x-zip-compressed': 'cs-icons-zip',
};
const defaultIconMap = {
  'image/': 'cs-icons-image-document',
  'audio/': 'cs-icons-audio-file',
  'video/': 'cs-icons-video-file',
  'text/': 'cs-icons-txt',
  'application/': 'cs-icons-application-file',
};
export default {
  props: {
    fileType: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
  },
  computed: {
    nameFile() {
      return this.fileName;
    },
    fileExtIcon() {
      const type = this.fileType || 'application/*';
      if (iconMap[type]) return iconMap[type];

      const key = Object.keys(defaultIconMap).find((k) => type.indexOf(k) > -1);
      return defaultIconMap[key] || 'cs-icons-application-file';
    },
  },
};
</script>

<style scoped>
.name {
  font-size: 18px;
  display: flex;
  gap: 5px;
}
</style>
